import { Route, Routes } from 'react-router'

import { NotFound } from '../common/NotFound'
import { List } from './List'
import { Edit } from './Edit'

export const Nodes = () => {
  return (
    <Routes>
      <Route index element={<List />} />
      <Route path="update/:regionId/node/:name" element={<Edit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
