import Dashboard from '@mui/icons-material/Dashboard'
import OndemandVideoOutlined from '@mui/icons-material/OndemandVideoOutlined'
import Settings from '@mui/icons-material/Settings'
import VideocamOutlined from '@mui/icons-material/VideocamOutlined'
import Warning from '@mui/icons-material/Warning'

import { Role } from 'common/api/v1/types'
import { useRoutes } from '../../store'
import { IS_CONNECT_IT } from '../../env'
import { Sidebar, type SidebarProps } from '../common/SidebarMenu/index'
import AlarmItemNameWithActiveAlarms from '../common/SidebarMenu/AlarmItemNameWithActiveAlarms'
import { useSettingsSelector } from '../../utils'

export const NMSidebar = () => {
  const routes = useRoutes()
  const { settings } = useSettingsSelector()
  const expFeatures = settings?.expFeatures

  const menuItems: SidebarProps['menuItems'] = ({ userRole, alarms, onClick, devMode }) => [
    {
      name: 'Overview',
      url: routes.overview(),
      icon: <Dashboard />,
      onClick,
    },
    {
      name: 'Inputs',
      url: routes.inputs(),
      icon: <VideocamOutlined />,
      onClick,
    },
    {
      name: 'Outputs',
      url: routes.outputs(),
      icon: <OndemandVideoOutlined />,
      onClick,
    },
    {
      name: <AlarmItemNameWithActiveAlarms alarms={alarms} />,
      url: routes.alarms(),
      icon: <Warning />,
      onClick,
    },
    {
      name: 'Settings',
      icon: <Settings />,
      children: [
        {
          name: 'Regions',
          url: routes.regions(),
          onClick,
        },
        {
          name: 'Appliances',
          url: routes.appliances(),
          onClick,
        },
        devMode
          ? {
              name: <s>Interfaces</s>,
              url: routes.interfaces(),
              divider: true,
              onClick,
            }
          : null,
        userRole === Role.super
          ? {
              name: 'Networks',
              url: routes.networks(),
              onClick,
            }
          : null,
        userRole === Role.super
          ? {
              name: 'Address mappings',
              url: routes.addressMappings(),
              onClick,
            }
          : null,
        userRole === Role.super && !IS_CONNECT_IT
          ? {
              name: 'Kubernetes nodes',
              url: routes.kubernetesNodes(),
              onClick,
            }
          : null,
        userRole === Role.super && !IS_CONNECT_IT
          ? {
              name: 'Kubernetes services',
              url: routes.services(),
              divider: true,
              onClick,
            }
          : null,
        {
          name: 'Groups',
          url: routes.groups(),
          onClick,
        },
        {
          name: 'Users',
          url: routes.users(),
          divider: true,
          onClick,
        },
        {
          name: 'Audit log',
          url: routes.audit(),
          onClick,
        },
        userRole === Role.super
          ? {
              name: 'Alarm history',
              url: routes.alarmLogs(),
              divider: true,
              onClick,
            }
          : null,
        userRole === Role.super && !IS_CONNECT_IT
          ? {
              name: 'Billing',
              url: routes.billing(),
              onClick,
            }
          : null,
        userRole === Role.super || userRole === Role.admin
          ? {
              name: 'Usage',
              url: routes.usage(),
              divider: true,
              onClick,
            }
          : null,
        userRole >= Role.admin && devMode && expFeatures?.ExtDerivedInput
          ? {
              name: 'Production tools',
              url: routes.production(),
            }
          : null,
        userRole >= Role.admin && expFeatures?.AdInsertion
          ? {
              name: 'Ad Insertion',
              url: routes.adInsertion(),
            }
          : null,
        userRole === Role.super
          ? {
              name: 'Global settings',
              url: routes.globalSettings(),
              onClick,
            }
          : null,
      ].filter(Boolean),
    },
  ]

  return (
    <Sidebar
      logoLink={routes.overview()}
      title="Network Manager"
      menuItems={menuItems}
      profileUrl={routes.profile.route}
    />
  )
}
