import { getApplianceOwnerId, isEditableAppliance } from '../../utils'
import Tooltip from './Tooltip'
import MuiLink from '@mui/material/Link'
import { api } from '../../utils/routes'
import { COOKIE_APPLIANCE_PROXY } from 'common/constants'
import IconButton from '@mui/material/IconButton'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { Appliance, User } from 'common/api/v1/types'

interface ApplianceProxyButtonProps {
  user: Pick<User, 'group' | 'role'>
  appliance: Pick<Appliance, 'owner' | 'id' | 'features' | 'settings'>
  iconButtonProps?: {
    height: number
  }
}
export const ApplianceProxyButton = ({ user, appliance, iconButtonProps }: ApplianceProxyButtonProps) => {
  const isEditable = isEditableAppliance(user, getApplianceOwnerId(appliance))
  const proxyEnabledOverride = appliance.settings.proxy?.enabled
  const applianceHasProxy =
    (!!appliance.features.proxy?.enabled && proxyEnabledOverride === undefined) || !!proxyEnabledOverride
  if (!isEditable) return null
  if (!applianceHasProxy) return null
  return (
    <Tooltip title="Manage appliance" placement="top">
      <span>
        <MuiLink
          href={api.appliance({ id: appliance.id })}
          onClick={() => {
            document.cookie = `${COOKIE_APPLIANCE_PROXY}=${appliance.id};path=/api/appliance/${appliance.id}/proxy`
          }}
          target="_blank"
        >
          <IconButton edge="end" aria-label="Manage appliance" sx={iconButtonProps ? iconButtonProps : undefined}>
            <OpenInNew />
          </IconButton>
        </MuiLink>
      </span>
    </Tooltip>
  )
}
