import { Navigate, Route, Routes } from 'react-router'

import { ROUTES } from '../../utils/routes'
import { EventManagerContainer } from './EventManagerContainer'
import { StreamManagerContainer } from './StreamManagerContainer'
import { NetworkManagerContainer } from './NetworkManagerContainer'
import { useUser } from '../../utils'
import { Role } from 'common/api/v1/types'
import { ConfirmDialogProvider } from '../common/ConfirmDialog'
import { ContentDialogProvider } from '../common/ContentDialog'
import { HelmetProvider } from 'react-helmet-async'

const LoggedIn = () => {
  const user = useUser()

  return (
    <HelmetProvider>
      <ConfirmDialogProvider>
        <ContentDialogProvider>
          <Routes>
            <Route path={`${ROUTES.stream}/*`} element={<StreamManagerContainer />} />
            <Route path={`${ROUTES.events}/*`} element={<EventManagerContainer />} />
            <Route
              path="/*"
              element={user.role === Role.basic ? <Navigate to={ROUTES.stream} replace /> : <NetworkManagerContainer />}
            />
          </Routes>
        </ContentDialogProvider>
      </ConfirmDialogProvider>
    </HelmetProvider>
  )
}

export default LoggedIn
