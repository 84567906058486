import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'

interface ArcadeButtonProps {
  onClick: () => void
  size?: number
  triggerExternalAnimation?: (triggerAnimation: () => void) => void
  disabled?: boolean
}

export default function ArcadeButton({
  onClick,
  size = 120,
  triggerExternalAnimation,
  disabled = false,
}: ArcadeButtonProps) {
  const [isAnimating, setIsAnimating] = useState(false)

  const triggerAnimation = () => {
    setIsAnimating(true)
  }

  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => {
        setIsAnimating(false)
      }, 200)
      return () => clearTimeout(timer)
    }
  }, [isAnimating])

  useEffect(() => {
    if (triggerExternalAnimation) {
      triggerExternalAnimation(triggerAnimation)
    }
  }, [triggerExternalAnimation])

  return (
    <Button
      onClick={onClick}
      onMouseDown={triggerAnimation}
      disabled={disabled}
      sx={{
        width: size,
        height: size,
        borderRadius: '50%',
        background: disabled
          ? '#212531'
          : isAnimating
          ? 'radial-gradient(circle, #ff4d4d 0%, #cc0000 100%)'
          : 'radial-gradient(circle, #ff0000 0%, #990000 100%)',
        color: 'white',
        fontSize: size / 6,
        fontWeight: 'bold',
        boxShadow: disabled
          ? 'none'
          : isAnimating
          ? '0px 7px 20px rgba(255, 0, 0, 0.9)'
          : '0px 5px 15px rgba(255, 0, 0, 0.7)',
        transform: isAnimating ? 'scale(1.1)' : 'none',
        transition: 'transform 0.1s, box-shadow 0.1s',
        '&:hover': {
          background: disabled ? '#212531' : 'radial-gradient(circle, #ff4d4d 0%, #cc0000 100%)',
          transform: disabled ? 'none' : 'scale(1.1)',
          boxShadow: disabled ? 'none' : '0px 7px 20px rgba(255, 0, 0, 0.9)',
        },
        '&:active': {
          transform: disabled ? 'none' : 'scale(0.95)',
        },
      }}
    >
      INSERT AD
    </Button>
  )
}
