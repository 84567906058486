import { DispatchProp } from 'react-redux'
import { format } from 'date-fns'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import Tooltip from '../../common/Tooltip'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

import {
  ExperimentalFeatures,
  Input,
  ListOutputSortableField,
  Output,
  OutputAdminStatus,
  Role,
} from 'common/api/v1/types'

import { TableConfig } from '../../common/Table'

import { hasAccessToAppliance, inputOutputColor, isEditableGroup, outputType } from '../../../utils'
import { EnrichedOutput, EnrichedUser } from '../../../api/nm-types'
import { DATE_FORMAT_LONG, DATE_FORMAT_SHORT, formatOutputStreamContents } from 'common/api/v1/helpers'
import { Link } from '../../common/Link'
import { NonSubscribingAutoUpdatingOutputHealthIndicator, OutputHealthIndicator } from '../../common/Indicator'
import { NonSubscribingThumbnail } from '../../common/Thumbnail'
import { OutputListDialog } from '.'

import { ActionMenu } from './ActionMenu'
import { Routes } from '../../../utils/routes'
import CableIcon from '@mui/icons-material/Cable'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'

interface GetConfigProps {
  selected: Array<Input['id']>
  outputs: Array<Output>
  experimentalFeatures?: ExperimentalFeatures
  handleSelect: (id: Output['id']) => void
  handleSelectAll: () => void
  showDialog: (output: Output, type: OutputListDialog) => void
  dispatch: DispatchProp['dispatch']
  routes: Routes
  user: EnrichedUser
}
type OutputColumn = TableConfig<EnrichedOutput, ListOutputSortableField>[number]

const canUseNM = (role: Role) => role !== Role.basic

const status = ({
  autoUpdate,
  experimentalFeatures,
}: { autoUpdate: boolean } & Pick<GetConfigProps, 'experimentalFeatures'>): OutputColumn => ({
  title: 'status',
  getValue: (output) => {
    const indicator = autoUpdate ? (
      <NonSubscribingAutoUpdatingOutputHealthIndicator initialOutput={output} />
    ) : (
      <OutputHealthIndicator status={output.health} disabled={output.adminStatus === OutputAdminStatus.off} />
    )
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {indicator}
        {output.adInsertionEnabled && (
          <Tooltip title={'Ad insertion is enabled for this output'}>
            <MonetizationOnIcon style={{ marginLeft: '5px' }} />
          </Tooltip>
        )}
        {output.unhealthyAlarm && (
          <Tooltip title={'Status alarms are enabled for this output'}>
            <RemoveRedEyeIcon style={{ marginLeft: '5px' }} />
          </Tooltip>
        )}
      </div>
    )
  },
  sorting:
    experimentalFeatures && experimentalFeatures.ExtHealthAlarms
      ? {
          byParameter: ListOutputSortableField.healthStatus,
        }
      : undefined,
  props: { noTypography: true },
})

const preview = (): OutputColumn => ({
  title: 'preview',
  getValue: (output) => (
    <div data-test-id="thumbnail">
      <NonSubscribingThumbnail input={output._input} initialOutput={output} />
    </div>
  ),
  props: {
    sx: { padding: 1, width: '100px' },
    noTypography: true,
  },
  headerProps: {},
})

const name = (
  { user, toCallback }: Pick<GetConfigProps, 'user'> & { toCallback: (output: EnrichedOutput) => string },
  override?: Partial<Pick<NonNullable<OutputColumn>, 'props'>>,
): OutputColumn => ({
  title: 'name',
  getValue: (output) => (
    <Link available={canUseNM(user.role)} to={toCallback(output)} underline="hover">
      <Typography {...inputOutputColor({ adminStatus: output.adminStatus })}>{output.name}</Typography>
    </Link>
  ),
  sorting: {
    byParameter: ListOutputSortableField.outputName,
  },
  props: {
    ...override?.props,
  },
})

export const outputColumns = {
  status,
  preview,
  name,
} as const satisfies Record<string, (props: any) => OutputColumn>

const getConfig: (props: GetConfigProps) => OutputColumn[] = ({
  selected,
  outputs,
  experimentalFeatures,
  handleSelect,
  handleSelectAll,
  showDialog,
  dispatch,
  routes,
  user,
}) => [
  {
    title: (
      <Checkbox
        indeterminate={selected.length > 0 && selected.length < outputs.length}
        checked={selected.length === outputs.length}
        onChange={handleSelectAll}
        inputProps={{ 'aria-label': 'select all outputs' }}
        data-test-id={'select-all'}
      />
    ),
    getValue: ({ id }) => (
      <Checkbox
        checked={selected.includes(id)}
        data-test-id="select-output"
        onChange={() => {
          handleSelect(id)
        }}
        onDoubleClick={(e) => e.stopPropagation()}
      />
    ),
    props: {
      padding: 'checkbox',
      noTypography: true,
    },
  },
  status({ autoUpdate: true, experimentalFeatures }),
  preview(),
  name({ user, toCallback: ({ id }: Output) => routes.outputsUpdate({ id }) }),
  {
    title: 'input',
    getValue: (output) => {
      const isInputOwner = user.group === output._input?.owner || user.role === Role.super
      return (
        output._input?.id && (
          <Link to={routes.inputsUpdate({ id: output._input.id })} available={isInputOwner} underline="hover">
            <Typography variant="subtitle1" {...inputOutputColor(output)}>
              {output._input?.name}
            </Typography>
          </Link>
        )
      )
    },
    sorting: {
      byParameter: ListOutputSortableField.inputName,
    },
  },
  {
    title: 'appliance',
    getValue: ({ appliances, adminStatus }) =>
      appliances.map((a) => (
        <Link
          key={a.id}
          to={routes.appliancesUpdate({ id: a.id })}
          available={hasAccessToAppliance(a, user)}
          underline="hover"
        >
          <Typography {...inputOutputColor({ adminStatus })} variant="body2">
            {a.name}
          </Typography>
        </Link>
      )),
    sorting: {
      byParameter: ListOutputSortableField.applianceName,
    },
  },
  {
    title: 'protocol',
    getValue: ({ ports }) => (
      <Chip size="small" icon={ports[0] ? <CableIcon /> : <QuestionMarkIcon />} label={outputType(ports[0])} />
    ),
    sorting: {
      byParameter: ListOutputSortableField.protocol,
    },
  },
  {
    title: 'format',
    getValue: ({ _input, adminStatus, ports }) => {
      if (!_input) return 'N/A'
      const { tsInfo } = _input
      const formattedOutputStreamContents = formatOutputStreamContents({ ports, tsInfo })
      return (
        <Tooltip
          title={JSON.stringify((tsInfo || [])[0]?.services?.[0]?.type?.description) || 'no info'}
          placement="top"
        >
          <Typography {...inputOutputColor({ adminStatus })} variant="body2">
            {formattedOutputStreamContents}
          </Typography>
        </Tooltip>
      )
    },
  },
  ...(user.role === Role.super
    ? [
        {
          title: 'owner',
          sorting: {
            byParameter: ListOutputSortableField.ownerGroupName,
          },
          getValue: ({ _group }: EnrichedOutput) => (
            <Link
              to={routes.groupsUpdate({ id: _group?.id })}
              available={!!_group?.id && isEditableGroup(_group?.id, user)}
              underline="hover"
            >
              <Typography variant="body2" color="textSecondary">
                {!!_group && _group.name}
              </Typography>
            </Link>
          ),
        },
      ]
    : []),
  {
    title: 'created',
    getValue: ({ createdAt, adminStatus }) => (
      <Tooltip title={format(new Date(createdAt), DATE_FORMAT_LONG)} placement="top">
        <Typography {...inputOutputColor({ adminStatus })} variant="body2">
          {format(new Date(createdAt), DATE_FORMAT_SHORT)}
        </Typography>
      </Tooltip>
    ),
    sorting: {
      byParameter: ListOutputSortableField.creationDate,
    },
  },
  {
    title: '',
    getValue: (row) => <ActionMenu output={row} dispatch={dispatch} showDialog={showDialog} />,
    props: { noTypography: true },
  },
]

export default getConfig
