import { Route, Routes } from 'react-router'

import { SUB_PATH } from '../../utils/routes'
import { NotFound } from '../common/NotFound'
import { Edit } from './Edit'
import { GroupsList as List } from './List'
import { Edit as GroupListsEdit } from '../groupLists/Edit'
import { ListView as GroupLists } from '../groupLists/List'

export const Groups = () => {
  return (
    <Routes>
      <Route path={SUB_PATH.UPDATE} element={<Edit />} />
      <Route path={SUB_PATH.NEW} element={<Edit />} />
      <Route index element={<List />} />
      <Route path="lists">
        <Route index element={<GroupLists />} />
        <Route path={SUB_PATH.NEW} element={<GroupListsEdit />} />
        <Route path={SUB_PATH.UPDATE} element={<GroupListsEdit />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
