import { AdAuditFilter, ListResult } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { AdAuditLogRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'
import { AdAuditLogMessage } from 'common/types'

export interface IAdAuditApi {
  getAdAudit(params: AdAuditLogRequestParams): Promise<ListResult<AdAuditLogMessage>>
}

export class AdAuditApi implements IAdAuditApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  getAdAudit(params: AdAuditLogRequestParams): Promise<ListResult<AdAuditLogMessage>> {
    const filter: AdAuditFilter = {
      filter: params.filter,
      fromDate: params.fromDate ? new Date(params.fromDate) : undefined,
      toDate: params.toDate ? new Date(params.toDate) : undefined,
      entityType: params.entityType,
      method: params.method,
      result: params.result,
      message: params.message,
      status: params.status,
      eventId: params.eventId ? Number(params.eventId) : undefined,
      outputId: params.outputId,
      streamId: params.streamId ? Number(params.streamId) : undefined,
      channelId: params.channelId ? Number(params.channelId) : undefined,
      component: params.component,
      pod: params.pod,
      entity: params.entity,
      entityId: params.entityId,
      entityName: params.entityName,
      source: params.source,
      target: params.target,
      traceId: params.traceId,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.listAdAuditLogs(query)
  }
}
