import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew'
import Person from '@mui/icons-material/Person'
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount'
import type { Theme } from '@mui/material/styles'

import { AlarmSeverityLevels, BuildInfo, Role } from 'common/api/v1/types'
import { equals } from 'common/util'
import { logoutAndNavigateToMainPage, stopImpersonation } from '../../../redux/actions/userActions'
import { toggleSidebarMenu } from '../../../redux/actions/uiActions'
import { AppDispatch, GlobalState } from '../../../store'
import { useUser } from '../../../utils'
import { TopLeftNav } from '../../partials/TopLeftNav'
import { SidebarMenuItem, type SidebarMenuItemProps } from './MenuItem'
import { useMobile, useSettingsSelector } from '../../../utils/index'
import DevModeSwitch from './DevModeSwitch'

const styles = {
  open: {
    transition: (theme: Theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
  },
  close: {
    transition: (theme: Theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    width: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  list: {
    overflowY: 'auto',
  },
  menuBottom: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-end',
  },
  version: {
    fontSize: '10px',
    padding: (theme: Theme) => theme.spacing(1, 2),
  },
}

interface AppVersionProps {
  buildInfo?: BuildInfo
}

const AppVersion = ({ buildInfo }: AppVersionProps) => {
  if (!buildInfo) {
    return <></>
  }

  const commit = buildInfo.commit ? `, ${buildInfo.commit}` : ''
  const buildTime = buildInfo.buildTime.toISOString().slice(0, 19)
  const versionInfo = `${buildInfo.release}, ${buildTime}${commit}`

  return <Typography sx={styles.version}>{versionInfo}</Typography>
}

export type SidebarProps = {
  logoLink: string
  title: 'Network Manager' | 'Event Manager'
  menuItems: (props: {
    userRole: Role
    alarms: { total: number; hasCritical: boolean }
    onClick: SidebarMenuItemProps['onClick']
    devMode?: boolean
  }) => SidebarMenuItemProps[]
  profileUrl: string
}

export const Sidebar = ({ title, logoLink, menuItems, profileUrl }: SidebarProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { isMobile } = useMobile()
  useEffect(() => {
    dispatch(toggleSidebarMenu(!isMobile))
  }, [dispatch, isMobile])

  const { open } = useSelector(({ uiReducer }: GlobalState) => ({ open: uiReducer.open }), shallowEqual)
  const { buildInfo } = useSelector(
    ({ buildInfoReducer }: GlobalState) => ({
      buildInfo: buildInfoReducer.buildInfo,
    }),
    equals,
  )
  const user = useUser()

  const { numAlarms, hasCriticalAlarm } = useSelector(
    ({ alarmsReducer }: GlobalState) => ({
      numAlarms: alarmsReducer.alarmsForNotifications.total,
      hasCriticalAlarm: alarmsReducer.alarmsForNotifications.items.some(
        (alarm) => alarm.alarmSeverity === AlarmSeverityLevels.critical,
      ),
    }),
    shallowEqual,
  )
  const { devMode } = useSettingsSelector()
  const { cachedUrlParams } = useSelector(
    ({ urlParamReducer }: GlobalState) => ({ cachedUrlParams: urlParamReducer.cachedUrlParams }),
    equals,
  )
  // cachedUrlParams: referenced only in order to reload the menu items (and their associated hrefs) when the stored url params are updated in Redux
  void cachedUrlParams
  const logoutUserAction = () => void dispatch(logoutAndNavigateToMainPage())
  const stopImpersonationAction = () => void dispatch(stopImpersonation({ showSuccessSnackbar: true }))
  const handleOpen = () => dispatch(toggleSidebarMenu(true))
  const handleClose = () => dispatch(toggleSidebarMenu(false))
  const handleClickLogo = () => {
    if (isMobile) handleClose()
  }
  const menu = (
    <Box component="nav" id="sidebar" data-test-isopen={open} sx={styles.container}>
      <TopLeftNav title={title} logoLink={logoLink} onClickLogo={handleClickLogo} />
      <Divider />
      <List id="menu" disablePadding sx={styles.list}>
        {menuItems({
          userRole: user.role,
          alarms: { total: numAlarms, hasCritical: hasCriticalAlarm },
          onClick: isMobile ? handleClose : undefined,
          devMode,
        }).map((item, ind) => {
          return <SidebarMenuItem {...item} key={item.url || ind} />
        })}
      </List>
      <Divider />
      <Box sx={styles.menuBottom}>
        <Divider />
        <SidebarMenuItem
          name={user.username}
          icon={<Person />}
          key="/profile"
          url={profileUrl}
          id="profile"
          secondaryText={
            user.impersonatedBy && (
              <Typography variant="body2" component="div">
                <span style={{ opacity: 0.5 }}>
                  (by <span data-test-id="current-impersonator">{user.impersonatedBy.username}</span>)
                </span>
              </Typography>
            )
          }
        />
        <Divider />
        {user.impersonatedBy ? (
          <SidebarMenuItem
            name="Switch to admin"
            icon={<SwitchAccountIcon />}
            id="stop-impersonation"
            onClick={stopImpersonationAction}
          />
        ) : (
          <SidebarMenuItem
            name="Log out"
            icon={<PowerSettingsNew />}
            key="/logout"
            id="logout-button"
            onClick={logoutUserAction}
          />
        )}
        <DevModeSwitch>
          <AppVersion buildInfo={buildInfo} />
        </DevModeSwitch>
      </Box>
    </Box>
  )

  return isMobile ? (
    <SwipeableDrawer
      anchor="left"
      disableSwipeToOpen
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      sx={{ visibility: open !== true ? 'hidden' : undefined }}
    >
      {menu}
    </SwipeableDrawer>
  ) : (
    <Drawer variant="persistent" anchor="left" open={open !== false} sx={open !== false ? styles.open : styles.close}>
      {menu}
    </Drawer>
  )
}
