import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { useInputsSelector, usePageParamsFilteredSelector, useSettingsSelector, useUser } from '../../../utils'
import { type Input, Role, type User } from 'common/api/v1/types'

import { MissingContent } from '../../common/MissingContent'
import Wrapper from '../../common/Wrapper'
import Table from '../../common/Table'

import SendToOutputDialog from './SendToOutput'
import CommonActions from './CommonActions'
import DeleteDialog from './DeleteDialog'
import SharingDialog from './SharingDialog'
import getConfig from './tableConfig'
import { AppDispatch, useRoutes } from '../../../store'
import { Link } from '../../common/Link'
import Button from '@mui/material/Button'
import { registerInputObserver, unregisterInputObserver } from '../../../redux/actions/inputsActions'

export const canEditInput = (user: User, owner: Input['owner']) => {
  return (user.role === Role.admin && user.group === owner) || user.role === Role.super
}

export const List = () => {
  const navigate = useNavigate()
  const { inputs, total, loading } = usePageParamsFilteredSelector(useInputsSelector)

  const inputIds = inputs.map((i) => i.id)
  useEffect(() => {
    dispatch(registerInputObserver({ inputIds }))
    return () => {
      dispatch(unregisterInputObserver({ inputIds }))
    }
  }, [JSON.stringify(inputIds)])

  const user = useUser()
  const dispatch = useDispatch<AppDispatch>()
  const routes = useRoutes()
  const { settings } = useSettingsSelector()
  const expFeatures = settings?.expFeatures

  const [selected, setSelected] = useState<Array<Input['id']>>([])
  useEffect(() => {
    setSelected([])
  }, [inputs.map((i) => i.id).join(',')])

  const handleSelect = (id: Input['id']) => {
    if (selected.includes(id)) {
      setSelected([...selected.filter((item) => item !== id)])
    } else {
      setSelected([...selected, id])
    }
  }

  const handleSelectAll = () => {
    if (selected.length === inputs.length) {
      setSelected([])
    } else {
      setSelected(inputs.reduce<Array<Input['id']>>((acc, { id }) => acc.concat(id), []))
    }
  }

  return (
    <Wrapper
      name="Inputs"
      actions={
        <Link to={routes.inputsNew()}>
          <Button variant="contained" color="primary">
            Create input
          </Button>
        </Link>
      }
    >
      <CommonActions inputs={inputs} selected={selected} setSelected={setSelected} user={user} />
      <Table<Input>
        id="inputs"
        emptyMessageComponent={
          <MissingContent message="No inputs available" buttonText="Create input" url={routes.inputsNew()} />
        }
        config={getConfig({
          dispatch,
          routes,
          handleSelect,
          handleSelectAll,
          inputs,
          selected,
          user,
          experimentalFeatures: expFeatures,
        })}
        rowProps={({ id, owner }) => {
          const isItemSelected = Boolean(id && selected.includes(id))
          return {
            onDoubleClick: () => (canEditInput(user, owner) ? void navigate(routes.inputsUpdate({ id })) : null),
            'aria-checked': isItemSelected,
            tabIndex: -1,
            selected: isItemSelected,
          }
        }}
        data={inputs}
        pagination={{ useUrlSearchParams: true, total }}
        pending={loading}
      />
      <DeleteDialog setSelected={setSelected} />
      <SharingDialog />
      <SendToOutputDialog />
    </Wrapper>
  )
}
