import { ListResult, SearchableFilter } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { AdServer, AdServerInit, DeletedAdServer } from 'common/types'
import { PaginatedRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface IAdServersApi {
  listAdServers(params?: PaginatedRequestParams): Promise<ListResult<AdServer>>
  getAdServer(id: AdServer['id']): Promise<AdServer>
  createAdServer(adServer: AdServerInit): Promise<AdServer>
  updateAdServer(id: AdServer['id'], adServer: AdServer): Promise<AdServer>
  deleteAdServer(id: AdServer['id']): Promise<DeletedAdServer>
}

export class AdServersApi implements IAdServersApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  async listAdServers({ filter, ...params }: PaginatedRequestParams): Promise<ListResult<AdServer>> {
    const query = singleSortQueryFromPaginatedRequestParams<SearchableFilter, string>({
      filter: filter ? { searchName: filter } : {},
      paginatedRequestParams: params,
    })

    return this.edgeClient.listAdServers(query)
  }

  getAdServer(id: string): Promise<AdServer> {
    return this.edgeClient.getAdServer(id)
  }

  createAdServer(adServer: AdServerInit): Promise<AdServer> {
    return this.edgeClient.createAdServer(adServer)
  }

  updateAdServer(id: string, adServer: AdServer): Promise<AdServer> {
    return this.edgeClient.updateAdServer(id, adServer)
  }

  deleteAdServer(id: string): Promise<DeletedAdServer> {
    return this.edgeClient.deleteAdServer(id)
  }
}
