import { Checkbox, Paper, Select } from '../../../../common/Form'
import {
  ApplianceSettings,
  ApplianceType,
  MatroxD4ApplianceSettings,
  MatroxDecoderConfig,
  MatroxDecoderFramerateFamily,
  MatroxDecoderOutputResolution,
  MatroxDecoderOutputResolutionFamilyOne,
  MatroxDecoderOutputResolutionFamilyThree,
  MatroxDecoderOutputResolutionFamilyTwo,
  MatroxE4ApplianceSettings,
  MatroxS1ApplianceSettings,
  MatroxSdiOutputPort,
} from 'common/api/v1/types'
import { RichOption } from '../../../../common/Form/Select'
import {
  getResolutionsInFramerateFamily,
  is4kResolution,
  isMatroxSdi12gPort,
  isResolutionInFramerateFamily,
  pickDecoderGenlockFromApplianceSettings,
} from 'common/matrox'
import { useFormContext, useWatch } from 'react-hook-form'

enum MatroxSdiFields {
  decoderSettings = 'decoderSettings',
}

export function matroxSdiPortDefaults(
  applianceType?: ApplianceType,
  settings?: ApplianceSettings,
): {
  [MatroxSdiFields.decoderSettings]: MatroxDecoderConfig
} {
  const currentlySelectedFamily =
    settings && 'genlock' in settings && 'framerateFamily' in settings.genlock
      ? settings.genlock.framerateFamily
      : MatroxDecoderFramerateFamily.familyThree

  const options = getResolutionOptions(0, currentlySelectedFamily, applianceType, undefined)
  const initialOption = options.find((option) => !option.disabled) ?? options[0]
  return {
    [MatroxSdiFields.decoderSettings]: {
      outputSettings: {
        isAncEnabled: false,
        resolution: initialOption.value as MatroxDecoderOutputResolution,
      },
    },
  }
}

export const getMatroxSdiPortFormFields = () => {
  const fields: string[] = [MatroxSdiFields.decoderSettings]
  return fields
}

interface Props {
  namePrefix: string
  physicalPortIndex: string
  port: MatroxSdiOutputPort
  applianceType: ApplianceType
  settings: MatroxD4ApplianceSettings | MatroxS1ApplianceSettings | MatroxE4ApplianceSettings
}

// Expected format '720p50', '1080i25'
const outputResolutionRegexp = /([0-9]+)([a-zA-Z])([0-9]+)/
function compareAscendingByResolutionScanAndFramerate(a: string, b: string): number {
  const [_, heightAStr, scanMethodA, frameRateAStr] = a.match(outputResolutionRegexp) ?? []
  const [__, heightBStr, scanMethodB, frameRateBStr] = b.match(outputResolutionRegexp) ?? []
  if (heightAStr && scanMethodA && frameRateAStr && heightBStr && scanMethodB && frameRateBStr) {
    const heightA = Number(heightAStr)
    const heightB = Number(heightBStr)
    if (heightA === heightB) {
      if (scanMethodA === scanMethodB) {
        const frameRateA = Number(frameRateAStr)
        const frameRateB = Number(frameRateBStr)
        return frameRateA - frameRateB
      } else {
        return scanMethodA.localeCompare(scanMethodB)
      }
    } else {
      return heightA - heightB
    }
  }
  return a.localeCompare(b)
}

function getResolutionOptions(
  portIndex: number,
  currentlySelectedFamilyOnDevice: MatroxDecoderFramerateFamily,
  applianceType: ApplianceType | undefined,
  currentResolution: string | undefined,
): RichOption[] {
  const sdi4kTooltip = 'will disable all other SDI ports on the appliance'
  const notAvailable4kTooltip = 'only available on the D4 12G SDI-4 port'

  const allFramerateFamilies = [
    MatroxDecoderFramerateFamily.familyOne,
    MatroxDecoderFramerateFamily.familyTwo,
    MatroxDecoderFramerateFamily.familyThree,
  ]

  function formattedFramerate(res: MatroxDecoderOutputResolution): string {
    // Convert frame rate to field rate for interlaced resolutions to be consistent with other Network Manager UI
    // (Matrox displays everything as frame rate).
    switch (res) {
      case MatroxDecoderOutputResolutionFamilyOne['1080i25']:
        return '1080i50'
      case MatroxDecoderOutputResolutionFamilyTwo['1080i29.97']:
        return '1080i59.94'
      case MatroxDecoderOutputResolutionFamilyThree['1080i30']:
        return '1080i60'
      default:
        return res
    }
  }
  const allFramerates = allFramerateFamilies.flatMap(getResolutionsInFramerateFamily)
  const isD4 = applianceType === undefined || applianceType === ApplianceType.matroxMonarchEdgeD4
  const options: RichOption[] = allFramerates.map((res) => ({
    name: formattedFramerate(res),
    value: res,
    disabled:
      // 4K resolution only available on 12G port on D4
      (is4kResolution(res) && (!isD4 || !isMatroxSdi12gPort(portIndex))) ||
      !isResolutionInFramerateFamily(res, currentlySelectedFamilyOnDevice),
    tooltip: is4kResolution(res)
      ? isD4 && isMatroxSdi12gPort(portIndex)
        ? sdi4kTooltip
        : notAvailable4kTooltip
      : undefined,
  }))

  if (currentResolution && !options.find((res) => res.value === currentResolution)) {
    options.push({
      name: currentResolution,
      value: currentResolution,
      disabled: true,
      tooltip: 'Not a member of the current framerate family on the appliance',
    })
  }
  return options.sort((a, b) => compareAscendingByResolutionScanAndFramerate(a.value!.toString(), b.value!.toString()))
}

const MatroxSdiPortForm = ({ namePrefix, physicalPortIndex, port, settings, applianceType }: Props) => {
  const isRunningMatroxV2 = false // FIXME MG: Uncomment when we wish to support matrox v2 features: settings.apiVersion === 'v2'

  const genlock = pickDecoderGenlockFromApplianceSettings(settings)!
  const resolutionOptions = getResolutionOptions(
    Number(physicalPortIndex),
    genlock.framerateFamily,
    applianceType,
    port.decoderSettings.outputSettings.resolution,
  )

  const useInputStreamResolutionKey = `${namePrefix}.${MatroxSdiFields.decoderSettings}.followInputStreamResolution`
  useWatch({ name: useInputStreamResolutionKey }) // Enables disables resolution select
  const { getValues } = useFormContext()
  const decoderSettings = getValues(`${namePrefix}.${MatroxSdiFields.decoderSettings}`) as MatroxDecoderConfig

  return (
    <Paper title="Output Settings">
      {isRunningMatroxV2 && (
        <Checkbox
          label={'Follow input stream resolution'}
          name={useInputStreamResolutionKey}
          tooltip={
            'Will output the same resolution as the input stream if compatible with the selected framerate family device - else the nearest compatible resolution will be outputted.'
          }
        />
      )}
      <Select
        label="Resolution"
        name={`${namePrefix}.${MatroxSdiFields.decoderSettings}.outputSettings.resolution`}
        disabled={decoderSettings.followInputStreamResolution}
        required
        tooltip={
          'Only resolutions belonging to the currently selected frame rate family on the appliance are selectable.\n\nThe frame rate family of the appliance can be can be changed in the Appliance Details page.'
        }
        options={resolutionOptions}
        validators={{
          oneOf: { validValues: new Set(resolutionOptions.filter((o) => !o.disabled).map((o) => o.value)) },
        }}
      />
      <Checkbox
        newLine
        label={'Include ancillary data'}
        name={`${namePrefix}.${MatroxSdiFields.decoderSettings}.outputSettings.isAncEnabled`}
      />
    </Paper>
  )
}

export default MatroxSdiPortForm
