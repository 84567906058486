import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import type { Theme } from '@mui/material/styles'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { ListOutputSortableField, Role } from 'common/api/v1/types'
import { Link, type LinkProps } from '../common/Link'
import { TopLeftLogo } from '../common/LogoFetcher'
import { useRoutes } from '../../store'
import { useSettingsSelector, useUser } from '../../utils/hooks'

const styles = {
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '48px',
    marginY: 1,
    marginX: 2,
  },
  system: {
    lineHeight: '14px',
  },
  group: {
    fontSize: '10px',
    lineHeight: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttonWrapper: {
    textTransform: 'none',
    overflow: 'hidden',
  },
  icon: {
    color: (theme: Theme) => theme.palette.text.secondary,
  },
  serviceSelector: {
    color: (theme: Theme) => theme.palette.text.primary,
  },
}
export type TopLeftNavProps = {
  title: 'Network Manager' | 'Stream Manager' | 'Event Manager'
  onClickLogo?: React.MouseEventHandler<HTMLDivElement>
  logoLink: LinkProps['to']
}

/**
 * Logo for both network manager and Stream Manager top left corner
 * @param onClickLogo - Link of TopLeftLogo
 */
export const TopLeftNav = ({ title, onClickLogo, logoLink }: TopLeftNavProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const routes = useRoutes()
  const user = useUser()
  const { settings } = useSettingsSelector()

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)

  const defaultOrder = settings?.expFeatures?.ExtHealthAlarms ? ListOutputSortableField.healthStatus : undefined // 'healthStatus' for Input/Output
  return (
    <Box sx={styles.logoWrapper}>
      <Link to={logoLink}>
        <TopLeftLogo onClick={onClickLogo} />
      </Link>
      <Button sx={{ marginLeft: 1 }} onClick={handleOpen} endIcon={<ExpandMore sx={styles.icon} />}>
        <Box sx={styles.buttonWrapper}>
          <Typography component="div" sx={styles.system} variant="caption" color="textPrimary" align="left">
            {title}
          </Typography>
          <Typography component="div" sx={styles.group} variant="caption" color="textSecondary" align="left">
            {!!user._group && user._group.name}
          </Typography>
        </Box>
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {user.role !== Role.basic ? (
          <MenuItem
            disabled={title === 'Network Manager'}
            component={Link}
            to={routes.overview()}
            muiStyle={styles.serviceSelector}
            onClick={handleClose}
          >
            Network Manager
          </MenuItem>
        ) : null}
        <MenuItem
          disabled={title === 'Stream Manager'}
          component={Link}
          to={routes.stream()}
          muiStyle={styles.serviceSelector}
          onClick={handleClose}
        >
          Stream Manager
        </MenuItem>
        <MenuItem
          disabled={title === 'Event Manager'}
          component={Link}
          to={routes.events({ desc: defaultOrder })}
          muiStyle={styles.serviceSelector}
          onClick={handleClose}
        >
          Event Manager
        </MenuItem>
      </Menu>
    </Box>
  )
}
