import { useDispatch } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew'
import Person from '@mui/icons-material/Person'

import { TopLeftNav } from '../partials/TopLeftNav'
import { Link } from '../common/Link'
import { logoutAndNavigateToMainPage, stopImpersonation } from '../../redux/actions/userActions'
import { useUser } from '../../utils'
import { type AppDispatch, useRoutes } from '../../store'

const styles = {
  logoutButton: {
    marginLeft: 3,
  },
}

export const Header = () => {
  const user = useUser()
  const routes = useRoutes()
  const dispatch = useDispatch<AppDispatch>()
  const logoutUserAction = () => void dispatch(logoutAndNavigateToMainPage())
  const stopImpersonationAction = () => void dispatch(stopImpersonation({ showSuccessSnackbar: true }))

  return (
    <AppBar position="sticky">
      <Toolbar disableGutters sx={{ paddingRight: 2 }}>
        <TopLeftNav title="Stream Manager" logoLink={routes.stream()} />

        <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <Link to={routes.streamProfile.route} underline="hover">
            <Typography variant="body2" data-test-id="current-user">
              {user.username}
            </Typography>
          </Link>
          <Typography variant="body2">
            {user.impersonatedBy && (
              <span style={{ opacity: 0.5, marginLeft: 4 }}>
                (by <span data-test-id="current-impersonator">{user.impersonatedBy.username}</span>)
              </span>
            )}
          </Typography>
          {user.impersonatedBy ? (
            <Button endIcon={<Person />} onClick={stopImpersonationAction} sx={styles.logoutButton}>
              <Typography variant="body2" color="textSecondary" id="stop-impersonation">
                SWITCH TO ADMIN
              </Typography>
            </Button>
          ) : (
            <Button endIcon={<PowerSettingsNew />} onClick={logoutUserAction} sx={styles.logoutButton}>
              <Typography variant="body2" color="textSecondary">
                LOG OUT
              </Typography>
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  )
}
