import { Navigate, Route, Routes } from 'react-router'

import { NotFound } from '../common/NotFound'
import { ProductionPage } from './ProductionPage'
import { ROUTES, SUB_PATH } from '../../utils/routes'
import { EditOverlay } from './overlay/EditOverlay'

export const Production = () => {
  return (
    <Routes>
      <Route path={`overlays/${SUB_PATH.UPDATE}`} element={<EditOverlay />} />
      <Route path={`overlays/${SUB_PATH.NEW}`} element={<EditOverlay />} />
      <Route path={`overlays/${SUB_PATH.COPY}`} element={<EditOverlay />} />
      <Route path={`overlays`} element={<Navigate to={ROUTES.production} />} />
      <Route index element={<ProductionPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
