import {
    ApplianceType,
    CommonApplianceSettings,
    EncoderSettings,
    GeneralEncoderSettings,
    InputPort,
    MatroxD4ApplianceSettings,
    MatroxDecoderFramerateFamily,
    MatroxDecoderGenlock,
    MatroxDecoderOutputResolution,
    MatroxDecoderOutputResolutionFamilyOne,
    MatroxDecoderOutputResolutionFamilyThree,
    MatroxDecoderOutputResolutionFamilyTwo,
    MatroxE4ApplianceSettings,
    MatroxEncoderConfig,
    MatroxEncoderIOConfig,
    MatroxInputPort,
    MatroxOutputPort,
    MatroxPortMode,
    MatroxS1ApplianceSettings,
    MatroxSdiInputPort,
    OutputPort,
    PhysicalPort,
    UpdateAppliancePayloadApplianceSettings,
} from './api/v1/types'

// A virtual interface is used to represent a grid layout matrox E4 input (receiving from multiple SDI interfaces at once.)
export function isVirtualAggregatedPort(port: Pick<PhysicalPort, 'index'>): boolean {
    return port.index === '0,1,2,3'
}

// A virtual interface is used to represent a grid layout matrox E4 input (receiving from multiple SDI interfaces at once.)
export function isVirtualAggregatedLogicalPort(lp: InputPort | OutputPort): lp is MatroxSdiInputPort {
    return (
        lp.mode === MatroxPortMode.matroxSdi &&
        'encoderSettings' in lp &&
        lp.encoderSettings.processorSettings.videoLayout === 'quad'
    )
}

export const RESOLUTION_4K = 2160
export function is4kResolution(resolution: MatroxDecoderOutputResolution | string) {
    return resolution.includes(RESOLUTION_4K.toString())
}

export function isMatroxSdi12gPort(edgePortIndex: number): boolean {
    // The first 3 SDI ports are 3G and the fourth is 12G (4k capabilities).
    return edgePortIndex === 3
}

export function isMatroxOutputPort(outputPort: OutputPort): outputPort is MatroxOutputPort {
    return outputPort.mode === MatroxPortMode.matroxSdi
}

export function isResolutionInFramerateFamily(
    resolution: MatroxDecoderOutputResolution,
    framerateFamily: MatroxDecoderFramerateFamily
) {
    const familyMembers = getResolutionsInFramerateFamily(framerateFamily)
    return familyMembers.includes(resolution)
}

export function getResolutionsInFramerateFamily(
    framerateFamily: MatroxDecoderFramerateFamily
): MatroxDecoderOutputResolution[] {
    switch (framerateFamily) {
        case MatroxDecoderFramerateFamily.familyOne:
            return Object.values(MatroxDecoderOutputResolutionFamilyOne)
        case MatroxDecoderFramerateFamily.familyTwo:
            return Object.values(MatroxDecoderOutputResolutionFamilyTwo)
        case MatroxDecoderFramerateFamily.familyThree:
            return Object.values(MatroxDecoderOutputResolutionFamilyThree)
    }
    throw new Error(`Unknown framerate family: ${framerateFamily}`)
}

export function getFramerateFamilyForResolution(
    resolution: MatroxDecoderOutputResolution
): MatroxDecoderFramerateFamily {
    switch (resolution) {
        // Family One
        case MatroxDecoderOutputResolutionFamilyOne['720p50']:
        case MatroxDecoderOutputResolutionFamilyOne['1080p25']:
        case MatroxDecoderOutputResolutionFamilyOne['1080p50']:
        case MatroxDecoderOutputResolutionFamilyOne['1080i25']:
        case MatroxDecoderOutputResolutionFamilyOne['2160p50']:
            return MatroxDecoderFramerateFamily.familyOne

        // Family Two
        case MatroxDecoderOutputResolutionFamilyTwo['720p59.94']:
        case MatroxDecoderOutputResolutionFamilyTwo['1080p23.98']:
        case MatroxDecoderOutputResolutionFamilyTwo['1080p29.97']:
        case MatroxDecoderOutputResolutionFamilyTwo['1080p59.94']:
        case MatroxDecoderOutputResolutionFamilyTwo['1080i29.97']:
        case MatroxDecoderOutputResolutionFamilyTwo['2160p59.94']:
            return MatroxDecoderFramerateFamily.familyTwo

        // Family Three
        case MatroxDecoderOutputResolutionFamilyThree['720p60']:
        case MatroxDecoderOutputResolutionFamilyThree['1080p24']:
        case MatroxDecoderOutputResolutionFamilyThree['1080p30']:
        case MatroxDecoderOutputResolutionFamilyThree['1080p60']:
        case MatroxDecoderOutputResolutionFamilyThree['1080i30']:
        case MatroxDecoderOutputResolutionFamilyThree['2160p60']:
            return MatroxDecoderFramerateFamily.familyThree
    }
}

export function getNumberOfInputSdiPortsFromIOConfig(ioConfig: MatroxEncoderIOConfig | undefined) {
    if (!ioConfig) {
        return 4
    }
    switch (ioConfig) {
        case MatroxEncoderIOConfig.fourZero:
            return 4
        case MatroxEncoderIOConfig.threeOne:
            return 3
        case MatroxEncoderIOConfig.twoTwo:
            return 2
    }
}

// Determine which SDI ports are disappearing after reconfiguration.
// Returns port indexes of disappearing/repurposed ports.
export function determineRepurposedSdiPorts(
    currentIoConfig: MatroxEncoderIOConfig,
    newIoConfig: MatroxEncoderIOConfig
) {
    const repurposedSdiInputs: number[] = []
    const repurposedSdiOutputs: number[] = []
    switch (currentIoConfig) {
        case MatroxEncoderIOConfig.fourZero: {
            switch (newIoConfig) {
                case MatroxEncoderIOConfig.threeOne:
                    // 4:0 --> 3:1
                    // inputs: [0,1,2,3] --> [0,1,2]
                    // outputs: [] --> [3]
                    repurposedSdiInputs.push(3)
                    break
                case MatroxEncoderIOConfig.twoTwo:
                    // 4:0 --> 2:2
                    // inputs: [0,1,2,3] --> [0,1]
                    // outputs: [] --> [2,3]
                    repurposedSdiInputs.push(2, 3)
                    break
            }
            break
        }

        case MatroxEncoderIOConfig.threeOne: {
            switch (newIoConfig) {
                case MatroxEncoderIOConfig.fourZero:
                    // 3:1 --> 4:0
                    // inputs: [0,1,2] --> [0,1,2,3]
                    // outputs: [3] --> []
                    repurposedSdiOutputs.push(3)
                    break
                case MatroxEncoderIOConfig.twoTwo:
                    // 3:1 --> 2:2
                    // inputs: [0,1,2] --> [0,1]
                    // outputs: [3] --> [2,3]
                    repurposedSdiInputs.push(2)
                    break
            }
            break
        }

        case MatroxEncoderIOConfig.twoTwo: {
            switch (newIoConfig) {
                case MatroxEncoderIOConfig.fourZero:
                    // 2:2 --> 4:0
                    // inputs: [0,1] --> [0,1,2,3]
                    // outputs: [2,3] --> []
                    repurposedSdiOutputs.push(2, 3)
                    break
                case MatroxEncoderIOConfig.threeOne:
                    // 2:2 --> 3:1
                    // inputs: [0,1] --> [0,1,2]
                    // outputs: [2,3] --> [3]
                    repurposedSdiOutputs.push(2)
                    break
            }
            break
        }
    }

    return { repurposedSdiInputs, repurposedSdiOutputs }
}

export function getSdiPortIndexesUsedByMatroxLogicalPort(p: MatroxInputPort) {
    const audioPort = p.encoderSettings.processorSettings.audioSourceSettings.portIndex
    const videoPorts = p.encoderSettings.processorSettings.videoSourceSettings.map((s) => s.portIndex)
    return [audioPort, ...videoPorts]
}

export function translateRepurposedSdiPortIndex(
    direction: 'edgeToMatrox' | 'matroxToEdge',
    portIndex: number,
    numberOfInputSdiConnectors: number,
    applianceType: ApplianceType
) {
    const isRepurposedInputSdiPort = applianceType === ApplianceType.matroxMonarchEdgeE4_10Bit
    if (!isRepurposedInputSdiPort) return portIndex
    // Using SetIOConfig() in Matrox api v2 on the E4-10bit, SDI input ports can be repurposed to become SDI output ports.
    // Matrox start the output SDI portIndex at 0, but Edge uses the original port index of the SDI port,
    // e.g. Edge uses [0:input,1:input,2:output,3:output], while Matrox uses input:[0,1] and output: [0,1]
    // hence we need to translate the edge portIndex --> matrox portIndex.
    const offset = numberOfInputSdiConnectors
    return direction === 'edgeToMatrox' ? portIndex - offset : portIndex + offset
}

type DecoderGenlock = {
    source: 'internal' | 'external'
    framerateFamily: '25/50' | '29.97/59.94' | '30/60'
}
export function pickDecoderGenlockFromApplianceSettings(
    settings: { genlock: DecoderGenlock } | { decoderGenlock?: DecoderGenlock }
): MatroxDecoderGenlock | undefined {
    const isDecoderGenlock = (genlock: object): genlock is MatroxDecoderGenlock =>
        'source' in genlock && 'framerateFamily' in genlock

    if ('decoderGenlock' in settings && settings.decoderGenlock && isDecoderGenlock(settings.decoderGenlock)) {
        return settings.decoderGenlock
    }
    if ('genlock' in settings && isDecoderGenlock(settings.genlock)) {
        return settings.genlock
    }
    return undefined
}

export function matroxApiVersionFromSettings(
    settings: MatroxS1ApplianceSettings | MatroxD4ApplianceSettings | MatroxE4ApplianceSettings
) {
    return settings.apiVersion ?? 'v1.4'
}

export function isMatroxApplianceSettings(
    settings: CommonApplianceSettings | UpdateAppliancePayloadApplianceSettings
): settings is MatroxE4ApplianceSettings | MatroxS1ApplianceSettings | MatroxD4ApplianceSettings {
    return 'genlock' in settings
}

export function isMatroxDecoderSettings(
    settings: CommonApplianceSettings | UpdateAppliancePayloadApplianceSettings
): settings is MatroxS1ApplianceSettings | MatroxD4ApplianceSettings {
    return 'genlock' in settings
}

export function isMatroxEncoderSettings(
    settings: CommonApplianceSettings | UpdateAppliancePayloadApplianceSettings
): settings is MatroxE4ApplianceSettings | MatroxS1ApplianceSettings {
    return 'sdiPortsWithPcmModeEnabled' in settings
}

export function isMatroxEncoderConfig(settings: EncoderSettings): settings is MatroxEncoderConfig {
    return 'type' in settings && settings.type === 'matroxEncoder'
}

export function isGeneralEncoderSettings(settings: EncoderSettings): settings is GeneralEncoderSettings {
    return !isMatroxEncoderConfig(settings)
}
