import type { DeletedOverlay, ListResult, Overlay, SearchableFilter } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { PaginatedRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface IOverlaysApi {
  listOverlays(params?: PaginatedRequestParams): Promise<ListResult<Overlay>>
  getOverlay(id: Overlay['id']): Promise<Overlay>
  updateOverlay(overlay: Overlay): Promise<Overlay>
}

export class OverlaysApi implements IOverlaysApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  listOverlays({ filter, ...params }: PaginatedRequestParams): Promise<ListResult<Overlay>> {
    const query = singleSortQueryFromPaginatedRequestParams<SearchableFilter, string>({
      filter: filter ? { searchName: filter } : {},
      paginatedRequestParams: params,
    })

    return this.edgeClient.listOverlays(query)
  }

  getOverlay(id: string): Promise<Overlay> {
    return this.edgeClient.getOverlay(id)
  }

  updateOverlay(overlay: Overlay): Promise<Overlay> {
    return this.edgeClient.createOverlay(overlay)
  }

  deleteOverlay(id: string): Promise<DeletedOverlay> {
    return this.edgeClient.deleteOverlay(id)
  }
}
