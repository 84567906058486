import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'

import { EnrichedInput } from '../../../api/nm-types'
import { TranscodingSection } from './TranscodingSection'
import { MptsDemuxSection } from './MptsDemuxSection'
import { Paper, Select } from '../../common/Form'
import { InputFormProps } from '../Edit/InputForm'
import { AudioReshuffling } from './AudioReshuffling'
import { formatTransformationType, useSettingsSelector } from '../../../utils'
import { useFormContext } from 'react-hook-form'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'

export interface InputTransformationSectionProps {
  currentParentInput: EnrichedInput
}

export const InputTransformationSection = ({ currentParentInput }: InputTransformationSectionProps) => {
  const { getValues, watch } = useFormContext<InputFormProps & AllowUncontrolled>()
  const { devMode } = useSettingsSelector()
  const [transformation, setTransformation] = useState<'mpts-demux' | 'transcode' | 'audio-reshuffling' | ''>('')

  const values = getValues()

  // Watch specific fields that control conditional rendering
  watch('deriveFrom.ingestTransform.type')

  useEffect(() => {
    if (values.deriveFrom?.ingestTransform?.type) {
      setTransformation(values.deriveFrom.ingestTransform.type)
    }
  }, [values.deriveFrom?.ingestTransform?.type])

  const options = [{ name: formatTransformationType('mpts-demux'), value: 'mpts-demux' }]

  if (devMode) {
    options.push(
      { name: formatTransformationType('transcode'), value: 'transcode' },
      { name: formatTransformationType('audio-reshuffling'), value: 'audio-reshuffling' },
    )
  }

  return (
    <Paper className="outlined" title="Transformation">
      <Paper>
        <Select label="Select transformation" name="deriveFrom.ingestTransform.type" options={options} />

        {transformation === 'mpts-demux' && (
          <Grid item xs={12}>
            <MptsDemuxSection currentParentInput={currentParentInput} />
          </Grid>
        )}
        {transformation === 'transcode' && <TranscodingSection />}

        {transformation === 'audio-reshuffling' && <AudioReshuffling />}
      </Paper>
    </Paper>
  )
}
