import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Paper, TextInput } from '../../../components/common/Form'
import { DeleteStoragePoolDialog } from './DeleteStoragePoolDialog'
import { AddStoragePoolDialog } from './AddStoragePoolDialog'
import { useConfirmationDialog } from '../../common/ConfirmDialog'
import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import { Service } from 'common/api/v1/types'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'

export const StoragePools = () => {
  const { getValues, trigger, formState } = useFormContext<Service & AllowUncontrolled>()
  const values = getValues()

  const { fields, append: appendField, remove: removeField } = useFieldArray({ name: 'storagePools' })

  const theme = useTheme()
  const { showConfirmation } = useConfirmationDialog()

  const [isAddingStoragePool, setIsAddingStoragePool] = useState(false)

  const onAddStoragePool = () => {
    if (!values.storagePools || formState.errors.storagePools) {
      return
    }

    const newPool = {
      name: values.storagePools[values.storagePools.length - 1].name,
      servers: values.storagePools[values.storagePools.length - 1].servers,
      size: values.storagePools[values.storagePools.length - 1].size,
    }

    showConfirmation(
      () => {
        setIsAddingStoragePool(false)
      },
      <AddStoragePoolDialog storagePool={newPool} />,
      {
        ok: { text: 'Confirm', variant: 'outlined' },
        cancel: { variant: 'contained' },
      },
      () => {
        trigger()
      },
    )
  }

  const onRemoveStoragePool = (index: number) => {
    showConfirmation(
      () => {
        removeField(index)
      },
      <DeleteStoragePoolDialog storagePool={values.storagePools![index]} />,
      {
        ok: { text: 'Confirm', variant: 'outlined' },
        cancel: { variant: 'contained' },
      },
      () => {
        trigger()
      },
    )
  }

  return (
    <Paper
      title="Storage pools"
      tooltip={
        'Add a storage pool to increase the storage capacity of the service. Existing storage pools cannot be edited.'
      }
    >
      <Grid container sx={{ mt: 3 }}>
        {fields.map((field, index) => {
          const storagePool = values.storagePools![index]
          if (isAddingStoragePool && index === values.storagePools!.length - 1) {
            return null
          }

          return (
            <Grid
              item
              xs={8}
              sm={7}
              lg={5}
              key={field.id}
              sx={{
                width: '100px',
                m: 3,
                mt: 0,
                p: 3,
                border: '2px solid',
                borderColor: theme.palette.grey[800],
                borderRadius: '10px',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h3">{storagePool.name}</Typography>
                <Tooltip
                  title="Deleting the storage pool will reduce the storage capacity of the service. All volumes persist in the cluster,
        but they are not reusable by MinIO."
                >
                  <IconButton onClick={() => onRemoveStoragePool(index)} disabled={isAddingStoragePool}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Divider />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
                <Typography>
                  Nodes: <strong>{storagePool.servers}</strong>
                </Typography>
                <Typography>
                  Node volume size: <strong>{storagePool.size}</strong>
                </Typography>
                <Typography>
                  Volumes per node: <strong>2</strong>
                </Typography>
              </Box>
            </Grid>
          )
        })}
      </Grid>

      {isAddingStoragePool && (
        <Paper sx={{ width: '40%', mr: '60%' }}>
          <TextInput
            name={`storagePools[${values.storagePools!.length - 1}].name`}
            label="Name"
            tooltip="The name of the new storage pool."
            required
            validators={{
              k8sName: {},
              notOneOf: {
                invalidValues: new Set(
                  values.storagePools!.slice(0, values.storagePools!.length - 1).map((pool) => pool.name),
                ),
                errorMsg: 'Name must be unique',
              },
            }}
          />
          <TextInput
            name={`storagePools[${values.storagePools!.length - 1}].servers`}
            label="Nodes"
            type="number"
            tooltip="Number of nodes in the storage pool."
            required
            validators={{
              number: {
                onlyInteger: true,
                greaterThanOrEqualTo: 3,
                message: 'Must be at least 3 servers',
              },
            }}
          />
          <TextInput
            newLine
            name={`storagePools[${values.storagePools!.length - 1}].size`}
            label="Volume size"
            tooltip="The size of each volume in the storage pool. Supports suffixes like G, M, Gi and Mi."
            required
            validators={{
              k8sMemoryString: {
                min: '5G',
                message: 'Must be at least 5G',
              },
            }}
          />
          <TextInput name={`_volumesPerNode`} label="Volumes per node" value="2" disabled />
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
            <Button
              variant="outlined"
              onClick={() => {
                removeField(values.storagePools!.length - 1)
                setIsAddingStoragePool(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onAddStoragePool()
              }}
            >
              OK
            </Button>
          </Box>
        </Paper>
      )}

      <Button
        variant="contained"
        onClick={() => {
          appendField({ name: '', servers: 3, size: '5Gi' })
          setIsAddingStoragePool(true)
          trigger()
        }}
        disabled={isAddingStoragePool}
      >
        Add storage pool
      </Button>
    </Paper>
  )
}
