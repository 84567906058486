import { createAsyncThunk } from '@reduxjs/toolkit'
import { DeletedOverlay, Overlay } from 'common/api/v1/types'
import { AppDispatch, ThunkApi } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { PaginatedRequestParams } from '../../api/nm-types'
import { IOverlaysApi } from '../../api/overlays/api'
import { withDefaultPagination } from '../../utils'

// for react-async-hook
export const listOverlaysHook = async (
  params: Partial<PaginatedRequestParams>,
  { dispatch, overlaysApi }: { dispatch: AppDispatch; overlaysApi: IOverlaysApi },
) => {
  try {
    return await overlaysApi.listOverlays(withDefaultPagination(params))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch overlays' }))
    throw err
  }
}

export const listOverlays = createAsyncThunk<{ total: number; items: Overlay[] }, PaginatedRequestParams, ThunkApi>(
  'overlays/listOverlays',
  async (params, { extra: { api } }) => {
    return await api.overlaysApi.listOverlays(params)
  },
)

export const getOverlay = createAsyncThunk<Overlay, string, ThunkApi>(
  'overlays/getOverlay',
  async (id, { dispatch, extra: { api } }) => {
    try {
      return await api.overlaysApi.getOverlay(id)
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'get overlay' }))
      throw error
    }
  },
)

export const updateOverlay = createAsyncThunk<Overlay, Overlay, ThunkApi>(
  'overlays/updateOverlay',
  async (overlay, { dispatch, extra: { api } }) => {
    try {
      const res = await api.overlaysApi.updateOverlay(overlay)
      dispatch(enqueueSuccessSnackbar('Overlay saved'))
      return res
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'update overlay' }))
      throw error
    }
  },
)

export const deleteOverlay = createAsyncThunk<DeletedOverlay, string, ThunkApi>(
  'overlays/deleteOverlay',
  async (id, { dispatch, extra: { api } }) => {
    try {
      const res = await api.overlaysApi.deleteOverlay(id)
      dispatch(enqueueSuccessSnackbar('Overlay deleted'))
      return res
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'delete overlay' }))
      throw error
    }
  },
)
