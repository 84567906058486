import {
  AlarmSeverityLevels,
  type AlarmWithImpact,
  Appliance,
  AppliancePhysicalPortInfo,
  ApplianceReference,
  ApplianceType,
  AuditLogOperationResult,
  AuditOperation,
  Group,
  GroupFilter,
  GroupInputPermission,
  Input,
  InputPort,
  KubernetesNode,
  ListAlarmSortableField,
  ListApplianceSortableField,
  ListGroupSortableField,
  ListInputSortableField,
  ListIpMappingSortableField,
  ListKubernetesNodeSortableField,
  ListOutputSortableField,
  ListPortSortableField,
  ListServiceSortableField,
  ListUserSortableField,
  type MetadataTag,
  MetricWindow,
  Network,
  NetworkSortableField,
  NewUser,
  Output,
  OutputPort,
  OutputRecipientList,
  PhysicalPort,
  PhysicalPortInfo,
  PortType,
  type Region,
  SortOrder,
  SrtMode,
  User,
  UserSettings,
} from 'common/api/v1/types'
import { Query } from 'common/query'

export interface EnrichedInput extends Input {
  _redundant?: boolean
  _owner?: Group
}

export interface EnrichedInputWithPorts extends EnrichedInput {
  ports?: EnrichedInputPort[]
}

export type EnrichedInputPort = InputPort & {
  _port: PhysicalPort & { _appliance?: ApplianceReference & Partial<Pick<Appliance, 'settings'>> }
}

export interface EnrichedUser extends User {
  _group: Omit<Group, 'applianceSecret'>
  _mfa?: {
    _totp: 'Configured' | 'Not configured'
  }
}

export interface EnrichedUserWithSettings extends EnrichedUser {
  // Settings can be undefined if user is logged in during upgrade to R3.21.0 (where user settings was introduced)
  // It will be populated next login though.
  settings?: UserSettings
}

export interface EnrichedOutput extends Output {
  _input?: Input
  _group?: Group
}

export type EnrichedOutputPort = OutputPort & { _port: PhysicalPort & { _appliance: Appliance } }

export interface EnrichedOutputWithPorts extends Output {
  ports: EnrichedOutputPort[]
  _input?: EnrichedInput
  _group?: Group
}

export interface EnrichedGroup extends Group {
  _permission?: GroupInputPermission
}

export interface EnrichedOutputRecipientList extends OutputRecipientList {
  _hasOutputsInUse?: boolean
  _group?: Group
}

export type EnrichedRegion = Region & {
  _externalText?: 'core' | 'external (kubernetes)' | 'external' | 'N/A'
  appliancesTotal?: number
}

export type PhysicalPortInfoWithAppliance = PhysicalPortInfo & { appliance: AppliancePhysicalPortInfo['appliance'] }

export interface EnrichedPhysicalPort extends PhysicalPort {
  _owner: Group
  _appliance?: EnrichedApplianceWithOwner
}

export type EnrichedAppliance = Appliance & {
  _physicalPorts: Array<PhysicalPort & { _owner: Group }>
  _restarting?: boolean
}
export type EnrichedApplianceWithOwner = Appliance & {
  _owner: Group
  _restarting?: boolean
}

export type EnrichedTag = MetadataTag & {
  _group: Group
}

export interface PaginatedRequestParams<TSort extends string = string> {
  pageNumber: string
  rowsPerPage: string
  // filter is used for populating "Query.searchName"
  filter?: string
  asc?: TSort
  desc?: TSort
}

export enum InputFilterHealthStatus {
  healthy = 'healthy',
  unhealthy = 'unhealthy',
}
export enum OutputFilterHealthStatus {
  healthy = 'healthy',
  unhealthy = 'unhealthy',
}
export interface InputsRequestParams extends PaginatedRequestParams<ListInputSortableField> {
  /** @param canSubscribe - to show only those you have access to send to output */
  canSubscribe?: boolean
  applianceId?: string
  derived?: boolean
  appliances?: string
  regions?: string
  owner?: string
  adminStatus?: string
  health?: string
  tags?: string
}

export interface UsageRequestParams extends PaginatedRequestParams {
  startDate: Date
  endDate: Date
  type?: 'egress' | 'ingress'
  format?: 'csv'
}

export interface OutputsRequestParams extends PaginatedRequestParams<ListOutputSortableField> {
  applianceId?: string
  hasInput?: boolean
  output?: Output['id']
  input?: Input['id']
  notInput?: Input['id']
  inputTr101290Window?: MetricWindow

  appliances?: string
  regions?: Region['name']
  inputName?: Input['name']
  group?: Group['name']
  adminStatus?: string
  health?: string
  tags?: string
}
export interface OutputListRequestParams extends PaginatedRequestParams {}

export type GroupsRequestParams = GroupFilter &
  PaginatedRequestParams<ListGroupSortableField> & {
    inputShared?: Input['id']
    inputNotShared?: Input['id']
    userGroup?: string
  }

export interface GroupListRequestParams extends PaginatedRequestParams {}
export interface ServiceOverviewRequestParams extends PaginatedRequestParams {}

export interface UsersRequestParams extends PaginatedRequestParams<ListUserSortableField> {
  owner?: Group['id']
}

export interface EventsRequestParams extends PaginatedRequestParams {
  tags?: InputsRequestParams['tags'] | OutputsRequestParams['tags']
}

export interface ServicesRequestParams extends PaginatedRequestParams<ListServiceSortableField> {
  includeHidden?: boolean
}

export interface AlarmsRequestParams extends PaginatedRequestParams<ListAlarmSortableField> {
  applianceId?: string
}

export interface AlarmLogRequestParams extends PaginatedRequestParams {
  fromDate?: string
  toDate?: string
  severity?: AlarmSeverityLevels
  searchName?: string
}

export interface PortsRequestParams extends PaginatedRequestParams<ListPortSortableField> {
  owner?: Group['id']
  portType?: PortType
  applianceType?: ApplianceType
  appliance?: Appliance['id']
}

export interface AppliancesRequestParams extends PaginatedRequestParams<ListApplianceSortableField> {
  isSystemProvider?: boolean
  owner?: Group['id']
  types?: string

  groupName?: Group['name']
  regions?: Region['name']
  tags?: string
}

export interface IpMappingsRequestParams extends PaginatedRequestParams<ListIpMappingSortableField> {}

export interface AuditLogRequestParams extends PaginatedRequestParams {
  fromDate?: string
  toDate?: string
  operation?: AuditOperation
  entity?: string
  username?: string
  entityName?: string
  result?: AuditLogOperationResult
}

export interface AdAuditLogRequestParams extends PaginatedRequestParams {
  filter?: string
  fromDate?: string
  toDate?: string
  entityType?: string
  method?: string
  result?: string
  message?: string
  status?: string
  eventId?: string
  outputId?: string
  streamId?: string
  channelId?: string
  component?: string
  pod?: string
  entity?: string
  entityId?: string
  entityName?: string
  source?: string
  target?: string
  traceId?: string
}

export interface KubernetesNodesRequestParams extends PaginatedRequestParams<ListKubernetesNodeSortableField> {
  name?: KubernetesNode['name']
}

export interface NetworksRequestParams extends PaginatedRequestParams<NetworkSortableField> {
  ids?: Array<Network['id']>
  port?: string
}

export type EnrichedAlarm = AlarmWithImpact & {
  _input?: Input
  _output?: Output
}

/** Returns Query<TFilter, SortOrder<TSortOrder>> (i.e. single SortOrder) for backend APIs that only accept queries with a single sort param **/
export function singleSortQueryFromPaginatedRequestParams<TFilter, TSortOrder extends string>({
  filter,
  paginatedRequestParams,
}: {
  filter: TFilter
  paginatedRequestParams: PaginatedRequestParams<TSortOrder>
}): Query<TFilter, SortOrder<TSortOrder>> {
  const { pageNumber, rowsPerPage, asc, desc } = paginatedRequestParams
  return {
    filter,
    skip: +pageNumber * +rowsPerPage,
    limit: +rowsPerPage,
    order: makeSortOrder({ asc, desc }),
  }
}

/** For backend APIs that receive queries with a single sort order **/
function makeSortOrder<SortableEnumType extends string>({
  asc,
  desc,
}: {
  asc: SortableEnumType | undefined
  desc: SortableEnumType | undefined
}): SortOrder<SortableEnumType> | undefined {
  if (asc !== undefined) {
    return { descending: false, field: asc }
  } else if (desc !== undefined) {
    return { descending: true, field: desc }
  }
  return undefined
}

export type ExistingUserForUpdate = User & { password?: NewUser['password'] }

export enum SrtBondingMode {
  none = 0,
  // bondingMode == 1 just signifies that bonding is enabled for the listener case
  // (since listeners can only indicate whether bonding is enabled or not)
  activeActive = 1,
  activeBackup = 2,
}

export const srtCallerBondingOptions: { name: string; value: number }[] = [
  {
    name: 'None',
    value: SrtBondingMode.none,
  },
  {
    name: 'Active-Active',
    value: SrtBondingMode.activeActive,
  },
  {
    name: 'Active-Backup',
    value: SrtBondingMode.activeBackup,
  },
]

export const srtListenerBondingOptions: { name: string; value: number }[] = [
  {
    name: 'None',
    value: SrtBondingMode.none,
  },
  {
    name: 'Bonded',
    value: SrtBondingMode.activeActive,
  },
]

export const nullBondingOptions: { name: string; value: number }[] = [
  {
    name: 'None',
    value: SrtBondingMode.none,
  },
]

export function srtBondingOptions(srtMode: SrtMode): { name: string; value: number }[] {
  if (srtMode === SrtMode.caller) {
    return srtCallerBondingOptions
  } else if (srtMode === SrtMode.listener) {
    return srtListenerBondingOptions
  }
  return nullBondingOptions
}
