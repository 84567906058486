import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Api, AppDispatch } from '../../../store'
import {
  AllocateApplianceInRegionRequest,
  AllocateApplianceRequest,
  AllocateSpecificApplianceRequest,
  ApplianceType,
  CoreVideoApplianceAllocation,
  IpPortMode,
  PortBase,
  PortMode,
  Region,
  RegionalPort,
} from 'common/api/v1/types'
import { initialInputLogicalPort } from '../../inputs/Edit/InputForm'
import { initialOutputLogicalPort } from '../../outputs/Edit/OutputForm'
import { CommonFields as CommonInputFields } from '../../inputs/Edit/PortForm/IpPortForm'
import { CommonFields as CommonOutputFields } from '../../outputs/Edit/PortForm/IpPortForm'
import {
  ApplianceSectionForm,
  areMultipleLogicalPortsPerApplianceSupported,
  collectApplianceSectionEntries,
  collectPortsFromApplianceSections,
  Error,
  isRegionalPort,
  Loading,
  LogicalPortForm,
} from './Base'
import { useAppliancePhysicalPorts } from '../../../utils'
import { PortDirection } from 'common/types'
import { notUndefinedOrNull } from 'common/api/v1/helpers'
import { whyIsPhysicalPortUnavailable } from '../../../utils/physicalPortUtil'
import { enqueueErrorSnackbar } from '../../../redux/actions/notificationActions'
import { useDispatch } from 'react-redux'
import { AllowUncontrolled, FormProps } from '../Form/RHF'
import { useFieldArray } from 'react-hook-form'

interface Props {
  namePrefix: string
  isInputForm: boolean
  isEditingExistingEntity: boolean
  isCopyingExistingEntity: boolean
  inputId: string | undefined
  outputId: string | undefined
  adminStatus: 0 | 1
  initialApplianceOrRegionId: string | undefined
  selectedRegion: Pick<Region, 'id' | 'name'>
  enforcedPortMode: PortMode | undefined
  isModeSelectionDisabled: boolean
  logicalPorts: Array<RegionalPort & { _allocation?: CoreVideoApplianceAllocation }> // _allocation is a not-yet-consumed allocation
  onAddLogicalPortRequested: () => void
}

const Component = <T extends ApplianceSectionForm>(
  props: FormProps<T & AllowUncontrolled> & Props,
  ref: React.Ref<{
    onAddInterfaceButtonClicked: () => void
  }>,
) => {
  const {
    namePrefix,
    isInputForm,
    isEditingExistingEntity,
    isCopyingExistingEntity,
    inputId,
    outputId,
    adminStatus,
    initialApplianceOrRegionId,
    selectedRegion,
    enforcedPortMode,
    isModeSelectionDisabled,
    logicalPorts,
    getValues,
    onAddLogicalPortRequested,
  } = props
  const values = getValues()
  const [allocationState, setAllocationState] = useState({ isAllocating: false, error: false })
  const dispatch = useDispatch<AppDispatch>()
  const logicalPort1 = logicalPorts[0]
  const appliance = logicalPort1?._allocation?.appliance ?? logicalPort1?.region?.allocatedAppliance
  const {
    result: appliancePhysicalPorts,
    loading: isFetchingPhysicalPorts,
    error: listPhysicalPortsError,
  } = useAppliancePhysicalPorts(
    appliance,
    Api.appliancesApi,
    isInputForm ? PortDirection.input : PortDirection.output,
    isInputForm ? inputId : outputId,
  )

  useEffect(() => {
    // This effect handles the case when "appliancePhysicalPorts" may not yet have been fetched after allocating an appliance,
    // leading to the props.logicalPort.physicalPort being undefined or belonging to a different appliance (e.g. if switching regions).
    function patchLogicalPortsWithPhysicalPort() {
      if (!logicalPorts?.[0]) return
      if (!appliancePhysicalPorts?.[0]) return
      for (let i = 0; i < logicalPorts.length; i++) {
        const lp = logicalPorts[i]
        const hasInvalidPhysicalPort = !appliancePhysicalPorts.some((pp) => pp.id === lp.physicalPort)
        if (hasInvalidPhysicalPort) {
          const defaultPhysicalPort =
            appliancePhysicalPorts.find(
              (physicalPort) => whyIsPhysicalPortUnavailable({ physicalPort }) === undefined,
            ) ?? appliancePhysicalPorts[0]
          if (defaultPhysicalPort) {
            updateLogicalPortField(i, {
              ...lp,
              physicalPort: defaultPhysicalPort.id,
              _port: defaultPhysicalPort,
            })
          }
        }
      }
    }
    patchLogicalPortsWithPhysicalPort()
  }, [appliancePhysicalPorts, logicalPorts])

  // Appliance ids of:
  // 1) all regional interfaces for this input/output and
  // 2) any allocated but not-yet-consumed ports
  const occupiedAppliances = collectApplianceSectionEntries(values)
    .flatMap(({ region, ports }) => {
      if (region) {
        return ports.map((p: PortBase) => {
          if (isRegionalPort(p)) return p.region?.allocatedAppliance?.id
          else if ('_allocation' in p) return (p._allocation as CoreVideoApplianceAllocation).appliance.id
        })
      }
    })
    .filter(notUndefinedOrNull)

  const areMultipleLogicalPortsSupported = areMultipleLogicalPortsPerApplianceSupported({
    isInput: isInputForm,
    region: selectedRegion,
    appliance,
    allLogicalPorts: collectPortsFromApplianceSections(getValues()),
  })

  // Effect triggered when user selects a different region
  useEffect(() => {
    setAllocationState({ isAllocating: false, error: false })

    const onRegionChanged = async (signal: AbortController['signal']) => {
      const isInitialRegion = initialApplianceOrRegionId === selectedRegion.id
      if (isEditingExistingEntity && isInitialRegion && logicalPorts.length > 0) {
        /* noop */
      } else if (isCopyingExistingEntity && isInitialRegion && logicalPorts.length > 0) {
        for (let portIndex = 0; portIndex < logicalPorts.length; portIndex++)
          updateLogicalPortWithNewAllocatedAppliance(portIndex)
      } else {
        const INITIAL_PORT_INDEX = 0
        if (occupiedAppliances.length === 0) {
          const allocation = await allocateArbitraryAppliance({ regionId: selectedRegion.id }, { signal })
          addNewLogicalPortToForm(INITIAL_PORT_INDEX, allocation)
        } else {
          const allocation = await allocateDifferentAppliance(
            { regionId: selectedRegion.id, excludedApplianceIds: occupiedAppliances },
            { signal },
          )
          addNewLogicalPortToForm(INITIAL_PORT_INDEX, allocation)
        }
      }
    }
    const controller = new AbortController()
    onRegionChanged(controller.signal).catch((e) => void e) // TODO: Show error message?

    return () => {
      controller.abort()
    }
  }, [selectedRegion])

  const {
    fields: logicalPortFields,
    update: updateLogicalPortField,
    remove: removeLogicalPortField,
    ...fieldArrayProps
  } = useFieldArray({
    name: `${namePrefix}.ports`,
  })

  useImperativeHandle(ref, () => ({
    onAddInterfaceButtonClicked: allocateApplianceAndAddAdditionalLogicalPortToForm,
  }))

  const allocateApplianceAndAddAdditionalLogicalPortToForm = async () => {
    const applianceId = logicalPort1?._allocation?.appliance.id ?? logicalPort1?.region?.allocatedAppliance?.id
    const allocationId = logicalPort1?._allocation?.id
    const excludedAllocationIds = allocationId ? [allocationId] : []
    const allocation = await (applianceId
      ? allocateSpecificAppliance({ applianceId, excludedAllocationIds })
      : allocateDifferentAppliance({ regionId: selectedRegion.id, excludedApplianceIds: occupiedAppliances }))
    const nextIndex = logicalPorts.length
    addNewLogicalPortToForm(nextIndex, allocation)
  }

  const updateLogicalPortWithNewAllocatedAppliance = (portIndex: number) => {
    const portToUpdate = logicalPorts[portIndex]
    const allocatedApplianceId = portToUpdate._allocation?.appliance.id ?? portToUpdate.region?.allocatedAppliance?.id
    if (!allocatedApplianceId) return

    const excludedAllocationIds = logicalPorts
      .filter((_, i) => i !== portIndex)
      .map((p) => p.applianceAllocationId)
      .filter(nonEmptyString)
    allocateSpecificAppliance({ applianceId: allocatedApplianceId, excludedAllocationIds }).then((allocation) => {
      if (!allocation) return
      updateLogicalPortField(portIndex, {
        ...portToUpdate,
        [isInputForm ? CommonInputFields.applianceAllocationId : CommonOutputFields.applianceAllocationId]:
          allocation.id,
        _allocation: allocation,
      })
    })
  }

  const addNewLogicalPortToForm = (portIndex: number, allocation: CoreVideoApplianceAllocation | undefined) => {
    if (!allocation) return

    const logicalPortInit = {
      applianceAllocationId: allocation.id,
      physicalPortId: undefined, // We have not yet fetched the physicalPorts for the allocated appliance,
      enforcedMode: areMultipleLogicalPortsSupported ? (logicalPort1?.mode as IpPortMode) : undefined,
      applianceType: ApplianceType.core,
    }
    const logicalPort = {
      ...(isInputForm ? initialInputLogicalPort(logicalPortInit) : initialOutputLogicalPort(logicalPortInit)),
      _allocation: allocation,
    }
    updateLogicalPortField(portIndex, logicalPort)
  }

  const allocateArbitraryAppliance = (
    params: Pick<AllocateApplianceInRegionRequest, 'regionId'>,
    options: Parameters<typeof doAllocateAppliance>[1],
  ) => doAllocateAppliance({ ...params, inputId: isInputForm ? undefined : inputId }, options)

  const allocateSpecificAppliance = (
    params: Pick<AllocateSpecificApplianceRequest, 'applianceId' | 'excludedAllocationIds'>,
    options?: Parameters<typeof doAllocateAppliance>[1],
  ) => doAllocateAppliance(params, options)

  const allocateDifferentAppliance = (
    params: Pick<AllocateApplianceInRegionRequest, 'regionId' | 'excludedApplianceIds'>,
    options?: Parameters<typeof doAllocateAppliance>[1],
  ) => doAllocateAppliance({ ...params, inputId: isInputForm ? undefined : inputId }, options)

  const doAllocateAppliance = async (
    params: AllocateApplianceRequest,
    options?: Parameters<typeof Api.portsApi.allocateCoreVideoAppliance>[1],
  ): Promise<CoreVideoApplianceAllocation | undefined> => {
    setAllocationState({ isAllocating: true, error: false })
    try {
      const allocation = await Api.portsApi.allocateCoreVideoAppliance(
        {
          ...params,
          purpose: isInputForm ? 'input' : 'output',
        },
        options,
      )
      setAllocationState({ isAllocating: false, error: false })
      return allocation
    } catch (e) {
      if (Api.isAborted(e)) return
      setAllocationState({ isAllocating: false, error: true })
      dispatch(enqueueErrorSnackbar({ error: e, operation: 'Allocate appliance' }))
      throw e
    }
  }

  return (
    <>
      {allocationState.isAllocating && <Loading message={`Allocating appliance in region ${selectedRegion.name}...`} />}
      {!allocationState.isAllocating && appliance && isFetchingPhysicalPorts && (
        <Loading message={`Fetching appliance interfaces`} />
      )}
      {allocationState.error && <Error message={`Failed allocating appliance in region ${selectedRegion.name}...`} />}
      {listPhysicalPortsError && <Error message={`Failed fetching appliance interfaces`} />}
      {appliancePhysicalPorts && appliancePhysicalPorts.length > 0 && (
        <LogicalPortForm
          isRegional={true}
          namePrefix={namePrefix}
          logicalPorts={logicalPorts}
          fieldArray={{
            fields: logicalPortFields,
            update: updateLogicalPortField,
            remove: removeLogicalPortField,
            ...fieldArrayProps,
          }}
          isInputForm={isInputForm}
          adminStatus={adminStatus}
          appliancePhysicalPorts={appliancePhysicalPorts}
          isModeSelectionDisabled={isModeSelectionDisabled}
          enforcedPortMode={enforcedPortMode}
          onAddLogicalPortRequested={onAddLogicalPortRequested}
        />
      )}
    </>
  )
}

function nonEmptyString(s?: string): s is string {
  return typeof s === 'string' && !!s
}

export const ReferencedComponent = forwardRef(Component)

export const RegionalInterfaceSection = <T extends ApplianceSectionForm>({
  myRef,
  ...rest
}: FormProps<T> & Props & { myRef: React.Ref<{ onAddInterfaceButtonClicked: () => void }> }) => (
  <ReferencedComponent {...(rest as any)} ref={myRef} />
)
