import Typography from '@mui/material/Typography'
import { parseK8sMemoryString } from 'common/utils'

export type AddStoragePoolDialogProps = {
  storagePool: {
    name: string
    servers: number
    size: string
  }
}

export const AddStoragePoolDialog = ({ storagePool }: AddStoragePoolDialogProps) => {
  const volumesPerNode = 2
  const totalBytes = storagePool.servers * volumesPerNode * parseK8sMemoryString(storagePool.size)
  const totalGi = totalBytes / 1024 ** 3

  return (
    <>
      <Typography>Adding a new storage pool with the following properties:</Typography>
      <Typography component={'div'}>
        <ul>
          <li>
            Name: <strong>{storagePool.name}</strong>
          </li>
          <li>
            Nodes: <strong>{storagePool.servers}</strong>
          </li>
          <li>
            Node volume size: <strong>{storagePool.size}</strong>
          </li>
          <li>
            Volumes per node: <strong>{volumesPerNode}</strong>
          </li>
        </ul>
      </Typography>
      <Typography>
        This will request <strong>{totalGi.toFixed(2)} Gi</strong> from your storage provider.
      </Typography>
    </>
  )
}
