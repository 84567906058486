import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import { NMSidebar } from '../partials/NMSidebar'
import { ROUTES } from '../../utils/routes'
import { readAlarmsInBackground } from '../../redux/actions/alarmsActions'
import { NotFound } from '../common/NotFound'
import { Dashboard } from '../dashboards/serviceProvider'
import { Inputs } from '../inputs'
import { Groups } from '../groups'
import { Users } from '../users'
import { Interfaces } from '../interfaces'
import { Appliances } from '../appliances'
import { Outputs } from '../outputs'
import { Alarms } from '../alarms'
import { BillingReport } from '../billing'
import { Graphs } from '../graph'
import ServiceOverview from '../serviceOverview/Overview'
import { Audit } from '../audit'
import { Regions } from '../regions'
import { Settings } from '../settings'
import { Status } from '../status'
import { AddressMappings } from '../addressMappings'
import { UsageReport } from '../usage'
import ErrorBoundary from '../common/ErrorBoundary'
import { Nodes } from '../kubernetesNodes'
import { Networks } from '../networks'
import { Services } from '../services'
import { AppDispatch } from '../../store'
import { AlarmLogs } from '../alarmLogs'
import { Profile } from '../profile/index'
import { TunnelDetails } from '../appliances/Edit/ApplianceForm/Tunnels'
import { PageTitle } from '../common/PageTitle'
import { Production } from '../production'
import { AdInsertion } from '../adInsertion'

export const NetworkManagerContainer = () => {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(readAlarmsInBackground({}))
  }, [dispatch])

  return (
    <>
      <PageTitle title="Network Manager" />
      <Box sx={{ height: '100vh', width: '100vw', display: 'flex' }}>
        <NMSidebar />
        <ErrorBoundary>
          <Container component="main" maxWidth={false} sx={{ overflowX: 'auto' }}>
            <Routes>
              <Route index element={<Navigate to={ROUTES.overview} replace />} />
              <Route path={ROUTES.overview} element={<Dashboard />} />
              <Route path={`${ROUTES.graph}/*`} element={<Graphs />} />
              <Route path={`${ROUTES.inputs}/*`} element={<Inputs />} />
              <Route path={`${ROUTES.outputs}/*`} element={<Outputs />} />
              <Route path={`${ROUTES.alarms}/*`} element={<Alarms />} />
              <Route path={ROUTES.service} element={<ServiceOverview />} />
              <Route path={ROUTES.setting} element={<Navigate to="settings/appliances/" />} />
              <Route path={`${ROUTES.appliances}/*`} element={<Appliances />} />
              <Route path={`${ROUTES.users}/*`} element={<Users />} />
              <Route path={`${ROUTES.interfaces}/*`} element={<Interfaces />} />
              <Route path={`${ROUTES.networks}/*`} element={<Networks />} />
              <Route path={`${ROUTES.groups}/*`} element={<Groups />} />
              <Route path={`${ROUTES.billing}/*`} element={<BillingReport />} />
              <Route path={`${ROUTES.usage}/*`} element={<UsageReport />} />
              <Route path={`${ROUTES.audit}/*`} element={<Audit />} />
              <Route path={`${ROUTES.alarmLogs}/*`} element={<AlarmLogs />} />
              <Route path={`${ROUTES.addressMappings}/*`} element={<AddressMappings />} />
              <Route path={`${ROUTES.regions}/*`} element={<Regions />} />
              <Route path={`${ROUTES.production}/*`} element={<Production />} />
              <Route path={`${ROUTES.adInsertion}/*`} element={<AdInsertion />} />
              <Route path={ROUTES.globalSettings} element={<Settings />} />
              <Route path={`${ROUTES.status}/*`} element={<Status />} />
              <Route path={`${ROUTES.nodes}/*`} element={<Nodes />} />
              <Route path={`${ROUTES.services}/*`} element={<Services />} />
              <Route path={`${ROUTES.tunnels}/:tunnelId`} element={<TunnelDetails />} />
              <Route path={ROUTES.profile} element={<Profile />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
        </ErrorBoundary>
      </Box>
    </>
  )
}
