import { useDispatch } from 'react-redux'
import type { GlobalSettings } from 'common/api/v1/types'
import { ButtonsPane, Form, GridItem, Paper, RHF, SafeRouting, TextInput } from '../common/Form'
import { AppDispatch } from '../../store'
import { saveSettings } from '../../redux/actions/settingsActions'
import { useSettingsSelector } from '../../utils'
import { FormProps } from '../common/Form/RHF'
import Pendable from '../common/Pendable'

const AdInsertionSettings = ({ formState }: FormProps<GlobalSettings>) => {
  const { saving } = useSettingsSelector()

  return (
    <>
      <SafeRouting formState={formState} />
      <Form id="ad-insertion-form" noValidate>
        <Paper
          title="Settings"
          sx={{
            margin: 0,
            overflow: 'hidden',
            height: '100%',
          }}
        >
          <GridItem lg={12} xl={12}>
            <TextInput
              name="adBucketSizeThreshold"
              label="Ad bucket size threshold"
              tooltip="The maximum size of the ad bucket. If the size of the ad bucket exceeds this value, the retention service will start deleting least recently used ad files. Supports suffixes like G, M, Gi and Mi."
              required
              validators={{
                k8sMemoryString: {},
              }}
            />
          </GridItem>
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Paper>
      </Form>
    </>
  )
}

export const AdInsertionSettingsForm = () => {
  const { settings } = useSettingsSelector()
  const dispatch = useDispatch<AppDispatch>()

  const handleSubmit = async (data: GlobalSettings) => {
    dispatch(saveSettings(data))
  }

  return (
    <Pendable pending={!settings}>
      <RHF
        onSubmit={handleSubmit}
        defaultValues={
          {
            ...settings,
          } as GlobalSettings
        }
        component={AdInsertionSettings}
      />
    </Pendable>
  )
}
