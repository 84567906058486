import { PRODUCTS } from './constants'
import {
    AlarmReport,
    ApplianceVersion,
    AudioCodec,
    BroadcastStandard,
    CoaxPortMode,
    ComprimatoPortMode,
    Entity,
    GeneralEncoderSettings,
    GeneratorBitrate,
    GeneratorFrameRate,
    GeneratorResolutionPreset,
    GeneratorScanMode,
    GeneratorTimestampResolution,
    IngestTransform,
    IpcPortMode,
    IpPortMode,
    MatroxDecoderConfig,
    MatroxEncoderConfig,
    MatroxPortMode,
    Output3gLevel,
    OutputEncoderSettings,
    OutputPortFec,
    RistProfile,
    SrtKeylen,
    SrtMode,
    SrtRateLimiting,
    Tr101290,
    TranscodeAcceleratorType,
    VaObject,
    VideoCodec,
    VideonPortMode,
    ZixiDecryptType,
    ZixiFeederSinkStats,
    ZixiLinkMode,
    ZixiLinkSet,
    ZixiMode,
} from './api/v1/types'
import { ApplianceConfiguration, BackendIoType } from './messages'

import { TsToolTransportStream } from './tr101Types'
import { SrtStats, SrtStreamTags } from './srt'
import { RtmpInputStats, RtmpOutputStats, RtmpStreamTags } from './rtmp'
import { RequestResult } from './network/request-options'
import { TranscodeStreamStats, TranscodeStreamTags } from './transcode'
import { OmitFromUnion } from 'common/util'
import { RistStatisticsResponseDiff } from './rist'

export interface Message<T, Type extends string> {
    type: Type
    data: T
}

export type TranscodeAcceleratorInfoMessage = Message<{ info: TranscodeAcceleratorInfo }, 'transcodeAcceleratorInfo'>

export type TranscodeAcceleratorStatsMessage = Message<
    { stats: TranscodeAcceleratorStats },
    'transcodeAcceleratorStats'
>

export type TranscodeStreamStatsMessage = Message<
    { tags: TranscodeStreamTags; stats: TranscodeStreamStats },
    'transcodeStreamStats'
>
export type AdStageStatsMessage = Message<{ tags: AdStageTags; stats: AdStageStats }, 'adStageStats'>

export type RtmpInputStatsMessage = Message<{ tags: RtmpStreamTags; stats: RtmpInputStats }, 'rtmpInputStats'>
export type RtmpOutputStatsMessage = Message<{ tags: RtmpStreamTags; stats: RtmpOutputStats }, 'rtmpOutputStats'>
export type RtmpStatsMessage = RtmpInputStatsMessage | RtmpOutputStatsMessage

export type RistStatsMessage = Message<RistStatisticsResponseDiff, 'ristStats'>
export type SrtStatsMessage = Message<{ tags: SrtStreamTags; stats: SrtStats }, 'srtStats'>
export type MptsDemuxStatsMessage = Message<MptsDemuxStatusDiff, 'mptsStats'>
export type Tr101290StatisticsMessage = Message<Tr101290Statistics, 'tr101290Stats'>
export type IpConfigMessage = Message<Array<VaIpConfig | IfConfig>, 'ipConfig'>
export type BasicInfoMessage = Message<Partial<VAProductInfo>, 'basicInfo'>

export type SrtStatsBatch = SrtStatsMessage[]
export type RtmpStatsBatch = RtmpStatsMessage[]
export type AdStageStatsBatch = AdStageStatsMessage[]

export interface VersionMessage {
    version: ApplianceVersion
}

export interface ZixiFeederChannelStats {
    channelId: number
    // zixiFeederInput: ZixiFeederInputStats
    zixiFeederOutput: ZixiFeederOutputStats[]
}

export interface ZixiReceiverChannelStats {
    channelId: number
    zixiReceiverInput: ZixiReceiverInputStats[]
}

export interface ZixiFeederOutputStats {
    streamId: number
    outputId: string
    bitrate: number
    connectionStatus: 'Connected' | 'Connecting'
    error: string
    sinkStats: ZixiFeederSinkStats
}

export interface ZixiReceiverInputStats {
    streamId: number
    bitrate: number
    rtt?: number
    connectionStatus: 'Online' | 'Reconnecting'
    error: string
}

export interface ZixiFeederStats {
    channels: ZixiFeederChannelStats[]
}
export interface ZixiReceiverStats {
    channels: ZixiReceiverChannelStats[]
}

export type ZixiBroadcasterStatsMessage = Message<{ stats: ZixiBroadcasterStats }, 'zixiBroadcasterStats'>
export interface ZixiBroadcasterStats {
    streams: {
        channelId: number
        streamId: number
        bitrate: number
        rtt: number
        connectionStatus: 'Connected' | 'Connecting' | 'Offline' | string
        error: 'Timeout' | 'Error' | '' | string
    }[]
    outputs: {
        outputId: string
        channelId: number
        streamId: number
        bitrate: number
        error: 'Timeout' | '' | string
        connectionStatus: 'Connected' | 'Connecting' | 'Offline' | string
        sinkStats: {
            net: {
                bitrate: number
                droppedPackets: number
                duplicatePackets: number
                sendPacketRate: number
                jitter: number
                packets: number
                packetRate: number
                rtt: number
            }
            arq: {
                lostPackets: number
                duplicatePackets: number
                recoveredPackets: number
                overflowedPackets: number
            }
            fec: {
                sentPackets: number
                recoveredPackets: number
                receivedPackets: number
            }
        }
    }[]
}

export type ApplianceConfigurationMessage = Message<ApplianceConfiguration, 'nodeConfiguration'>

export interface ApplianceRegistrationResponse {
    id: string
    zixiFeederKey: string
    zixiReceiverKey: string
}

// Many of these features require the kernel to support Linux capabilities.
// To check for support, you can use the docker info command.
// If a capability is disabled in your kernel, you may see a warning at the end of the output like the following: "WARNING: No swap limit support"
export interface DockerMemoryConfig {
    // Hard limit. The maximum amount of memory the container can use. The minimum allowed value is 6 megabytes.
    memory?: string
    // The amount of memory this container is allowed to swap to disk.
    memorySwap?: string
    // The host kernel can swap out a percentage of anonymous pages used by a container.
    memorySwappinessPercentage?: string
    // A soft limit (i.e. no guarantees) smaller than --memory which is activated when Docker detects contention or low memory on the host machine.
    memoryReservation?: string
    // The maximum amount of kernel memory the container can use. The minimum allowed value is 4m.
    kernelMemory?: string
    // Set this flag to prevent the kernel from killing processes in the container. Only disable the OOM killer on containers where you have also set the -m/--memory option.
    oomKillDisable?: boolean
}
export interface DockerConfig {
    registryUrl: string
    dataImage: string
    dataVersion: string
    memoryConfig?: DockerMemoryConfig
}

export type ConfigMessage = ApplianceConfigurationMessage

export type ApplianceCommandResult = ApplianceCommandSuccessResult | ApplianceCommandErrorResult
export interface ApplianceCommandSuccessResult {
    success: true
    result: any
    command: ApplianceCommand
}
export interface ApplianceCommandErrorResult {
    success: false
    errorMessage: string
    command: ApplianceCommand
}
export interface ApplianceCommandBase<T extends string> {
    id: string
    name: T
}
export interface UpgradeCommand extends ApplianceCommandBase<'upgrade'> {
    name: 'upgrade'
    updateUrl: string
}

export interface OpenRpcChannelCommand extends ApplianceCommandBase<'openRpcChannel'> {
    name: 'openRpcChannel'
}

export interface RestartEdgeControlCommand extends ApplianceCommandBase<'restartEdgeControl'> {
    name: 'restartEdgeControl'
}

export interface RestartEdgeDataCommand extends ApplianceCommandBase<'restartEdgeData'> {
    name: 'restartEdgeData'
}

export interface ListPcapsCommand extends ApplianceCommandBase<'listPcaps'> {
    name: 'listPcaps'
}

export interface TcpdumpCommand extends ApplianceCommandBase<'tcpdump'>, TcpdumpArguments {
    name: 'tcpdump'
}
export interface TcpdumpArguments {
    durationSeconds: number
    captureName: string
    friendlyName: string
    protocol: 'udp' | 'tcp'
    srcHost?: string
    srcPort?: number
    dstHost?: string
    dstPort?: number
    iface?: string
}

export type ApplianceCommand =
    | UpgradeCommand
    | OpenRpcChannelCommand
    | RestartEdgeControlCommand
    | RestartEdgeDataCommand
    | ListPcapsCommand
    | TcpdumpCommand

export type ApplianceCommandType = ApplianceCommand['name']
export type ApplianceCommandInit = OmitFromUnion<ApplianceCommand, 'id'>

export interface VAProductInfo {
    serial: string
    prodName: string
    // prodVersion used contains a full ApplianceVersion object in
    // basicInfo messages from appliances running 3.15.0 or earlier.
    // From R3.16.0 it contains a string which is the VA version, like V17.0.0.1
    // (The Edge version information is moved into its own version message)
    prodVersion: string | ApplianceVersion
    prodReadableName: string
    name: string
    location: string
    contact: string
}

export interface PortDbConfigurationBase {
    copies?: number
    priority?: number
    /**
     * @OAS_EXCLUDED
     */
    index?: number
}

export interface UdpPortDbConfigurationBase extends PortDbConfigurationBase {
    mode: IpPortMode
    localIp: string
    localPort: number
    publicIp?: string
    multicastAddress?: string
    multicastSource?: string
}

export interface RistSimpleProfileOutputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.rist
    profile: RistProfile.simple
    remoteIp: string
    remotePort: number
    region?: { id: string; name: string }
}

export interface RistSimpleProfileInputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.rist
    profile: RistProfile.simple
    whitelistCidrBlock?: string | string[]
    region?: { id: string; name: string }
}

export interface GeneratorInputPortDbConfiguration extends Omit<UdpPortDbConfigurationBase, 'localPort'> {
    mode: IpPortMode.generator
    audioOnly?: boolean
    frameRate?: GeneratorFrameRate
    resolution?: GeneratorResolutionPreset
    scanMode?: GeneratorScanMode
    timestampResolution?: GeneratorTimestampResolution
    localPort?: number
    bitrate?: GeneratorBitrate
    programs: Array<{
        title: string
        audio: { pid: number }
        video: { pid: number }
    }>
}

export interface UdpInputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.udp
    whitelistCidrBlock?: string | string[]
    region?: { id: string; name: string }
    ingestTransform?: IngestTransform
    failoverPriority?: number
}

export interface RtmpInputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.rtmp
    whitelistCidrBlock?: string | string[]
    region?: { id: string; name: string }
}

export interface UnixInputPortDbConfiguration extends PortDbConfigurationBase {
    mode: IpcPortMode.unix
    localPath: string
    ingestTransform?: IngestTransform
}

export interface UnixOutputPortDbConfiguration extends PortDbConfigurationBase {
    mode: IpcPortMode.unix
    remotePath: string
}

export interface UdpOutputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.udp
    ttl?: number
    remoteIp: string
    remotePort: number
    region?: { id: string; name: string }
    services?: number[]
    mptsDestination?: string
}

export interface RtmpOutputPortDbConfiguration extends PortDbConfigurationBase {
    mode: IpPortMode.rtmp
    rtmpDestinationAddress: string
    region?: { id: string; name: string }
}

export interface RtpInputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.rtp
    rtp?: boolean
    rtcp?: boolean
    fec?: boolean
    whitelistCidrBlock?: string | string[]

    // Used to distinguish priority between non-binary-equal RTP streams.
    // If omitted the streams must be binary-equal and will be used for Dash7 redundancy.
    failoverPriority?: number
}

export interface RtpOutputPortDbConfiguration extends Omit<UdpOutputPortDbConfiguration, 'mode'> {
    mode: IpPortMode.rtp
    fec?: OutputPortFec
    fecRows?: number
    fecColumns?: number
    region?: { id: string; name: string }
}

export interface SrtPortDbConfigurationBase extends PortDbConfigurationBase {
    mode: IpPortMode.srt
    srtMode: SrtMode
    // RTP over SRT
    rtp?: boolean
    latency: number
    ipttl?: number
    mss?: number
    passphrase?: string
    region?: { id: string; name: string }
}

export interface SrtInputPortDbConfigurationBase extends SrtPortDbConfigurationBase {}

export interface SrtOutputPortDbConfigurationBase extends SrtPortDbConfigurationBase {
    pbkeylen: SrtKeylen
    rateLimiting: SrtRateLimiting
    inputBw?: number
    maxBw?: number
    oheadBw?: number
}

export interface SrtListenerOutputPortDbConfiguration extends SrtOutputPortDbConfigurationBase {
    srtMode: SrtMode.listener
    localIp: string
    localPort: number

    // The secondary config is used for srt bonding.
    // It is not persisted in the primary logical port db config but populated from the secondary logical port.
    // It is only used in the communication between backend and the appliance.
    secondaryConfig?: {
        localIp: string
        localPort: number
        streamId: number
    }
    bondingMode?: SrtBondingMode

    failoverPriority?: number
    whitelistCidrBlock?: string | string[]
}
export interface SrtCallerOutputPortDbConfiguration extends SrtOutputPortDbConfigurationBase {
    srtMode: SrtMode.caller
    localPort?: number
    remoteIp: string
    remotePort: number

    // The secondary config is used for srt bonding.
    // It is not persisted in the primary logical port db config but populated from the secondary logical port.
    // It is only used in the communication between backend and the appliance.
    secondaryConfig?: {
        remoteIp: string
        remotePort: number
        streamId: number
    }

    bondingMode?: SrtBondingMode
    failoverPriority?: number
    streamId?: string
}
export interface SrtRendezvousOutputPortDbConfiguration extends SrtOutputPortDbConfigurationBase {
    srtMode: SrtMode.rendezvous
    localIp: string
    remoteIp: string
    remotePort: number
    whitelistCidrBlock?: string | string[]
}

export type SrtOutputPortDbConfiguration =
    | SrtListenerOutputPortDbConfiguration
    | SrtCallerOutputPortDbConfiguration
    | SrtRendezvousOutputPortDbConfiguration

export const isSrtListenerOutputPortDbConfiguration = (
    cfg: SrtOutputPortDbConfiguration
): cfg is SrtListenerOutputPortDbConfiguration => cfg.srtMode === SrtMode.listener
export const isSrtCallerOutputPortDbConfiguration = (
    cfg: SrtOutputPortDbConfiguration
): cfg is SrtCallerOutputPortDbConfiguration => cfg.srtMode === SrtMode.caller
export const isSrtRendezvousOutputPortDbConfiguration = (
    cfg: SrtOutputPortDbConfiguration
): cfg is SrtRendezvousOutputPortDbConfiguration => cfg.srtMode === SrtMode.rendezvous

// VaSrtInputPortDbConfiguration contains fields only used in VAs...
export interface VaSrtInputPortDbConfiguration extends SrtInputPortDbConfigurationBase {
    reducedBitrateDetection: boolean
    reducedBitrateThreshold?: number
    unrecoveredPacketsDetection: boolean
    unrecoveredPacketsThreshold?: number
}

export enum SrtBondingMode {
    broadcast = 'broadcast',
    mainBackup = 'main/backup',
}

export interface SrtListenerInputPortDbConfiguration extends VaSrtInputPortDbConfiguration {
    srtMode: SrtMode.listener
    localIp: string
    localPort: number
    whitelistCidrBlock?: string | string[]

    failoverPriority?: number

    // The secondary config is used for srt bonding.
    // It is not persisted in the primary logical port db config but populated from the secondary logical port.
    // It is only used in the communication between backend and the appliance.
    secondaryConfig?: {
        localIp: string
        localPort: number
        streamId: number
    }

    bondingMode?: SrtBondingMode
}
export interface SrtCallerInputPortDbConfiguration extends VaSrtInputPortDbConfiguration {
    srtMode: SrtMode.caller
    // Used to enforce the local outgoing port
    localPort?: number
    remoteIp: string
    remotePort: number
    // Currently only used for EdgeConnect
    streamId?: string

    failoverPriority?: number

    // The secondary config is used for srt bonding.
    // It is not persisted in the primary logical port db config but populated from the secondary logical port.
    // It is only used in the communication between backend and the appliance.
    secondaryConfig?: {
        remoteIp: string
        remotePort: number
        streamId: number
    }
    bondingMode?: SrtBondingMode
}
export interface SrtRendezvousInputPortDbConfiguration extends VaSrtInputPortDbConfiguration {
    srtMode: SrtMode.rendezvous
    localIp: string
    remoteIp: string
    remotePort: number
    whitelistCidrBlock?: string | string[]
}

export interface MatroxSdiInputPortDbConfiguration extends PortDbConfigurationBase {
    mode: MatroxPortMode.matroxSdi
    matroxInputSettings: MatroxEncoderConfig
}

export type MatroxInputPortDbConfiguration = MatroxSdiInputPortDbConfiguration

export interface MatroxSdiOutputPortDbConfiguration extends PortDbConfigurationBase {
    mode: MatroxPortMode.matroxSdi
    decoderSettings: MatroxDecoderConfig
}

export type MatroxOutputPortDbConfiguration = MatroxSdiOutputPortDbConfiguration

export interface VideonInputPortDbConfigurationBase<T extends VideonPortMode> extends PortDbConfigurationBase {
    mode: T
    encoderSettings: GeneralEncoderSettings
}

export interface VideonSdiInputPortDbConfiguration
    extends VideonInputPortDbConfigurationBase<VideonPortMode.videonSdi> {}
export interface VideonHdmiInputPortDbConfiguration
    extends VideonInputPortDbConfigurationBase<VideonPortMode.videonHdmi> {}
export interface VideonAutoInputPortDbConfiguration
    extends VideonInputPortDbConfigurationBase<VideonPortMode.videonAuto> {}

export type VideonInputPortDbConfiguration =
    | VideonSdiInputPortDbConfiguration
    | VideonHdmiInputPortDbConfiguration
    | VideonAutoInputPortDbConfiguration

export interface ComprimatoInputPortDbConfigurationBase<T extends ComprimatoPortMode> extends PortDbConfigurationBase {
    mode: T
    encoderSettings: GeneralEncoderSettings
}

export interface ComprimatoSdiInputPortDbConfiguration
    extends ComprimatoInputPortDbConfigurationBase<ComprimatoPortMode.comprimatoSdi> {}
export interface ComprimatoNdiInputPortDbConfiguration
    extends ComprimatoInputPortDbConfigurationBase<ComprimatoPortMode.comprimatoNdi> {
    name: string
}
export type ComprimatoInputPortDbConfiguration =
    | ComprimatoSdiInputPortDbConfiguration
    | ComprimatoNdiInputPortDbConfiguration

export interface ComprimatoOutputPortDbConfigurationBase<T extends ComprimatoPortMode> extends PortDbConfigurationBase {
    mode: T
    encoderSettings: OutputEncoderSettings
}

export interface ComprimatoNdiOutputPortDbConfiguration
    extends ComprimatoOutputPortDbConfigurationBase<ComprimatoPortMode.comprimatoNdi> {
    name: string
}

export interface ComprimatoSdiOutputPortDbConfiguration
    extends ComprimatoOutputPortDbConfigurationBase<ComprimatoPortMode.comprimatoSdi> {}

export type ComprimatoOutputPortDbConfiguration =
    | ComprimatoSdiOutputPortDbConfiguration
    | ComprimatoNdiOutputPortDbConfiguration

export type SrtInputPortDbConfiguration =
    | SrtListenerInputPortDbConfiguration
    | SrtCallerInputPortDbConfiguration
    | SrtRendezvousInputPortDbConfiguration

export const isSrtListenerInputPortDbConfiguration = (
    cfg: SrtInputPortDbConfiguration
): cfg is SrtListenerInputPortDbConfiguration => cfg.srtMode === SrtMode.listener
export const isSrtCallerInputPortDbConfiguration = (
    cfg: SrtInputPortDbConfiguration
): cfg is SrtCallerInputPortDbConfiguration => cfg.srtMode === SrtMode.caller
export const isSrtRendezvousInputPortDbConfiguration = (
    cfg: SrtInputPortDbConfiguration
): cfg is SrtRendezvousInputPortDbConfiguration => cfg.srtMode === SrtMode.rendezvous

export interface ZixiPortDbConfigurationBase extends PortDbConfigurationBase {
    mode: IpPortMode.zixi
    zixiMode: ZixiMode
    streamId: string
    password?: string
    // Only used by the VA
    unrecoveredPacketsDetection: boolean
    // Only used by the VA
    unrecoveredPacketsThreshold?: number
}

export interface ZixiInputPortDbConfigurationBase extends ZixiPortDbConfigurationBase {
    decryptKey?: string
    decryptType: ZixiDecryptType
    reducedBitrateDetection: boolean
    reducedBitrateThreshold?: number
}

interface ZixiFecSettingsDbConfiguration {
    fecLatency?: number
    optimizeFec?: boolean
    adaptiveFec?: boolean
    maxFecOverhead?: number
}
export interface ZixiPullInputPortDbConfiguration
    extends ZixiInputPortDbConfigurationBase,
        ZixiFecSettingsDbConfiguration {
    zixiMode: ZixiMode.pull
    retransmitBuf: number
    remotePrimaryIp: string
    remoteSecondaryIp?: string
    localIp?: string
    pullPort: number
    region?: { id: string; name: string }
}
export interface ZixiPushInputPortDbConfiguration extends ZixiInputPortDbConfigurationBase {
    zixiMode: ZixiMode.push
}

export interface ZixiPullOutputPortDbConfiguration extends ZixiPortDbConfigurationBase {
    zixiMode: ZixiMode.pull
}
export interface ZixiPushOutputPortDbConfiguration extends ZixiPortDbConfigurationBase, ZixiFecSettingsDbConfiguration {
    zixiMode: ZixiMode.push
    linkMode: ZixiLinkMode
    linkSet1: ZixiLinkSet
    linkSet2?: ZixiLinkSet
    retransmitBuf: number
    // maxBitrateMbps is only applicable for zixi-feeder-push outputs, which can currently only be set up on core appliances
    // The unit is Mibibits/second despite the name
    maxBitrateMbps?: number
    region?: { id: string; name: string }
}

export type ZixiInputPortDbConfiguration = ZixiPullInputPortDbConfiguration | ZixiPushInputPortDbConfiguration
export type ZixiOutputPortDbConfiguration = ZixiPullOutputPortDbConfiguration | ZixiPushOutputPortDbConfiguration

export interface SdiInputDbConfiguration extends SdiDbConfiguration {
    encoderSettings: GeneralEncoderSettings
}
export interface SdiOutputDbConfiguration extends SdiDbConfiguration {
    output3gLevel?: Output3gLevel
}
export interface SdiDbConfiguration extends PortDbConfigurationBase {
    mode: CoaxPortMode.sdi
    portIndex?: string
}
export type AsiInputDbConfiguration = AsiDbConfiguration
export type AsiOutputDbConfiguration = AsiDbConfiguration
export interface AsiDbConfiguration extends PortDbConfigurationBase {
    mode: CoaxPortMode.asi
}

export type CoaxOutputPortDbConfiguration = AsiOutputDbConfiguration | SdiOutputDbConfiguration

export type IpcOutputPortDbConfiguraiton = UnixOutputPortDbConfiguration

export type IpOutputPortDbConfiguration =
    | UdpOutputPortDbConfiguration
    | RtpOutputPortDbConfiguration
    | SrtOutputPortDbConfiguration
    | ZixiOutputPortDbConfiguration
    | RistSimpleProfileOutputPortDbConfiguration

export type OutputPortDbConfiguration =
    | CoaxOutputPortDbConfiguration
    | IpOutputPortDbConfiguration
    | RtmpOutputPortDbConfiguration
    | MatroxOutputPortDbConfiguration
    | ComprimatoOutputPortDbConfiguration
    | IpcOutputPortDbConfiguraiton

export type CoaxInputPortDbConfiguration = AsiInputDbConfiguration | SdiInputDbConfiguration

export type IpInputPortDbConfiguration =
    | UdpInputPortDbConfiguration
    | RtpInputPortDbConfiguration
    | RtmpInputPortDbConfiguration
    | SrtInputPortDbConfiguration
    | ZixiInputPortDbConfiguration
    | RistSimpleProfileInputPortDbConfiguration
    | GeneratorInputPortDbConfiguration

export type IpcInputPortDbConfiguration = UnixInputPortDbConfiguration

export type InputPortDbConfiguration =
    | CoaxInputPortDbConfiguration
    | IpInputPortDbConfiguration
    | IpcInputPortDbConfiguration
    | VideonInputPortDbConfiguration
    | MatroxInputPortDbConfiguration
    | ComprimatoInputPortDbConfiguration

type StringPropertiesAsNumbers<T extends object> = {
    [k in keyof T]: T[k] extends object ? StringPropertiesAsNumbers<T[k]> : T[k] extends string ? number : T[k]
}

export interface Tr101290Statistics extends StringPropertiesAsNumbers<Tr101290> {
    channelId: number
    type: string
}

export interface TsInfo {
    channelId: number
    type: BackendIoType
    broadcastStandard: BroadcastStandard
    stream: TsToolTransportStream
}

export interface VaEthMedia {
    active: string
    current: string
}

export interface VaDhcp {
    curNetmask: string
    curInet: string
}

interface IfConfigAddress {
    address: string
    netmask?: string
    publicAddress?: string
    interRegionPublicAddress?: string
    internalAddress?: string
}
export interface IfConfig {
    name: string
    mac: string
    addresses: IfConfigAddress[]
}

export enum PortDirection {
    input = 'input',
    output = 'output',
}
export interface MatroxCoaxPortConfiguration {
    isVirtualPort?: boolean
    direction: PortDirection
}

export interface CoaxPort<T = any> {
    modes: {
        type: CoaxPortMode
        name: string
        encode?: boolean
    }[]
    name: string
    portIndex: string
    configuration?: T
}

export interface VaIpConfig {
    adminStatus: string
    name: string
    mac: string
    operStatus: string
    media: VaEthMedia
    mtu: string
    dhcp: VaDhcp
    addresses: { address: string; netmask: string; publicAddress?: string }[]
    id: string
}

export interface ObjectStats {
    objects: VaObject[]
    alarms: AlarmReport[]
}

export type JsonRpcMessage = JsonRpcRequest | JsonRpcResponse | JsonRpcNotification
export interface JsonRpcRequest {
    id: number
    method: string
    params: any
}
export type WithJsonRpcId<T extends Omit<JsonRpcRequest, 'id'>> = T & Pick<JsonRpcRequest, 'id'>
export interface JsonRpcNotification {
    method: string
    params: any
}

export type JsonRpcResponse = JsonRpcSuccessResponse | JsonRpcErrorResponse
export interface JsonRpcErrorResponse {
    id: number
    error: {
        code: number
        message: string
        data?: any
    }
}

export enum JsonRpcErrorResponseCode {
    unknownMethod = 1,
    requestError = 2,
    applianceNotConnected = 20,
    invalidMethod = 10,
    noSuchBrowserSession = 30,
}

export interface JsonRpcSuccessResponse<T = any> {
    id: number
    result: RequestResult<T>
}

export interface TranscodeAcceleratorInfo {
    type: TranscodeAcceleratorType
}

export interface TranscodeAcceleratorStats {
    type: string
    utilization: number
}

export interface TranscodeNvidiaStats extends TranscodeAcceleratorStats {
    type: 'nvidia'
    sessionCount: number
    averageFps: number
    averageLatency: number
}

export interface TranscodeNetintStats extends TranscodeAcceleratorStats {
    type: 'netint'
    device: string
    encoder: number
    decoder: number
    scaler: number
}

export interface FfmpegStats {
    bitrateKbps: number
    speed?: number
    dropped?: number
    duplicate?: number
    crash?: number
}

export interface MptsDemuxStatusDiff {
    streams: StreamStatusDiff[]
}

export interface OutputStatusDiff {
    outputId: number
    counters: OutputCounters
}

interface OutputCounters {
    udpPackets: number
    tsPackets: number
    udpPacketsRate: number
    tsPacketsRate: number
}

export interface StreamStatusDiff {
    streamId: number
    counters: StreamCounters
    outputs: OutputStatusDiff[]
}

interface StreamCounters {
    udpPackets: number
    truncatedBytes: number
    tsPackets: number
    udpPacketsRate: number
    truncatedBytesRate: number
    tsPacketsRate: number
}

export interface SystemStats {
    cpu: CPUStats
    memory: MemoryStats
    swap: SwapStats
    softnet: SoftnetStat[]
    networkDevices: NetworkDeviceStats[]
}

interface PerCoreCPUStats {
    cpu: string

    // incremental values, stored as unit [seconds]
    time_user: number
    time_nice: number
    time_system: number
    time_idle: number
    time_iowait: number
    time_irq: number
    time_softirq: number
    time_steal: number // since Linux 2.6.11
    time_guest: number // since Linux 2.6.24
    time_guest_nice: number // since Linux 2.6.33
}
export interface CPUStats {
    cores: Array<PerCoreCPUStats & { cpuUtilisationPercentage?: number }>
}

export interface MemoryStats {
    total: number
    free: number
    available: number
    buffers: number
    cached: number
    shared: number
    sreclaimable: number // slab reclaimable, from /proc/meminfo
}

export interface SwapStats {
    total: number
    cached: number
    free: number
}

interface SoftnetStat {
    cpu: number
    processed: number
    dropped: number
    time_squeeze: number
}

// See the edge-control/metrics package for more information
interface NetworkDeviceStats {
    interface: string
    receive: NetworkDeviceStatsReceive
    transmit: NetworkDeviceStatsTransmit
}

// See the edge-control/metrics package (ProcNetDevReceive) for more information
interface NetworkDeviceStatsReceive {
    bytes: number
    packets: number
    errs: number
    drop: number
    fifo: number
    frame: number
    compressed: number
    multicast: number
}

// See the edge-control/metrics package (ProcNetDevTransmit) for more information
interface NetworkDeviceStatsTransmit {
    bytes: number
    packets: number
    errs: number
    drop: number
    fifo: number
    colls: number
    carrier: number
    compressed: number
}

export type Product = (typeof PRODUCTS)[keyof typeof PRODUCTS]
export type ConnectitProductId = typeof PRODUCTS.connectIt.id
export type NimbraEdgeProductId = typeof PRODUCTS.nimbraEdge.id

export interface Scte35Notification {
    channelId: number
    streamId: number
    eventId: number
    format: Format
    durationMs: number
}

// Data available in the Handlebars template URL - do not include any internal data here
export interface Scte35NotificationTemplate {
    eventId: number
    format: Format
    durationMs: number
    durationS: number
}

export interface VastRequest {
    adServerId: string
    eventId: number
    outputId: string
    channelId: number
    streamId: number
    outputFormat: Format
    url: string
}

export interface Format {
    framerate: number
    scan: 'progressive' | 'interlaced'
    videoSize: { horizontal: number; vertical: number }
    codec: VideoCodec
    bitrate: number
    audioCodec: AudioCodec
    audioBitrate: number
    audioSampleRate: number
}

// export interface Scte35Notification {
//     channelId: number
//     streamId: number
//     outputFormat: Format
//     scte35Message: Scte35Message
// }

// export interface Scte35Message {
//     eventId: number // Unique identifier for the SCTE-35 event
//     duration: number // Duration of the event in seconds
//     type: 'splice_insert' | 'time_signal' | 'segmentation_descriptor' | 'bandwidth_reservation' | 'private_command' // Explicitly listed SCTE-35 message types
//     segmentationType?:
//         | 'Program Start'
//         | 'Program End'
//         | 'Chapter Start'
//         | 'Chapter End'
//         | 'Break Start'
//         | 'Break End'
//         | 'Provider Advertisement Start'
//         | 'Provider Advertisement End'
//         | 'Distributor Advertisement Start'
//         | 'Distributor Advertisement End'
//         | 'Provider Promo Start'
//         | 'Provider Promo End'
//         | 'Distributor Promo Start'
//         | 'Distributor Promo End'
//         | 'Unscheduled Event Start'
//         | 'Unscheduled Event End'
//         | 'Network Start'
//         | 'Network End' // Segmentation event types (per SCTE-35)
//     segmentationUpid?: string // Unique Program Identifier (e.g., content ID)
//     ptsTime?: number // Presentation timestamp in seconds
//     utcTime?: string // UTC time representation of the event
//     isEncrypted?: boolean // Indicates if the SCTE-35 message is encrypted
//     cueInfo?: string // Additional information or metadata for the cue
// }

export interface AdImpressionReport {
    creativeId: string
    url: string
    timestamp: string
    outputId?: string
    streamId: number
    channelId: number
    eventId: number
    event: string
}

export interface AdErrorReport {
    creativeId: string
    url: string
    errorMessage: string
    errorCode: AdErrorCode
    timestamp: string
    outputId?: string
    streamId: number
    channelId: number
    eventId: number
    event: 'error'
}

export interface AdPlaylistRequest {
    events: AdEventRequest[]
}

// TODO: what exactly to call this? Ad Break?
export interface AdEventRequest {
    eventId: number
    channelId: number
    streamId: number
}

export interface ApplianceAdPlaylists {
    adPlaylists: ApplianceAdPlaylist[]
    // fillers: ApplianceAd[]
}

export interface AdPlaylist {
    eventId: number
    outputId: string
    streamId: number
    channelId: number
    requestedFormat: Format
    ads: Ad[]
}

export interface ApplianceAdPlaylist {
    eventId: number
    channelId: number
    streamId: number
    format: Format
    ads: ApplianceAd[]
}

export interface TrackingEvent {
    event: string
    offsetMs: number
    url: string
}

export interface MediaFile {
    url: string
    presignedUrl?: string
    bitrate?: number // 19500
    width?: number // 1920
    height?: number // 1080
    delivery?: string // progressive
    type?: string // video/mp4
}

export interface Ad {
    id: string
    durationMs: number
    mediaFiles: MediaFile[]
    trackingEvents: TrackingEvent[]

    /** loadedUrl is called when an ad is downloaded and ready to be played.
     * It is originally a TrackingEvent in a VAST response, but since
     * it should be triggered before the ad is played, it is more convenient
     * to have it as a separate field here (Other tracking events are triggered
     * based on their `offsetMs`)
     */
    loadedUrl?: string
    /** errorUrl does not exist for all VAST responses, so it is optional
     * If it is set, it should be called on any errors related to the ad
     */
    errorUrl?: string
}

export interface ApplianceAd {
    id: string
    durationMs: number
    fileName: string
    mediaFile: MediaFile
    trackingEvents: TrackingEvent[]
    loadedUrl?: string
    errorUrl?: string
}

export interface AdToTranscode {
    id: string
    eventId: number
    outputId: string
    streamId: number
    channelId: number
    errorUrl?: string

    requestedFormat: Format
    fileName: string
    mediaFile: MediaFile
    durationMs: number
}

export type AdFileType = 'mezzanine' | 'ad'

export interface AdServer {
    id: string
    name: string
    baseUrl: string
}

export interface AdServerInit {
    name: string
    baseUrl: string
}

export type DeletedAdServer = Entity

export interface AdAuditLogMessage {
    // What, how
    entityType: AdAuditEntity
    method?: AdAuditMethod
    status?: AdAuditStatus // Fail status here is not acceptable and should be investigated
    result?: AdAuditResult // If the method is allowed to fail (e.g. cache miss)
    message?: string // Additional information
    eventId?: number
    outputId?: string
    streamId?: number
    channelId?: number

    // When, where
    component: AdAuditComponent // ad-service, ad-transcoder, vast-requester, vast-reporter
    pod: string // Which pod the log entry is from, e.g. ad-transcoder-0
    timestamp: string // ISO 8601 timestamp

    // Describe the entity being processed
    entity?: any
    entityId?: string
    entityName?: string

    // Where is the entity going from/to
    source?: string // E.g. URL of a requested file
    target?: string // E.g. path to a downloaded file

    // For intra-service tracability
    traceId?: string
}

export enum AdAuditEntity {
    impressionReport = 'impression-report',
    impressionErrorReport = 'impression-error-report',
    vast = 'vast',
    scte35Notification = 'scte35-notification',
    applianceAdPlaylist = 'appliance-ad-playlist',
    adToTranscode = 'ad-to-transcode',
    transcodedFile = 'transcoded-file',
    mezzanineFile = 'mezzanine-file',
    applianceAd = 'appliance-ad',
    adBreak = 'ad-break',
    outputStream = 'output-stream',
}

export enum AdAuditMethod {
    exists = 'exists',
    download = 'download',
    upload = 'upload',
    transcode = 'transcode',
    parse = 'parse',
    receive = 'receive',
    request = 'request',
    retentionDelete = 'retention-delete',
    play = 'play',
}

// A fail status is not acceptable and should be investigated
export enum AdAuditStatus {
    start = 'start',
    finish = 'finish',
    fail = 'fail',
}

// A fail result is acceptable,
// such as a cache miss which is represented as AdAuditMethod.exists, AdAuditStatus.fail
export enum AdAuditResult {
    success = 'success',
    fail = 'fail',
    warning = 'warning',
}

export enum AdAuditComponent {
    adService = 'ad-service',
    adTranscoder = 'ad-transcoder',
    adRetention = 'ad-retention',
    vastRequester = 'vast-requester',
    vastReporter = 'vast-reporter',
    videoAppliance = 'video-appliance',
}

export enum AdStage {
    playlist = 'playlist',
    firstAd = 'first-ad',
    allAds = 'all-ads',
}

/**
 * Error codes used in code that are translated to VAST error codes
 */
export enum AdErrorCode {
    failedToPlayAd = 400,
    failedToDownloadAd = 402,
    unknownError = 900,
}

export interface AdStageStats {
    stage: AdStage
    durationMs: number
}

export interface AdStageTags {
    channelId: number
    streamId: number
    eventId: number
}

export interface ScteInjectionSettings {
    eventId: number
    programId: number
    preRollMs: number
    durationMs: number
}
