import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Grid from '@mui/material/Grid'

import { ButtonsPane, Checkbox, Form, Paper, SafeRouting, TextInput } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import { AppDispatch, GlobalState, useRoutes } from '../../store'
import { Network, Role } from 'common/api/v1/types'
import { formTransform, useConfirmationDialog, useUser } from '../../utils'
import {
  clearNetwork,
  createNetwork,
  getNetwork,
  removeNetwork,
  updateNetwork,
} from '../../redux/actions/networkActions'
import RHF, { FormProps } from '../common/Form/RHF'

const NetworkForm = ({ getValues, setError, setFocus, formState }: FormProps<Network | Omit<Network, 'id'>>) => {
  const values = getValues()
  const navigate = useNavigate()
  const routes = useRoutes()
  const user = useUser()
  const setConfirmation = useConfirmationDialog()
  const dispatch = useDispatch<AppDispatch>()
  const { formErrors, saving } = useSelector(
    ({ networksReducer }: GlobalState) => ({ formErrors: networksReducer.formErrors, saving: networksReducer.saving }),
    shallowEqual,
  )

  useEffect(() => {
    if (Array.isArray(formErrors)) {
      formErrors.forEach((item) => {
        setError(item.name as keyof Network, { type: 'manual', message: item.reason })
      })
      if (formErrors[0]) setFocus(formErrors[0].name as keyof Network)
    }
  }, [formErrors, setError, setFocus])

  const deleteNetwork = () =>
    'id' in values &&
    setConfirmation(
      () => void dispatch(removeNetwork(values.id)),
      `Are you sure you want to delete network ${values.name}?`,
    )

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting formState={formState} />
        <Form id="network-form" noValidate>
          <Paper
            title="Network"
            tooltip={
              <>
                <p>Interfaces within the same network will be connected using their private IP addresses.</p>
                <p>To assign networks to interfaces navigate to an appliance or a particular interface.</p>
                <p>Networks can be temporarily disabled to switch over to the behavior defined by address mappings.</p>
              </>
            }
          >
            <TextInput name="name" label="Name" required autoFocus />
            <Checkbox name="enabled" label="Enable" />
          </Paper>
          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => navigate(routes.networks()),
              },
              Save: { savingState: !!saving, primary: true, type: 'submit' },
            }}
            secondary={
              'id' in values && user.role === Role.super
                ? {
                    'Remove network': { onClick: deleteNetwork },
                  }
                : undefined
            }
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export default function EditNetwork() {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    id && dispatch(getNetwork(id))
    return () => {
      dispatch(clearNetwork())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const networkToEdit = useSelector(({ networksReducer }: GlobalState) => networksReducer.network, shallowEqual)
  const showConfirmation = useConfirmationDialog()
  const onSubmit = (network: Network | Omit<Network, 'id'>) => {
    if (networkToEdit) {
      if (network.enabled === networkToEdit.enabled) return dispatch(updateNetwork(network as Network))
      showConfirmation(
        () => void dispatch(updateNetwork(network as Network)),
        `${network.enabled ? 'Enabling' : 'Disabling'} the network may cause disruptions in active routing services.
          Are you sure you want to proceed?`,
      )
    } else dispatch(createNetwork(network))
  }
  const user = useUser()
  if (!user || user.role !== Role.super) return null

  return (
    <Wrapper name={['Networks', id ? networkToEdit?.name : 'New']}>
      <Pendable pending={!!id && !networkToEdit}>
        <RHF
          onSubmit={(values) => {
            onSubmit(formTransform(values))
          }}
          defaultValues={
            ((networkToEdit as Network) || ({ name: '', enabled: true } as Omit<Network, 'id'>)) as
              | Network
              | Omit<Network, 'id'>
          }
          component={NetworkForm}
        />
      </Pendable>
    </Wrapper>
  )
}
