import { DATE_FORMAT_LONG, DATE_FORMAT_LONGER } from 'common/api/v1/helpers'
import { format, addHours, addDays, addMonths, addMinutes, addSeconds, addMilliseconds, subDays } from 'date-fns'

export const dateFilterPossibleValues = (s?: string): { value: string; displayName: string }[] => {
  let now = new Date()
  now.setMinutes(0, 0, 0)
  now = subDays(now, 1)

  const options: { value: string; displayName: string }[] = []

  let maxValue = 24
  let stride = 1
  let dateFormat = DATE_FORMAT_LONG
  let dateAdderFn = addHours

  if (s) {
    now = new Date(s.replace('T', ' ').replace('Z', ' '))

    if (s.length <= 6) {
      // 2025XXX
      maxValue = 12
      dateAdderFn = addMonths
    } else if (s.length >= 7 && s.length <= 9) {
      // 2025-02XXX
      maxValue = 31
      dateAdderFn = addDays
    } else if (s.length >= 10 && s.length <= 12) {
      // 2025-02-11XXX
      maxValue = 24
      dateAdderFn = addHours
    } else if (s.length >= 13 && s.length <= 15) {
      // 2025-02-11T12XXX
      maxValue = 60
      stride = 5
      dateAdderFn = addMinutes
    } else if (s.length >= 16 && s.length <= 18) {
      // 2025-02-11T12:00XXX
      maxValue = 60
      stride = 5
      dateAdderFn = addSeconds
    } else if (s.length >= 19 && s.length <= 23) {
      // 2025-02-11T12:00:00XXXXX
      maxValue = 1000
      stride = 100
      dateFormat = DATE_FORMAT_LONGER
      dateAdderFn = addMilliseconds
    }
  }

  for (let i = 0; i < maxValue; i += stride) {
    const date = dateAdderFn(now, i)

    let displayName = format(date, dateFormat)
    if (s?.includes('T')) displayName = displayName.replace(' ', 'T')
    if (s?.includes('Z')) displayName = displayName + 'Z'

    options.push({ value: date.toISOString(), displayName })
  }

  return options
}
