import React, { useState, useEffect, SyntheticEvent } from 'react'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Warning from '@mui/icons-material/Warning'
import Error from '@mui/icons-material/Error'
import Info from '@mui/icons-material/Info'
import Close from '@mui/icons-material/Close'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import { styled, type Theme } from '@mui/material/styles'
import {
  useSnackbar,
  MaterialDesignContent,
  SnackbarProvider as NotistackProvider,
  type CloseReason,
  type SnackbarOrigin,
} from 'notistack'

import {
  removeSnackbar,
  closeSnackbar as closeSnackbarAction,
  Notification,
  NotificationErrorData,
} from '../../redux/actions/notificationActions'
import { GlobalState, AppDispatch } from '../../store'
import { styles } from '../../Common'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-success': styles.success(theme),
  '&.notistack-MuiContent-error': styles.error(theme),
  '&.notistack-MuiContent-warning': styles.warning(theme),
  '&.notistack-MuiContent-info': styles.info(theme),
}))

const iconProps = {
  style: {
    opacity: 0.6,
    marginRight: '12px',
  },
} as const

export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => (
  <NotistackProvider
    maxSnack={3}
    Components={{
      success: StyledMaterialDesignContent,
      error: StyledMaterialDesignContent,
      warning: StyledMaterialDesignContent,
      info: StyledMaterialDesignContent,
    }}
    iconVariant={{
      success: <CheckCircle {...iconProps} />,
      error: <Error {...iconProps} />,
      warning: <Warning {...iconProps} />,
      info: <Info {...iconProps} />,
    }}
  >
    {' '}
    {children}
  </NotistackProvider>
)

const buttonStyle = {
  color: (theme: Theme) => theme.palette.common.black,
  opacity: 0.6,
} as const

/**
 * Component to subscribe and control Redux state for snackbars
 * Every enqueue/dismiss for notification is basically push/pop from the notifications array in Redux
 */
export const Snackbar = () => {
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const notifications: Notification[] = useSelector(
    ({ notificationsReducer: notifications }: GlobalState) => notifications,
  )
  const errorSnackbarPosition = useSelector(({ uiReducer }: GlobalState) => uiReducer.errorSnackbarPosition)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [errorKeys, setErrorKeys] = useState<Notification['options']['key'][]>([])
  useEffect(() => {
    errorKeys.forEach((key) => dispatch(closeSnackbarAction(key)))
    setErrorKeys([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname])

  // Basic string message
  const enqueueBasicSnackbar = (notification: Notification) => {
    enqueueSnackbar(notification.message, {
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      ...notification.options,
      action: defaultAction(notification.options.key || ''),
      onClose: defaultOnClose(notification),
      preventDuplicate: true,
    })
  }

  // A new version is available, i.e. the client version is behind reported backend version
  const enqueueNewVersionSnackbar = (notification: Notification) => {
    enqueueSnackbar('A new version is available', {
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      action: () => (
        <>
          <Button color="primary" size="small" onClick={() => window.location.reload()}>
            Reload
          </Button>
          <Button color="inherit" onClick={() => dispatch(closeSnackbarAction(notification.options.key || ''))}>
            <Close sx={{ opacity: buttonStyle.opacity }} />
          </Button>
        </>
      ),
      onClose: defaultOnClose(notification),
      ...notification.options,
      key: 'new-version',
      preventDuplicate: true,
    })
  }

  // Fatal errors, encapsulating ErrorSnackbar
  const enqueueErrorSnackbar = (notification: Notification) => {
    const anchorOrigin: SnackbarOrigin = {
      horizontal: 'right',
      vertical: errorSnackbarPosition === 'top-right' ? 'top' : 'bottom',
    }
    enqueueSnackbar(<ErrorSnackbar error={notification.data as NotificationErrorData} />, {
      anchorOrigin,
      action: defaultAction(notification.options.key || ''),
      onClose: defaultOnClose(notification),
      ...notification.options,
      preventDuplicate: true,
    })
    setErrorKeys([...errorKeys, notification.options.key])
  }

  const defaultAction = (key: string | number) => (
    <Button onClick={() => dispatch(closeSnackbarAction(key))}>
      <Close sx={buttonStyle} data-test-id="close-snackbar" />
    </Button>
  )

  const defaultOnClose =
    (notification: Notification) => (event: SyntheticEvent<Element> | null, reason: CloseReason) => {
      if (notification.options.onClose) {
        notification.options.onClose(event, reason)
      }
      dispatch(removeSnackbar(notification.options.key || ''))
    }

  useEffect(() => {
    notifications.forEach((notification) => {
      const key = notification.options.key || ''

      if (notification.dismissed) {
        closeSnackbar(key)
        dispatch(removeSnackbar(key))
        return
      }

      switch (notification.type) {
        case 'basic':
          enqueueBasicSnackbar(notification)
          break
        case 'newVersionAvailableSnackbar':
          enqueueNewVersionSnackbar(notification)
          break
        case 'fatalError':
          enqueueErrorSnackbar(notification)
          break
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  return null
}

/**
 * Specific snackbar to display error
 * @param error - NotificationErrorData
 */
const ErrorSnackbar: React.FC<{ error: NotificationErrorData }> = ({ error }) => {
  const [collapsed, setCollapsed] = useState(false)
  let errorText = error.text
  let errorTitle = error.text
  if (error.httpStatusCode === 403) {
    errorTitle = 'Insufficient rights'
    errorText = 'Insufficient privilege for this user interaction, please contact your administrator'
  }
  if (error.httpStatusCode === 409) {
    errorTitle = 'Conflict'
  }

  return (
    <Box sx={{ maxWidth: 420 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& > *:first-child': {
            flexGrow: 1,
          },
        }}
      >
        <Typography data-test-id="title">{errorTitle}</Typography>
        <Button onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <ExpandMore sx={buttonStyle} /> : <ExpandLess sx={buttonStyle} />}
        </Button>
      </Box>
      <Collapse in={!collapsed}>
        {errorText !== errorTitle && (
          <Typography component="div" variant="body2">
            {errorText}
          </Typography>
        )}
        <Typography component="div" variant="body2" data-test-id="details" style={{ whiteSpace: 'pre-wrap' }}>
          {formatErrorDetails(error.details)}
        </Typography>
      </Collapse>
    </Box>
  )
}

function formatErrorDetails(details: any) {
  if (!details) {
    return ''
  }
  if (typeof details === 'string') {
    return details
  }
  let detailsText = ''
  if (Array.isArray(details)) {
    detailsText += 'Details: '
    for (const detail of details) {
      detailsText += `${formatDetail(detail)}\n`
    }
  }
  return detailsText
}

function formatDetail(detail: any) {
  if (!detail) {
    return ''
  }
  if (typeof detail === 'string') {
    return detail
  }
  if (typeof detail.reason === 'string') {
    return detail.reason
  }
  return JSON.stringify(detail)
}
