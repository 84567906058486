import { createSlice } from '@reduxjs/toolkit'
import { LOCAL_STORAGE_TAGS_HISTORY_KEY } from '../../localstorage'
import { isOneOf } from '../actions'
import { updateSearchHistory, deleteSearchHistory } from '../actions/tagsAction'

type State = { searchHistory: string[] }
const initialState: State = { searchHistory: JSON.parse(localStorage.getItem(LOCAL_STORAGE_TAGS_HISTORY_KEY) ?? '[]') }

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isOneOf([updateSearchHistory.fulfilled, deleteSearchHistory.fulfilled]),
      (_, { payload }): State => ({ searchHistory: payload }),
    )
  },
})

export default tagsSlice.reducer
