import Typography from '@mui/material/Typography'
import { parseK8sMemoryString } from 'common/utils'

export type AddStoragePoolDialogProps = {
  storagePool: {
    name: string
    servers: number
    size: string
  }
}

export const DeleteStoragePoolDialog = ({ storagePool }: AddStoragePoolDialogProps) => {
  const volumesPerNode = 2
  const totalBytes = storagePool.servers * volumesPerNode * parseK8sMemoryString(storagePool.size)
  const totalGi = totalBytes / 1024 ** 3

  return (
    <>
      <Typography>
        <strong>Warning!</strong> You are about to delete a storage pool. Make sure you have migrated or backed up the
        data stored on it.
      </Typography>
      <br />
      <Typography>Removing storage pool with the following properties:</Typography>
      <Typography component={'div'}>
        <ul>
          <li>
            Name: <strong>{storagePool.name}</strong>
          </li>
          <li>
            Nodes: <strong>{storagePool.servers}</strong>
          </li>
          <li>
            Node volume size: <strong>{storagePool.size}</strong>
          </li>
          <li>
            Volumes per node: <strong>{volumesPerNode}</strong>
          </li>
        </ul>
      </Typography>
      <Typography>
        This will shrink the service by <strong>{totalGi.toFixed(2)} Gi</strong>.
      </Typography>
    </>
  )
}
