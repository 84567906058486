import { AdAuditEntity, AdAuditMethod, AdAuditStatus } from 'common/types'

export interface AdInsertionStage {
  stage: string
  displayName: string
  status?: string
  progressCriteria?: AdInsertionStageCriterion[]
  doneCriteria?: AdInsertionStageCriterion[]
  endTime?: number
  previousStage?: string
  hideTime?: boolean
}

// Combination of entity, method and status to match in ad audit log
export interface AdInsertionStageCriterion {
  entity: AdAuditEntity
  method: AdAuditMethod
  status: AdAuditStatus
  entityName?: string
}

export const initialStages: AdInsertionStage[] = [
  { stage: 'request', displayName: 'Request', status: 'done' },
  {
    stage: 'scte35',
    displayName: 'SCTE 35 cue received',
    doneCriteria: [
      { entity: AdAuditEntity.scte35Notification, method: AdAuditMethod.receive, status: AdAuditStatus.finish },
    ],
  },
  {
    stage: 'got-vast',
    displayName: 'VAST received',
    doneCriteria: [{ entity: AdAuditEntity.vast, method: AdAuditMethod.parse, status: AdAuditStatus.finish }],
  },
  {
    stage: 'download',
    displayName: 'Download mezzanine',
    progressCriteria: [
      { entity: AdAuditEntity.mezzanineFile, method: AdAuditMethod.download, status: AdAuditStatus.start },
    ],
    doneCriteria: [
      { entity: AdAuditEntity.mezzanineFile, method: AdAuditMethod.download, status: AdAuditStatus.finish },
    ],
  },
  {
    stage: 'upload-mezzanine',
    displayName: 'Cache mezzanine',
    progressCriteria: [
      { entity: AdAuditEntity.mezzanineFile, method: AdAuditMethod.upload, status: AdAuditStatus.start },
    ],
    doneCriteria: [{ entity: AdAuditEntity.mezzanineFile, method: AdAuditMethod.upload, status: AdAuditStatus.finish }],
    previousStage: 'download',
  },
  {
    stage: 'transcode',
    displayName: 'Transcode',
    progressCriteria: [
      { entity: AdAuditEntity.transcodedFile, method: AdAuditMethod.transcode, status: AdAuditStatus.start },
    ],
    doneCriteria: [
      { entity: AdAuditEntity.transcodedFile, method: AdAuditMethod.transcode, status: AdAuditStatus.finish },
    ],
    previousStage: 'download',
  },
  {
    stage: 'upload-transcoded',
    displayName: 'Cache ad',
    progressCriteria: [
      { entity: AdAuditEntity.transcodedFile, method: AdAuditMethod.upload, status: AdAuditStatus.start },
    ],
    doneCriteria: [
      { entity: AdAuditEntity.transcodedFile, method: AdAuditMethod.upload, status: AdAuditStatus.finish },
    ],
    previousStage: 'transcode',
  },
  // FIXME: Add these when they are added to the ad audit log
  // { stage: 'available-on-node', displayName: 'Available on node', doneCriteria: [] },
  // { stage: 'break-start', displayName: 'Ad break start', doneCriteria: [], hideTime: true },
  {
    stage: 'impression',
    displayName: 'Report: Impression',
    doneCriteria: [
      {
        entity: AdAuditEntity.impressionReport,
        method: AdAuditMethod.upload,
        status: AdAuditStatus.finish,
        entityName: 'impression',
      },
    ],
    hideTime: true,
  },
  {
    stage: 'start',
    displayName: 'Report: 0/4',
    doneCriteria: [
      {
        entity: AdAuditEntity.impressionReport,
        method: AdAuditMethod.upload,
        status: AdAuditStatus.finish,
        entityName: 'start',
      },
    ],
    hideTime: true,
  },
  {
    stage: 'first-quartile',
    displayName: 'Report: 1/4',
    doneCriteria: [
      {
        entity: AdAuditEntity.impressionReport,
        method: AdAuditMethod.upload,
        status: AdAuditStatus.finish,
        entityName: 'firstQuartile',
      },
    ],
    hideTime: true,
  },
  {
    stage: 'second-quartile',
    displayName: 'Report: 2/4',
    doneCriteria: [
      {
        entity: AdAuditEntity.impressionReport,
        method: AdAuditMethod.upload,
        status: AdAuditStatus.finish,
        entityName: 'midpoint',
      },
    ],
    hideTime: true,
  },
  {
    stage: 'third-quartile',
    displayName: 'Report: 3/4',
    doneCriteria: [
      {
        entity: AdAuditEntity.impressionReport,
        method: AdAuditMethod.upload,
        status: AdAuditStatus.finish,
        entityName: 'thirdQuartile',
      },
    ],
    hideTime: true,
  },
  {
    stage: 'finish',
    displayName: 'Report: 4/4',
    doneCriteria: [
      {
        entity: AdAuditEntity.impressionReport,
        method: AdAuditMethod.upload,
        status: AdAuditStatus.finish,
        entityName: 'complete',
      },
    ],
    hideTime: true,
  },
]
