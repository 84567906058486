import { GridItem, Paper } from '../common/Form'
import Wrapper from '../common/Wrapper'
import { useNavigate } from 'react-router'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { AdInsertionSettingsForm } from './AdInsertionSettings'
import { AdServerList } from './adServer/List'
import Grid2 from '@mui/material/Grid2'

const stretchedPaper = {
  margin: 0,
  overflow: 'hidden',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
}

export const AdInsertionPage = () => {
  const navigate = useNavigate()

  return (
    <Wrapper name="Ad Insertion">
      <AdServerList />

      <Grid2 container spacing={3} alignItems="stretch" pb={3}>
        <Grid2 size={{ sm: 12, md: 6 }}>
          <Paper sx={stretchedPaper} title="Audit Log">
            <GridItem lg={12} xl={12}>
              <Typography variant="body1">Explore logs from all Ad Insertion services</Typography>
            </GridItem>

            <GridItem lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={() => navigate('adAudit')}>
                View logs
              </Button>
            </GridItem>
          </Paper>
        </Grid2>
        <Grid2 size={{ sm: 12, md: 6 }}>
          <Paper sx={stretchedPaper} title={'Metrics'}>
            <GridItem lg={12} xl={12}>
              <Typography variant="body1">Explore metrics from all Ad Insertion services</Typography>
            </GridItem>

            <GridItem lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                href="/grafana/d/ad-insertion/ad-insertion-metrics?orgId=1&refresh=5s"
                rel="noopener noreferrer"
                target="_blank"
              >
                View metrics
              </Button>
            </GridItem>
          </Paper>
        </Grid2>
        <Grid2 size={{ sm: 12, md: 6 }}>
          <AdInsertionSettingsForm />
        </Grid2>
        <Grid2 size={{ sm: 12, md: 6 }}>
          <Paper sx={stretchedPaper} title="Demo mode">
            <GridItem lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={() => navigate('demo')}>
                Enter
              </Button>
            </GridItem>
          </Paper>
        </Grid2>
      </Grid2>
    </Wrapper>
  )
}
