import MuiPagination from '@mui/material/Pagination'

import { DEFAULT_PAGINATION, usePageParams } from '../../../utils'
import MenuItem from '@mui/material/MenuItem'
import MuiSelect from '@mui/material/Select'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import TextField from '@mui/material/TextField'
export interface PaginationProps {
  id?: string
  total: number
  useUrlSearchParams?: true | { pageNumberKey: string; rowsPerPageKey: string }
  changePageCallback?: (val: string) => void
  changeRowsPerPageCallback?: (val: string) => void
  page?: string
  perPage?: string
  hidePerPageOption?: boolean
  hideJumpToPage?: boolean
}

/**
 * Common pagination component
 * @param id
 * @param changePageCallback - callback to call on page number change
 * @param changeRowsPerPageCallback - callback to call on rows per page number change
 * @param page - default page number
 * @param perPage - default rows per page number
 * @param total - total amount of entities
 * @param useUrlSearchParams - whether we should set url search params 'rowsPerPage' and 'pageNumber' etc, instead of calling callbacks on changes
 */
export const Pagination = ({
  id,
  changePageCallback,
  changeRowsPerPageCallback,
  page,
  perPage,
  total,
  useUrlSearchParams,
  hidePerPageOption,
  hideJumpToPage,
}: PaginationProps) => {
  const [params, setPageParams] = usePageParams()
  const [pageNumberKey, rowsPerPageKey] =
    typeof useUrlSearchParams === 'object'
      ? [useUrlSearchParams.pageNumberKey, useUrlSearchParams.rowsPerPageKey]
      : ['pageNumber', 'rowsPerPage']

  // Set current page according to order: 1) TablePagination 2) Url params 3) Defaults
  const pageNumber = page !== undefined ? page : params[pageNumberKey] ?? '0'

  const rowsPerPageStr = getValidRowsPerPage(
    perPage !== undefined ? perPage : params[rowsPerPageKey] ?? DEFAULT_PAGINATION.rowsPerPage,
  )
  function getValidRowsPerPage(rowsPerPageStr: string): string {
    const lRowsPerPage = parseInt(rowsPerPageStr)
    const isValid = !isNaN(lRowsPerPage) && lRowsPerPage > 0
    return isValid ? rowsPerPageStr : '10'
  }

  const handlePageChange = (page: number) => {
    if (changePageCallback) return changePageCallback(String(page))
    if (useUrlSearchParams) {
      setPageParams({
        [rowsPerPageKey]: rowsPerPageStr,
        [pageNumberKey]: String(page),
      })
    }
  }

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    if (changeRowsPerPageCallback) return changeRowsPerPageCallback(String(rowsPerPage))
    if (useUrlSearchParams) {
      DEFAULT_PAGINATION.rowsPerPage = getValidRowsPerPage(String(rowsPerPage))
      setPageParams({
        [rowsPerPageKey]: String(rowsPerPage),
        [pageNumberKey]: '0',
      })
    }
  }

  const rowsPerPageOptions = Array.from(new Set([10, 30, 50, parseInt(rowsPerPageStr)])).sort((a, b) => a - b)

  return (
    <PaginationComponent
      data-test-id={id ? `pagination-${id}` : undefined}
      total={total}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      page={Number(pageNumber)}
      rowsPerPageOptions={rowsPerPageOptions}
      rowsPerPage={Number(rowsPerPageStr)}
      hideRowsPerPage={hidePerPageOption}
      hideJumpToPage={hideJumpToPage}
    />
  )
}

interface PaginationComponentProps {
  total: number

  page: number
  onPageChange: (page: number) => void

  rowsPerPage: number
  rowsPerPageOptions: number[]
  onRowsPerPageChange: (rowsPerPage: number) => void

  hideJumpToPage?: boolean
  hideRowsPerPage?: boolean
}
const PaginationComponent = ({
  rowsPerPage: unsafeRowsPerPage,
  rowsPerPageOptions,
  onRowsPerPageChange,
  page,
  onPageChange,
  total,
  hideRowsPerPage,
  hideJumpToPage,
}: PaginationComponentProps) => {
  const theme = useTheme()
  const pagePlusOne = page + 1 // MuiPagination page prop starts at 1, but our api is zero-indexed
  const rowsPerPage = Math.max(1, unsafeRowsPerPage)
  const upperBound = pagePlusOne * rowsPerPage
  const lowerBound = upperBound - rowsPerPage + 1
  const cappedUpperBound = Math.min(upperBound, total)
  const cappedLowerBound = Math.min(lowerBound, total)
  const lastPage = Math.ceil(total / rowsPerPage)

  const [requestedPage, setRequestedPage] = useState<string>('')

  const goToRequestedPage = () => {
    const requestedPageNumber = parseInt(requestedPage) - 1
    if (!isNaN(requestedPageNumber)) {
      const safePage = Math.max(0, Math.min(lastPage - 1, requestedPageNumber))
      if (safePage !== page) {
        setRequestedPage('')
        onPageChange(safePage)
      }
    }
  }

  const minimumRowsPerPages = Math.min(...rowsPerPageOptions)
  const hasMultiplePages = total > minimumRowsPerPages
  const canPaginate = lastPage > 1
  return (
    <div
      style={{
        width: '100%',
        height: 50,
        padding: '4px 12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {!hideRowsPerPage && hasMultiplePages && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label style={{ fontSize: theme.typography.body2.fontSize }}>Rows Per Page:</label>
          <div style={{ width: '12px' }} />
          <MuiSelect
            style={{ fontSize: theme.typography.body2.fontSize }}
            variant="standard"
            value={rowsPerPage}
            onChange={({ target }) => onRowsPerPageChange(parseInt(target.value as string))}
          >
            {rowsPerPageOptions.map((option) => (
              <MenuItem value={option} key={`menu-item-${option}`}>
                {option}
              </MenuItem>
            ))}
          </MuiSelect>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexGrow: 1 }}>
        <label style={{ fontSize: theme.typography.body2.fontSize }}>
          {cappedLowerBound}-{cappedUpperBound} of {total}
        </label>

        {!hideJumpToPage && canPaginate && (
          <TextField
            type={'text'}
            style={{ marginLeft: '4px', maxWidth: '92px', paddingLeft: 6, paddingRight: 2 }}
            size={'small'}
            variant="outlined"
            label={'Go to page'}
            value={requestedPage}
            onChange={({ target }) => {
              const isValidInteger =
                !isNaN(Number(target.value)) && // Number allows whitespaces, tabs, newlines (and who knows what else)
                !isNaN(parseInt(target.value)) && // parseInt allows "1 2" (will be parsed to "1")
                !target.value.includes(' ') && // e.g. '3   '
                !target.value.includes('.') && // e.g. '3.5'
                !(target.value.startsWith('0') && target.value.length > 1) // e.g. '0000'

              if (target.value === '' || isValidInteger) {
                setRequestedPage(target.value)
              }
            }}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                goToRequestedPage()
                ev.preventDefault()
              }
            }}
            slotProps={{
              inputLabel: {
                style: { fontSize: theme.typography.body2.fontSize },
              },
            }}
          />
        )}

        {canPaginate && (
          <MuiPagination
            size="small"
            shape="rounded"
            page={pagePlusOne}
            count={lastPage}
            onChange={(_, requestedPage) => onPageChange(requestedPage - 1)}
          />
        )}
      </div>
    </div>
  )
}
