import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import Autocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import { SxProps, Theme } from '@mui/material/styles'
import debounce from 'lodash/debounce'
import omit from 'lodash/omit'
import upperFirst from 'lodash/upperFirst'

import { ListResult } from 'common/api/v1/types'
import GridItem, { GridItemProps } from '../GridItem'
import { PaginatedRequestParams } from '../../../../api/nm-types'
import { validate } from '../../../../utils'

type MuiAC<T> = MuiAutocompleteProps<T, any, any, any>
type GenericAutocompleteProps<TEntity> = {
  name: string
  groupBy?: MuiAC<TEntity>['groupBy']
  api: (params: PaginatedRequestParams<any>) => Promise<ListResult<TEntity>>
  getOptionLabel: (option: TEntity) => string
  getOptionValue: (option: TEntity) => any
  getOptionKey?: (option: TEntity) => string
  optionComparator: MuiAC<TEntity>['isOptionEqualToValue']
  getOptionDisabled?: MuiAC<TEntity>['getOptionDisabled']
  label?: TextFieldProps['label']
  required?: TextFieldProps['required']
  disabled?: boolean
  xs?: GridItemProps['xs']
  newLine?: GridItemProps['newLine']
  onChange?: (value: TEntity) => void
  onClear?: () => void
  renderOption?: MuiAC<TEntity>['renderOption']
  tooltip?: GridItemProps['tooltip']

  // If true, the selected option becomes the value of the input when the Autocomplete loses focus
  // unless the user chooses a different option or changes the character string in the input.
  autoSelect?: boolean

  // Whether the MUI-autocomplete displays the "clear" icon. If true, the input can't be cleared.
  disableClearable?: boolean

  // Message displayed beneath the input text field (not visible when an error message is displayed)
  comment?: string
  commentProps?: Partial<SxProps<Theme>>

  // Only allow the options returned from the API or allow the user to enter their own values
  allowCustomOptions?: boolean
  validateFn?: (value: TEntity) => undefined | string | string[]
}

const Comp = forwardRef(({ field, ...props }: any, ref) => {
  const muiProps = {
    ...props,
    ref,
  }

  return <Autocomplete {...muiProps} />
})

type SingleAutocompleteProps<TEntity> = GenericAutocompleteProps<TEntity> & {
  defaultOption?: TEntity | undefined
  type?: TextFieldProps['type']
}

type MultipleAutocompleteProps<TEntity> = GenericAutocompleteProps<TEntity> & {
  multiple: true
  defaultOption?: TEntity[]
  filterSelectedOptions?: boolean
  validators?: Parameters<typeof validate>[0]
}

export type AutocompleteProps<TEntity> = SingleAutocompleteProps<TEntity> | MultipleAutocompleteProps<TEntity>

const FormAutocomplete = <TEntity,>(props: AutocompleteProps<TEntity>) => {
  const {
    required = false,
    xs,
    newLine,
    label,
    name,
    groupBy,
    autoSelect,
    disableClearable,
    defaultOption,
    api,
    onChange,
    onClear,
    getOptionValue,
    optionComparator,
    getOptionDisabled,
    tooltip,
    comment,
    commentProps,
    allowCustomOptions,
    validateFn,
    ...restProps
  } = props

  const { setValue: setFieldValue, getFieldState, formState } = useFormContext()

  const type = ('type' in restProps && restProps.type) || undefined
  const multiple = 'multiple' in restProps && restProps.multiple
  const filterSelectedOptions = 'filterSelectedOptions' in restProps && restProps.filterSelectedOptions
  const extraValidators = 'validators' in restProps ? restProps.validators : undefined
  const componentProps = omit(restProps, ['validators', 'filterSelectedOptions', 'type'])

  const validators = extraValidators ?? {}
  validators.presence = required

  const initialized = useRef(false)
  const isMounted = useRef(false)
  const [value, setValue] = useState<MuiAC<TEntity>['value']>(defaultOption || (multiple ? [] : null))
  const [inputValue, setInputValue] = useState(
    defaultOption && !multiple ? props.getOptionLabel(defaultOption as TEntity) : '',
  )
  const [options, setOptions] = useState<TEntity[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [apiSearchFilter, setApiSearchFilter] = useState<string | undefined>(undefined)

  const doClear = () => {
    setApiSearchFilter('')
    onClear?.()
  }

  const hasError = formState.errors[name] !== undefined || getFieldState(name)?.error !== undefined

  const fetch = useCallback(
    async (filter: string | undefined) => {
      const { items } = await api({ pageNumber: '0', rowsPerPage: '500', filter })
      if (isMounted.current) {
        setOptions(items)
        setIsLoading(false)
      }
      initialized.current = true
    },
    [api],
  )
  const debouncedFetch = useCallback(debounce(fetch, 500), [fetch])

  useEffect(() => {
    isMounted.current = true
    setOptions([])
    setIsLoading(true)

    if (initialized.current) {
      debouncedFetch(apiSearchFilter)
    } else {
      const value = defaultOption ? getOptionValue(defaultOption as TEntity) : null
      if (value !== null) {
        setFieldValue(name, value)
      }
      fetch(apiSearchFilter)
    }

    return () => {
      isMounted.current = false
    }
  }, [apiSearchFilter, fetch, debouncedFetch])

  const selectOption = (option: typeof value) => {
    const newValue = multiple ? option : option ? getOptionValue(option as TEntity) : null
    setFieldValue(name, newValue, { shouldTouch: true, shouldValidate: true })
    setValue(option)
    onChange?.(newValue)
  }

  return (
    <GridItem xs={xs} newLine={newLine} tooltip={tooltip}>
      <FormControl
        margin="normal"
        fullWidth
        id={`autocomplete-${name}`}
        data-is-loading={isLoading}
        data-num-options={options.length}
        data-selected-value={value}
      >
        <Controller
          name={name}
          rules={{ validate: validateFn || validate(validators) }}
          render={({ field: { ref } }) => (
            <Comp
              ref={ref}
              groupBy={groupBy}
              autoSelect={autoSelect}
              filterSelectedOptions={filterSelectedOptions}
              disableClearable={disableClearable}
              value={value}
              defaultValue={defaultOption}
              inputValue={inputValue}
              options={options}
              freeSolo={allowCustomOptions}
              loading={isLoading}
              isOptionEqualToValue={optionComparator}
              getOptionDisabled={getOptionDisabled}
              onChange={(_: any, newOption: typeof value) => {
                selectOption(newOption)
              }}
              onInputChange={(_: any, newInputValue: string, reason: string) => {
                if (reason === 'clear') {
                  doClear()
                }
                setInputValue(newInputValue)
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  name={name}
                  type={type}
                  label={label || upperFirst(name)}
                  variant="outlined"
                  required={required}
                  error={hasError}
                  onWheel={type === 'number' ? (e) => e.target instanceof HTMLElement && e.target.blur() : undefined}
                  onChange={(e) => {
                    setApiSearchFilter(e.target.value)
                  }}
                  onBlur={() => {
                    if (!autoSelect && allowCustomOptions && !multiple && inputValue) {
                      selectOption(inputValue)
                    } else if (inputValue === '' || !value) {
                      doClear()
                    }
                  }}
                  slotProps={{
                    htmlInput: { ...params.inputProps, autoComplete: 'off' },
                  }}
                />
              )}
              {...componentProps}
            />
          )}
        />
        {hasError && <FormHelperText error>{getFieldState(name)?.error?.message}</FormHelperText>}
        {!hasError && comment && (
          <FormHelperText sx={commentProps ?? {}} variant="filled">
            {comment}
          </FormHelperText>
        )}
      </FormControl>
    </GridItem>
  )
}

export default FormAutocomplete
