import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import MuiCheckbox from '@mui/material/Checkbox'
import {
  ApplianceSettings,
  ApplianceType,
  GeoLocation,
  LogLevel,
  Region,
  RistserverLogLevel,
} from 'common/api/v1/types'
import { Autocomplete, Checkbox, Paper, Select } from '../../../../common/Form'
import { Api } from '../../../../../store'
import { numericEnum, useSettingsSelector } from '../../../../../utils'
import ApplianceSpecificSettings from './ApplianceSpecificSettings'
import type { ApplianceEditState } from '../../index'

type RegionWithId = Pick<Region, 'id' | 'name'>

interface IApplianceFormSettingsProps {
  region: RegionWithId | undefined
  secondaryRegion: RegionWithId | undefined
  geoLocation: GeoLocation | undefined
  type: ApplianceType
  settings: ApplianceSettings | undefined
}

const Settings = ({ region, geoLocation, type, settings, secondaryRegion }: IApplianceFormSettingsProps) => {
  const { getValues, setValue, trigger, watch } = useFormContext<ApplianceEditState>()
  const { devMode } = useSettingsSelector()
  const regionApi = useCallback(Api.regionApi.getRegions.bind(Api.regionApi), [])
  const locationApi = useCallback(Api.locationApi.searchLocation.bind(Api.locationApi), [])
  const unhealthyAlarm = watch('unhealthyAlarm')

  return (
    <Paper title="Settings" collapsible>
      <Autocomplete<RegionWithId>
        name="region"
        required={true}
        api={regionApi}
        defaultOption={region}
        disabled={type === ApplianceType.core || type === ApplianceType.thumb}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option}
        optionComparator={(o1, o2) => o1.id === o2.id}
        onChange={() => void trigger('secondaryRegion')}
      />
      {type !== ApplianceType.core && type !== ApplianceType.thumb && (
        <Autocomplete<RegionWithId>
          name="secondaryRegion"
          api={regionApi}
          defaultOption={secondaryRegion}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option}
          optionComparator={(o1, o2) => o1.id === o2.id}
          label="Secondary region"
          validateFn={(value) => {
            const currentRegion = getValues('region')
            if (value && value.id === currentRegion?.id) {
              return 'Secondary region cannot be the same as the primary region'
            }

            return undefined
          }}
        />
      )}
      <Autocomplete<GeoLocation>
        name="geoLocation"
        label="City"
        api={locationApi}
        defaultOption={geoLocation}
        getOptionLabel={(option) => option.location}
        getOptionValue={(option) => option}
        optionComparator={({ coordinates: c1 }, { coordinates: c2 }) => c1[0] === c2[0] && c1[1] === c2[1]}
        comment={
          type === ApplianceType.core
            ? 'When changing City on a Core node, all other Core nodes in the region are updated with the same city.'
            : ''
        }
      />
      <Select
        label="Log level"
        name="logLevel"
        options={Object.entries(numericEnum(LogLevel)).map(([name, value]) => ({ name, value }))}
      />
      {devMode && (
        <Select
          label="Log level for ristserver"
          name="ristserverLogLevel"
          options={Object.entries(RistserverLogLevel).map(([name, value]) => ({ name, value }))}
        />
      )}

      <Checkbox
        label={'Collect Appliance Host Metrics'}
        name={`collectHostMetrics`}
        tooltip="Collect system metrics for monitoring from this Appliance if enabled."
      />
      <Checkbox
        label="Offline alarm"
        tooltip="Alarm when this Appliance is offline."
        control={
          <MuiCheckbox
            name="offlineAlarm"
            checked={unhealthyAlarm !== 'off'}
            onChange={(e) => {
              setValue('unhealthyAlarm', e.target.checked ? null : 'off')
            }}
          />
        }
      />
      <Checkbox
        label={'Cordon appliance'}
        name={`cordon`}
        tooltip="When checked, this appliance will not accept new services to be scheduled on it."
      />

      <ApplianceSpecificSettings devMode={devMode} type={type} settings={settings} />
    </Paper>
  )
}

export default Settings
