import { Component, type ComponentType, type ErrorInfo, type ReactNode } from 'react'
import { useNavigate, type NavigateFunction } from 'react-router'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { styles, Paper } from './Form'

interface Props {
  children: ReactNode
  navigate: NavigateFunction
}

interface State {
  error?: {
    error: Error
    errorInfo: ErrorInfo
  }
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: undefined }
    this.handleOK = this.handleOK.bind(this)
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: {
        error: error,
        errorInfo: errorInfo,
      },
    })
  }

  private async handleOK() {
    this.props.navigate(-1)
    // This is a workaround to avoid a setState before the navigation is complete
    // Which would cause the error-component to be re-rendered and the error to be thrown a second time
    await new Promise((resolve) => setTimeout(resolve, 500))
    this.setState({ error: undefined })
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorDetails
          error={this.state.error.error}
          errorInfo={this.state.error.errorInfo}
          onOKButtonClicked={this.handleOK}
        />
      )
    }
    return this.props.children
  }
}

const ErrorDetails = ({
  error,
  errorInfo,
  onOKButtonClicked,
}: {
  error: Error
  errorInfo: ErrorInfo
  onOKButtonClicked: () => void
}) => {
  return (
    <Container component="main" maxWidth="xs">
      <Paper>
        <Typography component="h2" variant="h2">
          Something went wrong
        </Typography>
        <br />
        <Typography component="h2" variant="body2">
          {error.toString()}
        </Typography>
        <br />
        <details open={false} style={{ whiteSpace: 'pre-wrap' }}>
          {errorInfo.componentStack}
        </details>
        <br />
        <Button onClick={onOKButtonClicked} variant="contained" color="primary" sx={styles.button}>
          OK
        </Button>
      </Paper>
    </Container>
  )
}

const withNavigate = (Component: ComponentType<any>) => {
  return function WrappedComponent(props: any) {
    const navigate = useNavigate()
    return <Component {...props} navigate={navigate} />
  }
}

export default withNavigate(ErrorBoundary)
