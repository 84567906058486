import Button from '@mui/material/Button'
import { PortType, VideonPortMode } from 'common/api/v1/types'
import IpPortForm from './IpPortForm'
import CoaxPortForm from './CoaxPortForm'
import { GridItem } from '../../../common/Form'
import VideonPortForm from './VideonPortForm'
import NdiForm from './NdiForm'
import EncoderSettings from '../EncoderSettings/EncoderSettings'
import { PhysicalPortInfoWithAppliance } from '../../../../api/nm-types'

interface IPortFormProps {
  portIndex: number
  physicalPort: PhysicalPortInfoWithAppliance
  isModeDisabled: boolean
  namePrefix: string
  onRemoveLogicalPortRequested?: (index: number) => void
  onAddLogicalPortRequested: () => void
}

export const PortForm = ({
  physicalPort,
  isModeDisabled,
  namePrefix,
  portIndex,
  onRemoveLogicalPortRequested: onRemove,
  onAddLogicalPortRequested,
}: IPortFormProps) => {
  return (
    <>
      {physicalPort.portType === PortType.ip && (
        <IpPortForm
          portIndex={portIndex}
          namePrefix={namePrefix}
          addresses={physicalPort.addresses}
          applianceId={physicalPort.appliance.id}
          applianceType={physicalPort.appliance.type}
          applianceVersion={physicalPort.appliance.version}
          applianceFeatures={physicalPort.appliance.features}
          occupiedPorts={physicalPort.occupiedPorts}
          isModeDisabled={isModeDisabled}
          onAddLogicalPortRequested={onAddLogicalPortRequested}
        />
      )}

      {physicalPort.portType === PortType.coax && (
        <CoaxPortForm namePrefix={namePrefix} applianceFeatures={physicalPort.appliance.features} />
      )}

      {physicalPort.portType === PortType.videon && (
        <VideonPortForm
          namePrefix={namePrefix}
          applianceFeatures={physicalPort.appliance.features}
          limitToMode={
            physicalPort.occupiedPorts.find((p) => p.portMode in VideonPortMode && !!p.inputId)
              ?.portMode as VideonPortMode
          }
        />
      )}

      {physicalPort.portType === PortType.ndi && (
        <NdiForm namePrefix={namePrefix} applianceId={physicalPort.appliance.id} />
      )}

      {onRemove && (
        <GridItem newLine>
          <Button style={{ marginTop: 12 }} variant="outlined" color="primary" onClick={() => onRemove(portIndex)}>
            Remove interface
          </Button>
        </GridItem>
      )}

      <EncoderSettings
        namePrefix={namePrefix}
        applianceFeatures={physicalPort.appliance.features}
        applianceSettings={physicalPort.appliance.settings}
      />
    </>
  )
}
