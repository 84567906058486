import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import Typography from '@mui/material/Typography'

import { EnrichedInputPort, EnrichedOutputPort } from '../../../api/nm-types'
import { useAppliancePhysicalPorts } from '../../../utils'
import { Api } from '../../../store'
import { Appliance, Group, IpPortMode, PhysicalPort, PortMode } from 'common/api/v1/types'
import { GridItem } from '../Form'
import { initialInputLogicalPort } from '../../inputs/Edit/InputForm'
import { initialOutputLogicalPort } from '../../outputs/Edit/OutputForm'

import {
  ApplianceSectionForm,
  areMultipleLogicalPortsPerApplianceSupported,
  collectPortsFromApplianceSections,
  Error,
  Loading,
  LogicalPortForm,
} from './Base'
import { PortDirection } from 'common/types'
import { whyIsPhysicalPortUnavailable } from '../../../utils/physicalPortUtil'
import { generatorDefaults } from '../../inputs/Edit/PortForm/IpPortForm'
import { AllowUncontrolled, FormProps } from '../Form/RHF'
import { useFieldArray } from 'react-hook-form'

interface Props {
  namePrefix: string
  isInputForm: boolean
  inputId: string | undefined
  outputId: string | undefined
  adminStatus: 0 | 1
  groupId: Group['id']
  selectedAppliance: Pick<Appliance, 'id' | 'name' | 'type'> & Partial<Pick<Appliance, 'settings'>>
  logicalPorts: Array<EnrichedInputPort | EnrichedOutputPort>
  enforcedPortMode: PortMode | undefined
  isModeSelectionDisabled: boolean
  onAddLogicalPortRequested: () => void
}

const Component = <T extends ApplianceSectionForm>(
  props: FormProps<T & AllowUncontrolled> & Props,
  ref: React.Ref<{
    onAddInterfaceButtonClicked: () => void
  }>,
) => {
  const {
    namePrefix,
    isInputForm,
    inputId,
    outputId,
    groupId,
    adminStatus,
    selectedAppliance,
    logicalPorts,
    enforcedPortMode,
    isModeSelectionDisabled,
    clearErrors,
    onAddLogicalPortRequested,
    getValues,
  } = props

  const areMultipleInterfacesSupported = areMultipleLogicalPortsPerApplianceSupported({
    isInput: isInputForm,
    appliance: selectedAppliance,
    allLogicalPorts: collectPortsFromApplianceSections(getValues()),
  })

  const {
    result: appliancePhysicalPorts,
    loading: isFetchingPhysicalPorts,
    error: listPhysicalPortsError,
  } = useAppliancePhysicalPorts(
    selectedAppliance,
    Api.appliancesApi,
    isInputForm ? PortDirection.input : PortDirection.output,
    isInputForm ? inputId : outputId,
  )

  const {
    fields: logicalPortFields,
    update: updateLogicalPortField,
    remove: removeLogicalPortField,
    ...fieldArrayProps
  } = useFieldArray({
    name: `${namePrefix}.ports`,
  })

  useImperativeHandle(ref, () => ({
    onAddInterfaceButtonClicked: () => {
      return addNewLogicalPortToForm()
    },
  }))

  // --> Begin hack that should be removed when the field order has been changed
  // from appliance -> interface -> mode to appliance -> mode -> interface
  // This ensures the loopback interface is always used for generators
  const logicalPort1 = logicalPorts[0]
  const mode = logicalPort1?.mode
  useEffect(() => {
    const isEnrichedPortLocalInterface = (p: typeof logicalPort1) => p?._port?.addresses?.[0]?.address === '127.0.0.1'
    const isLocalInterface = (p: PhysicalPort) => p.addresses?.[0]?.address === '127.0.0.1'
    const localInterface = appliancePhysicalPorts?.find(isLocalInterface)

    if (mode === IpPortMode.generator && localInterface && !isEnrichedPortLocalInterface(logicalPort1)) {
      const generatorInputPort: EnrichedInputPort = {
        ...generatorDefaults(),
        physicalPort: localInterface!.id,
        mode: IpPortMode.generator,
        address: '127.0.0.1',
        port: '' as unknown as number,
        _port: { ...localInterface, _appliance: localInterface.appliance },
      } as any
      updateLogicalPortField(0, { ...generatorInputPort, id: logicalPort1.id })
    }
  }, [updateLogicalPortField, namePrefix, logicalPort1, mode, appliancePhysicalPorts])
  // <-- End hack that should be removed

  const addNewLogicalPortToForm = () => {
    const defaultPhysicalPort: PhysicalPort | undefined =
      (logicalPort1?._port ||
        appliancePhysicalPorts?.find((physicalPort) => {
          const occupiedReason = whyIsPhysicalPortUnavailable({ physicalPort, requestee: { groupId } })
          return occupiedReason === undefined
        })) ??
      appliancePhysicalPorts?.[0]

    if (!defaultPhysicalPort) {
      return
    }
    const logicalPortInit = {
      physicalPortId: defaultPhysicalPort.id,
      port: defaultPhysicalPort,
      enforcedMode: areMultipleInterfacesSupported ? (logicalPort1.mode as IpPortMode) : undefined,
      settings: selectedAppliance.settings,
      applianceType: defaultPhysicalPort.appliance.type,
    }
    const nextIndex = logicalPorts.length
    updateLogicalPortField(
      nextIndex,
      isInputForm ? initialInputLogicalPort(logicalPortInit) : initialOutputLogicalPort(logicalPortInit),
    )
    clearErrors(`${namePrefix}.ports.${nextIndex}` as any)
  }

  useEffect(() => {
    const shouldAutoAddNewLogicalPortSection = logicalPorts.length === 0
    if (shouldAutoAddNewLogicalPortSection) {
      addNewLogicalPortToForm()
    }
  }, [appliancePhysicalPorts])

  return (
    <>
      {isFetchingPhysicalPorts && <Loading message={`Fetching interfaces...`} />}
      {listPhysicalPortsError && <Error message={`Failed fetching interfaces`} />}

      {appliancePhysicalPorts && appliancePhysicalPorts.length === 0 && (
        <GridItem newLine>
          <Typography component="div" variant="body1">
            The selected appliance has no available interfaces.
          </Typography>
        </GridItem>
      )}

      {appliancePhysicalPorts && appliancePhysicalPorts.length > 0 && (
        <LogicalPortForm
          isRegional={false}
          namePrefix={namePrefix}
          logicalPorts={logicalPorts}
          fieldArray={{
            fields: logicalPortFields,
            update: updateLogicalPortField,
            remove: removeLogicalPortField,
            ...fieldArrayProps,
          }}
          isInputForm={isInputForm}
          adminStatus={adminStatus}
          appliancePhysicalPorts={appliancePhysicalPorts}
          isModeSelectionDisabled={isModeSelectionDisabled}
          enforcedPortMode={enforcedPortMode}
          restrictPhysicalPortsToGroupId={groupId}
          onAddLogicalPortRequested={onAddLogicalPortRequested}
        />
      )}
    </>
  )
}

export const ReferencedComponent = forwardRef(Component)

export const ApplianceInterfaceSection = <T extends ApplianceSectionForm>({
  myRef,
  ...rest
}: FormProps<T> & Props & { myRef: React.Ref<{ onAddInterfaceButtonClicked: () => void }> }) => (
  <ReferencedComponent {...(rest as any)} ref={myRef} />
)
