import Wrapper from '../common/Wrapper'
import { OverlaysList } from './overlay/List'

export const ProductionPage = () => {
  return (
    <Wrapper name="Production tools">
      <OverlaysList />
    </Wrapper>
  )
}
