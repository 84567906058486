import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'

import { PIDRule, PIDRuleAction } from 'common/api/v1/types'
import { Select, TextInput } from '../../common/Form'
import Table, { TableConfig } from '../../common/Table'
import { InputFormProps } from '../Edit/InputForm'
import { MissingContent } from '../../common/MissingContent'
import { RichOption } from 'src/components/common/Form/Select'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'

const options: RichOption[] = [
  {
    name: 'Map',
    value: PIDRuleAction.Map,
  },
  {
    name: 'Delete',
    value: PIDRuleAction.Delete,
  },
  {
    name: 'Set null',
    value: PIDRuleAction.SetNull,
  },
]

export const PidMapTable = () => {
  const { getValues } = useFormContext<InputFormProps & AllowUncontrolled>()

  const pidMapRulesPrefix = 'deriveFrom.ingestTransform.pidMap.rules'

  const {
    fields: rules,
    remove: removeRule,
    append: addRule,
    update: updateRule,
  } = useFieldArray({
    name: pidMapRulesPrefix,
  })

  const config: TableConfig<PIDRule> = [
    {
      title: 'action',
      getValue: (_rule, i) => {
        return (
          <Select
            sx={{ minWidth: '100px' }}
            name={`${pidMapRulesPrefix}.${i}.action`}
            options={options}
            variant="standard"
            hiddenLabel
            required
            onChange={(val: any) => {
              updateRule(i, { ...getValues(`${pidMapRulesPrefix}.${i}`), action: val })
            }}
          />
        )
      },
    },
    {
      title: 'Source PID',
      getValue: (_rule, i) => (
        <TextInput
          type="number"
          name={`${pidMapRulesPrefix}.${i}.pid`}
          variant="standard"
          hiddenLabel
          required
          validators={{ pid: {} }}
        />
      ),
    },
    {
      title: 'Destination PID',
      getValue: (rule, i) => {
        if (rule.action !== 'map') {
          return 'N/A'
        }

        return (
          <TextInput
            type="number"
            name={`${pidMapRulesPrefix}.${i}.destPid`}
            hiddenLabel
            variant="standard"
            required
            validators={{ pid: {} }}
          />
        )
      },
    },
    {
      getValue: (_rule, i) => {
        return (
          <Tooltip title={'Remove rule'} placement="top" style={{ marginRight: 16 }}>
            <IconButton edge="end" onClick={() => removeRule(i)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )
      },
      props: { align: 'right' },
    },
  ]

  if (!getValues('_derived')) {
    return null
  }

  return (
    <Table
      config={config}
      data={rules as any as PIDRule[]}
      emptyMessageComponent={<MissingContent message="No PID mapping" />}
      actions={[
        <Button
          key="add-pid-rule-button"
          variant="contained"
          color="primary"
          onClick={() => {
            addRule({ action: PIDRuleAction.Map, pid: 0, destPid: 0 })
          }}
        >
          Add Rule
        </Button>,
      ]}
    />
  )
}
