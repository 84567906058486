import { formatDistanceToNow } from 'date-fns'
import MuiList from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

import { Appliance, Group, ListResult, PhysicalPort, Role, User } from 'common/api/v1/types'
import { Paper } from '../../common/Form'
import { Link } from '../../common/Link'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { getInterfaceName, useSettingsSelector, useUser } from '../../../utils'
import { Api, useRoutes } from '../../../store'
import { AppliancesRequestParams, PortsRequestParams, UsersRequestParams } from '../../../api/nm-types'
import ListItemButton from '@mui/material/ListItemButton'
const styles = {
  main: {
    flexGrow: 1,
  },
}

interface ListProps<T, TParams> {
  api: (params: TParams) => Promise<ListResult<T>>
  getLeftInfo: (ent: T) => string
  getRightInfo?: (ent: T) => string
  name: 'appliance' | 'interface' | 'user'
  owner: Group['id']
  canEdit: (ent: T) => boolean
}
const List = <
  T extends Appliance | PhysicalPort | User,
  TParams extends AppliancesRequestParams | UsersRequestParams | PortsRequestParams,
>({
  api,
  getLeftInfo,
  getRightInfo,
  name,
  owner,
  canEdit,
}: ListProps<T, TParams>) => {
  const routes = useRoutes()
  return (
    <Paper title={`${name[0].toUpperCase()}${name.slice(1)}s`} collapsible>
      <PaginatedList<TParams & { owner: Group['id'] }, T, Pick<TParams, 'owner'>>
        api={api}
        emptyMessage={`No ${name}s yet`}
        notFoundMessage={`No matching ${name}s`}
        otherParams={{ owner } as Pick<TParams, 'owner'>}
        hideSearch
        List={({ list }) => (
          <MuiList disablePadding sx={{ marginTop: 2, marginX: 2 }}>
            {list.map((ent) =>
              canEdit(ent) ? (
                <Link to={routes[`${name}sUpdate`]({ id: ent.id })} key={ent.id}>
                  <ListItemButton disableGutters divider>
                    <Typography sx={styles.main}>{getLeftInfo(ent)}</Typography>
                    {getRightInfo !== undefined && <Typography>{getRightInfo(ent)}</Typography>}
                  </ListItemButton>
                </Link>
              ) : (
                <ListItem disableGutters divider key={ent.id}>
                  <Typography sx={styles.main}>{getLeftInfo(ent)}</Typography>
                  {getRightInfo !== undefined && <Typography>{getRightInfo(ent)}</Typography>}
                </ListItem>
              ),
            )}
          </MuiList>
        )}
      />
    </Paper>
  )
}

const { userApi, portsApi, appliancesApi } = Api

interface ListsProps {
  groupId: Group['id']
}
const Lists = ({ groupId }: ListsProps) => {
  const currentUser = useUser()
  const { devMode } = useSettingsSelector()

  return (
    <>
      <List<User, UsersRequestParams>
        api={userApi.getUsers.bind(userApi)}
        getLeftInfo={({ username }) => username}
        getRightInfo={({ role }) => role}
        name="user"
        owner={groupId}
        canEdit={({ role, group }) =>
          currentUser.role === Role.super ||
          (currentUser.role === Role.admin && currentUser.group === group && role !== Role.super)
        }
      />
      <List<Appliance, AppliancesRequestParams>
        api={appliancesApi.getBareAppliances.bind(appliancesApi)}
        getLeftInfo={({ name }) => name}
        getRightInfo={({ lastMessageAt = '' }) =>
          lastMessageAt && `last seen ${formatDistanceToNow(new Date(lastMessageAt))} ago`
        }
        name="appliance"
        owner={groupId}
        canEdit={() => true}
      />
      {devMode ? (
        <List<PhysicalPort, PortsRequestParams>
          api={portsApi.getBarePorts.bind(portsApi)}
          name="interface"
          getLeftInfo={(port) => getInterfaceName(port)}
          owner={groupId}
          canEdit={() => false}
        />
      ) : null}
    </>
  )
}

export default Lists
