import { fetch } from '../../utils/fetch'
import { ApplicationException } from '../ApplicationException'
import {
  EnrichedUser,
  EnrichedUserWithSettings,
  ExistingUserForUpdate,
  singleSortQueryFromPaginatedRequestParams,
  UsersRequestParams,
} from '../nm-types'
import {
  Group,
  ListResult,
  LoginResult,
  NewUser,
  OidcMetadata,
  SamlMetadata,
  LoginMethod,
  User,
  UserFilter,
  UserSettings,
} from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { omit } from 'common/api/v1/helpers'

export interface IUserApi {
  createUser(user: NewUser): Promise<User>

  getUser(userId: User['id']): Promise<EnrichedUser>

  getUsers(params: UsersRequestParams): Promise<ListResult<EnrichedUser>>

  loginUser(username: string, password: string, otp?: string): Promise<EnrichedUser>

  logoutUser(): Promise<boolean>

  impersonateUser(userId: User['id']): Promise<EnrichedUserWithSettings>

  stopImpersonation(): Promise<EnrichedUserWithSettings>

  removeUser(userID: string): Promise<{ id: string }>

  updateUser(user: ExistingUserForUpdate): Promise<User>

  getLoginMethods(): Promise<LoginMethod[]>

  getSsoMetadataDataSaml(url: string): Promise<SamlMetadata>
  getSsoMetadataDataOidc(url: string): Promise<OidcMetadata>

  // /api/current-user
  getCurrentEnrichedUser(): Promise<EnrichedUserWithSettings>
  configureTOTP(): ReturnType<EdgeClient['totpCurrentUsers']>
  verifyTOTP(token: string): Promise<boolean>
  deleteTOTP(): Promise<void>

  getUserSettings(userId: User['id']): Promise<UserSettings>
  setUserSettings(userId: User['id'], settings: UserSettings): Promise<UserSettings>
}

export class UserApi implements IUserApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  async loginUser(username: string, password: string, otp?: string): Promise<EnrichedUser> {
    const res = await fetch<LoginResult>('/api/login/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password, otp }),
    })
    if (res.status === 200) {
      const result: LoginResult = res.body
      const userGroup = await this.edgeClient.getGroup(result.user.group)
      return {
        ...result.user,
        _group: omit(userGroup, 'applianceSecret'),
      }
    }
    throw new ApplicationException({
      fatal: true,
      errorCode: 'authentication_fail',
      details: 'Failed to login',
      text: 'Failed to login',
      origin: res,
    })
  }

  async logoutUser(): Promise<boolean> {
    const { success } = await this.edgeClient.logout()
    return success
  }

  async impersonateUser(userId: User['id']): Promise<EnrichedUserWithSettings> {
    const { user } = await this.edgeClient.impersonate(userId)
    const settings = await this.getUserSettings(userId)
    const userGroup = await this.edgeClient.getGroup(user.group)
    return {
      ...user,
      _group: omit(userGroup, 'applianceSecret'),
      settings,
    }
  }

  async stopImpersonation(): Promise<EnrichedUserWithSettings> {
    const { user } = await this.edgeClient.unimpersonate()
    const settings = await this.getUserSettings(user.id)
    const userGroup = await this.edgeClient.getGroup(user.group)
    return {
      ...user,
      _group: omit(userGroup, 'applianceSecret'),
      settings,
    }
  }

  createUser(user: NewUser): Promise<User> {
    return this.edgeClient.createUser(user)
  }

  removeUser(userID: string): Promise<{ id: string }> {
    return this.edgeClient.deleteUser(userID)
  }

  updateUser(user: ExistingUserForUpdate): Promise<User> {
    return this.edgeClient.updateUser(user.id, user)
  }

  getLoginMethods(): Promise<LoginMethod[]> {
    return this.edgeClient.listLoginMethods()
  }

  getSsoMetadataDataSaml(url: string): Promise<SamlMetadata> {
    return this.edgeClient.getSsoMetadataSaml({ url: url })
  }

  getSsoMetadataDataOidc(url: string): Promise<OidcMetadata> {
    return this.edgeClient.getSsoMetadataOidc({ url: url })
  }

  /**
   * Returns user with group object populated
   * @param userId
   */
  async getUser(userId: string): Promise<EnrichedUser> {
    const user = await this.edgeClient.getUser(userId)
    return {
      ...user,
      _group: await this.edgeClient.getGroup(user.group),
    }
  }

  getUserSettings(userId: User['id']): Promise<UserSettings> {
    return this.edgeClient.getUserSettings(userId)
  }

  setUserSettings(userId: User['id'], settings: UserSettings): Promise<UserSettings> {
    return this.edgeClient.setUserSettings(userId, settings)
  }

  /**
   * Returns ListResult of users with group object populated
   * @param userId
   */
  async getUsers({ owner, filter: searchName, ...params }: UsersRequestParams): Promise<ListResult<EnrichedUser>> {
    const filter: UserFilter = { group: owner, searchName }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    const { items: users, total } = await this.edgeClient.listUsers(query)

    const groupIds = users.map((user) => user.group)
    const groups = (await this.edgeClient.listGroups({ filter: { ids: Array.from(new Set(groupIds)) } })).items

    return {
      items: users.map((user) => ({
        ...user,
        _group: groups.find(({ id }) => id === user.group) as Group,
      })),
      total,
    }
  }

  async getCurrentEnrichedUser(): Promise<EnrichedUserWithSettings> {
    const currentUser = await this.edgeClient.getCurrentUser()
    const group = await this.edgeClient.getGroup(currentUser.group)
    const settings = await this.getUserSettings(currentUser.id)
    return { ...currentUser, _group: group, settings }
  }

  async configureTOTP() {
    return this.edgeClient.totpCurrentUsers()
  }

  async verifyTOTP(token: string) {
    return this.edgeClient
      .setCurrentUserTotpVerify({ token })
      .then(() => true)
      .catch(() => false)
  }

  async deleteTOTP() {
    await this.edgeClient.deleteCurrentUserTotps()
  }
}
