import React from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { ImageName } from 'common/api/v1/types'
import { FileInfo, setImage, setImageToDelete } from '../../redux/actions/settingsActions'
import { FaviconLogo, LoginLogo, TopLeftLogo } from '../common/LogoFetcher'
import { AppDispatch } from '../../store'
import { enqueueErrorSnackbar } from '../../redux/actions/notificationActions'
import { useSettingsSelector } from '../../utils'

const MAX_SIZE = 1024 * 1024

const styles = {
  toolbar: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > * + *': {
        marginLeft: 0,
        marginTop: theme.spacing(2),
      },
    },
  }),
  label: {
    flexGrow: 1,
  },
  container: {
    margin: (theme: Theme) => theme.spacing(4, 0),
    padding: (theme: Theme) => theme.spacing(2, 2),
    border: (theme: Theme) => `1px solid ${theme.palette.text.secondary}`,
    borderRadius: (theme: Theme) => theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    whiteSpace: 'nowrap',
  },
}

interface FileBase64Props {
  onSelect: (file: FileInfo) => void
  name: string
}
const FileBase64: React.FunctionComponent<FileBase64Props> = ({ onSelect, name }) => {
  const id = `${name}-file-input`
  const onChange = ({ target }: { target: { files: FileList | null } }) => {
    const file = target.files && target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const fileInfo: FileInfo = {
        name: file.name,
        type: file.type,
        size: file.size,
        base64: reader.result as string,
        file: file,
      }
      onSelect(fileInfo)
    }
  }
  return (
    <label htmlFor={id} style={{ width: '122px' }}>
      <Button sx={styles.button} color="primary" variant="contained" component="span">
        Select file
      </Button>
      <input
        type="file"
        onChange={onChange}
        multiple={false}
        id={id}
        style={{ display: 'none' }}
        accept=".jpg, .png, .jpeg, .ico, .svg"
      />
    </label>
  )
}

interface FileInputProps {
  label: string
  name: ImageName
  text?: string
  id?: string
}
const FileInput: React.FunctionComponent<FileInputProps> = ({ name, label, text = '', id }) => {
  const dispatch = useDispatch<AppDispatch>()
  const settings = useSettingsSelector()
  const image = settings[name]
  const onSelect = (file: FileInfo) => {
    if (file.size > MAX_SIZE) {
      dispatch(
        enqueueErrorSnackbar({
          error: {
            errorInfo: {
              errorCode: '',
              fatal: false,
              text: 'File size is too large',
              details: '',
              origin: undefined,
            },
          },
        }),
      )
      return
    }
    dispatch(setImage({ name, file }))
  }
  return (
    <FormControl margin="normal" fullWidth id={id}>
      <Box sx={styles.toolbar}>
        <Box sx={styles.label}>
          <Typography variant="h4">{label}</Typography>
          <Typography variant="body2" color="textSecondary">
            {`Max size is ${MAX_SIZE / 1024 / 1024} MB. ${text}`}
          </Typography>
        </Box>
        <FileBase64 name={name} onSelect={onSelect} />
        <Button
          sx={styles.button}
          variant="outlined"
          onClick={() => dispatch(setImageToDelete(name))}
          data-test-id={`${id}-reset`}
        >
          Restore default
        </Button>
      </Box>
      <Box sx={styles.container}>
        {name === ImageName.serviceProvider ? (
          <TopLeftLogo image={image?.base64 as string} isDraft />
        ) : name === ImageName.product ? (
          <LoginLogo image={image?.base64 as string} isDraft />
        ) : (
          <FaviconLogo image={image?.base64 as string} isDraft />
        )}
      </Box>
    </FormControl>
  )
}

export default FileInput
