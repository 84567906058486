import { Scte104Injection, Scte104InjectionResult } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IAdInsertionDemoApi {
  triggerAdBreak(params: Scte104Injection): Promise<Scte104InjectionResult>
}

export class AdInsertionDemoApi implements IAdInsertionDemoApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  triggerAdBreak(params: Scte104Injection): Promise<Scte104InjectionResult> {
    return this.edgeClient.createScte104Injection(params)
  }
}
