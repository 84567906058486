import { Service } from 'common/api/v1/types'
import Tooltip from '../common/Tooltip'
import MuiLink from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import OpenInNew from '@mui/icons-material/OpenInNew'

interface Link {
  href: string
  tooltip: string
}

function getProductLink(product: Service['product']): Link | undefined {
  switch (product) {
    case 'Redpanda':
      return {
        href: '/kafka',
        tooltip: 'View Redpanda Console',
      }
    case 'LoadSimulator':
      return {
        href: '/loadsim/',
        tooltip: 'View load simulator',
      }
    case 'Grafana':
      return {
        href: '/grafana',
        tooltip: 'View Grafana dashboards',
      }
    case 'MinIO':
      return {
        href: '/minio',
        tooltip: 'View MinIO Console',
      }
    default:
      return undefined
  }
}

interface Props {
  product: Service['product']
  enabled: Service['enabled']
}

export const ProductLink = ({ product, enabled }: Props) => {
  const link = getProductLink(product)
  return link ? (
    <Tooltip title={enabled ? link.tooltip : `${product} is not enabled`}>
      <MuiLink href={link.href} target="_blank">
        <IconButton edge="end" aria-label={link.tooltip} disabled={!enabled}>
          <OpenInNew />
        </IconButton>
      </MuiLink>
    </Tooltip>
  ) : null
}
