import { tryConvertDates } from 'common/serialization'
import type { RequestResult } from 'common/network/request-options'
import store from '../store'
import { ApplicationException } from '../api/ApplicationException'
import { clearState } from '../localstorage'

export const fetch = async <T>(...args: Parameters<typeof window.fetch>): Promise<RequestResult<T>> => {
  const response = await window.fetch(...args)
  const contentType = response.headers.get('content-type')
  const body = contentType?.includes('application/json')
    ? tryConvertDates(await response.json())
    : await response.text()

  if (!response.ok) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    if (response.status === 401 && !store.getState().userReducer.changingUser) {
      clearState()
      document.cookie = 'edgetoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
      if (
        // Response from login request
        response.url !== '/api/login/' &&
        // Already logged out
        window.location.hash !== '#/'
      ) {
        window.location.assign('/')
      }
    }

    const defaultTitle = `Unexpected status code: ${response.status}`

    throw new ApplicationException({
      fatal: response.status !== 409,
      errorCode: `${response.status}`,
      text: body?.title || defaultTitle,
      details: body?.detail || body?.title || response.statusText,
      origin: '',
    })
  }

  return {
    status: response.status,
    headers: Object.fromEntries(response.headers.entries()),
    body,
  }
}
