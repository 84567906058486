import { createSlice } from '@reduxjs/toolkit'
import { KubernetesNode, KubernetesNodeResourceUsage, ListResultError } from 'common/api/v1/types'
import type { ApplicationException } from '../../api/ApplicationException'
import { logoutUser } from '../actions/userActions'
import {
  clearKubernetesNode,
  clearKubernetesNodes,
  getKubernetesNode,
  getKubernetesNodes,
  updateKubernetesNode,
  getResourceUsage,
} from '../actions/k8sActions'
import { createLoadingReducer } from './shared'

interface State {
  kubernetesNodes: Array<KubernetesNode>
  kubernetesNode: KubernetesNode | undefined
  resourceUsage: KubernetesNodeResourceUsage[]
  formErrors?: ApplicationException['errorInfo']['details'] // unused
  loading: boolean
  loadingResourceUsage: boolean
  saving: boolean
  total: number
  errors?: ListResultError[]
}

const initialStateNodes: State = {
  kubernetesNodes: [],
  kubernetesNode: undefined,
  resourceUsage: [],
  loading: false,
  loadingResourceUsage: false,
  saving: false,
  total: 0,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(getKubernetesNodes, getKubernetesNode)

const nodesSlice = createSlice({
  name: 'kubernetesNodes',
  initialState: initialStateNodes,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getKubernetesNodes.fulfilled,
        (state, { payload: { items: kubernetesNodes, total, errors } }): State => ({
          ...state,
          kubernetesNodes: kubernetesNodes,
          total,
          errors,
        }),
      )

      .addCase(
        getKubernetesNode.fulfilled,
        (state, { payload: kubernetesNode }): State => ({
          ...state,
          kubernetesNode,
        }),
      )

      .addCase(
        updateKubernetesNode.fulfilled,
        (state, { payload: kubernetesNode }): State => ({
          ...state,
          kubernetesNode,
          formErrors: undefined,
        }),
      )

      .addCase(clearKubernetesNode, (state): State => ({ ...state, kubernetesNode: undefined, formErrors: undefined }))
      .addCase(clearKubernetesNodes, (state): State => ({ ...state, kubernetesNodes: [], formErrors: undefined }))

      .addCase(logoutUser.fulfilled, (): State => initialStateNodes)

      .addCase(getResourceUsage.pending, (state): State => ({ ...state, loadingResourceUsage: true }))
      .addCase(getResourceUsage.rejected, (state): State => ({ ...state, loadingResourceUsage: false }))
      .addCase(
        getResourceUsage.fulfilled,
        (state, { payload: resourceUsage }): State => ({
          ...state,
          resourceUsage,
          loadingResourceUsage: false,
        }),
      )
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default nodesSlice.reducer
