import { TranscodeAcceleratorType } from './api/v1/types'
import { FfmpegStats } from './types'

/// Tags populated and sent from edge-data
export interface TranscodeStreamTags {
    inputId: string
    inputName: string
}

/// Stats populated and sent from edge-data
export interface TranscodeStreamStats extends FfmpegStats {}

export const transcodeAcceleratorTypeDisplayName = (type: TranscodeAcceleratorType): string => {
    switch (type) {
        case TranscodeAcceleratorType.nvidia:
            return 'NVIDIA NVENC'
        case TranscodeAcceleratorType.netint:
            return 'NETINT Quadra'
        case TranscodeAcceleratorType.xilinx_u30:
            return 'Xilinx U30'
        case TranscodeAcceleratorType.videotoolbox:
            return 'Apple VideoToolbox'
        case TranscodeAcceleratorType.cpu:
            return 'CPU'
        default:
            return type
    }
}
