import { createAsyncThunk } from '@reduxjs/toolkit'
import type { ThunkApi } from '../../store'
import { LOCAL_STORAGE_TAGS_HISTORY_KEY } from '../../localstorage'

const HISTORY_LEN = 10

export const updateSearchHistory = createAsyncThunk<string[], string | undefined | null, ThunkApi>(
  'tags/updateSearchHistory',
  async (tags) => {
    const history: string[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TAGS_HISTORY_KEY) ?? '[]')
    if (!tags || history.some((h) => h === tags)) return history

    const newHistory = [...history, tags].slice(-HISTORY_LEN)
    localStorage.setItem(LOCAL_STORAGE_TAGS_HISTORY_KEY, JSON.stringify(newHistory))
    return newHistory
  },
)

export const deleteSearchHistory = createAsyncThunk<string[], string | undefined | null, ThunkApi>(
  'tags/deleteSearchHistory',
  async (tags) => {
    const history: string[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TAGS_HISTORY_KEY) ?? '[]')
    if (!tags) return history

    const newHistory = history.filter((h) => h !== tags)
    localStorage.setItem(LOCAL_STORAGE_TAGS_HISTORY_KEY, JSON.stringify(newHistory))
    return newHistory
  },
)
