import { createSlice } from '@reduxjs/toolkit'
import { Network } from 'common/api/v1/types'
import { logoutUser } from '../actions/userActions'
import { isOneOf } from '../actions'
import {
  clearNetwork,
  clearNetworks,
  createNetwork,
  getNetwork,
  getNetworks,
  removeNetwork,
  updateNetwork,
} from '../actions/networkActions'
import type { ApplicationException } from '../../api/ApplicationException'
import { createLoadingReducer } from './shared'

interface State {
  formErrors?: ApplicationException['errorInfo']['details']
  loading: boolean
  network?: Network
  networks: Array<Network>
  saving?: boolean
  total: number
}
export const initialStateNetworks: State = {
  networks: [],
  loading: false,
  total: 0,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(removeNetwork, getNetworks)

const networksSlice = createSlice({
  name: 'networks',
  initialState: initialStateNetworks,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNetwork.fulfilled, (state, { payload: network }): State => ({ ...state, network }))
      .addCase(logoutUser.fulfilled, (): State => initialStateNetworks)
      .addCase(clearNetwork, (state): State => ({ ...state, network: undefined, formErrors: undefined }))
      .addCase(clearNetworks, (state): State => ({ ...state, networks: [] }))
      .addCase(
        getNetworks.fulfilled,
        (state, { payload: { items: networks, total } }): State => ({
          ...state,
          networks,
          total,
        }),
      )
      .addMatcher(
        isOneOf([updateNetwork.pending, createNetwork.pending]),
        (state): State => ({ ...state, saving: true }),
      )
      .addMatcher(
        isOneOf([createNetwork.fulfilled, updateNetwork.fulfilled]),
        (state): State => ({
          ...state,
          saving: undefined,
          formErrors: undefined,
        }),
      )
      .addMatcher(
        isOneOf([updateNetwork.rejected, createNetwork.rejected]),
        (state, { payload }): State => ({
          ...state,
          saving: false,
          formErrors: payload?.details,
        }),
      )
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default networksSlice.reducer
