import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { createHashRouter, RouterProvider } from 'react-router'
import '@fontsource/nunito-sans'
import '@fontsource/roboto'
import '@fontsource/roboto-mono'

import './index.css'
import App from './App'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { storeWithRoutes, RouteContext } from './store'

const container = document.getElementById('root')
const root = createRoot(container!)
const router = createHashRouter([{ path: '*', element: <App /> }])

root.render(
  <StrictMode>
    <Provider store={storeWithRoutes.store}>
      <RouteContext.Provider value={{ routes: storeWithRoutes.routes }}>
        <DndProvider backend={HTML5Backend}>
          <RouterProvider router={router} />
        </DndProvider>
      </RouteContext.Provider>
    </Provider>
  </StrictMode>,
)
