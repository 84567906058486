import {
  ApplianceSettings,
  ApplianceType,
  MatroxD4ApplianceSettings,
  MatroxE4ApplianceSettings,
  MatroxS1ApplianceSettings,
} from 'common/api/v1/types'
import { Checkbox } from '../../../../common/Form'
import MatroxE4SettingsComp from './MatroxE4SettingsComp'
import MatroxD4SettingsComp from './MatroxD4SettingsComp'
import MatroxS1SettingsComp from './MatroxS1SettingsComp'

interface Props {
  devMode: boolean
  type: ApplianceType
  settings: ApplianceSettings | undefined
}

const ApplianceSpecificSettings = ({ type, settings }: Props) => {
  return (
    <>
      <Checkbox
        label={'Use dynamic tunnel client source address (0.0.0.0)'}
        name={`settings.useDynamicTunnelClientSourceAddress`}
        tooltip="Enabling this setting will ensure that new tunnels are created with a source address of 0.0.0.0. Enable this on appliances where you want to use multiple NICs for the video traffic."
      />

      {[ApplianceType.matroxMonarchEdgeD4].includes(type) && (
        <MatroxD4SettingsComp settings={settings as MatroxD4ApplianceSettings} />
      )}
      {[ApplianceType.matroxMonarchEdgeS1].includes(type) && (
        <MatroxS1SettingsComp settings={settings as MatroxS1ApplianceSettings} />
      )}
      {[ApplianceType.matroxMonarchEdgeE4_8Bit, ApplianceType.matroxMonarchEdgeE4_10Bit].includes(type) && (
        <MatroxE4SettingsComp
          settings={settings as MatroxE4ApplianceSettings}
          applianceType={type as ApplianceType.matroxMonarchEdgeE4_8Bit | ApplianceType.matroxMonarchEdgeE4_10Bit}
        />
      )}
    </>
  )
}

export default ApplianceSpecificSettings
