import Badge, { BadgeProps } from '@mui/material/Badge'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

type IProps = {
  alarms: { hasCritical: boolean; total: number }
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    transform: 'unset',
    color: theme.palette.grey[900],
  },
  '& .MuiBadge-colorPrimary': {
    background: theme.palette.warning.main,
  },
  '& .MuiBadge-colorError': {
    background: theme.palette.error.main,
  },
}))

const AlarmItemNameWithActiveAlarms = ({ alarms: { hasCritical, total } }: IProps) => {
  const badgeColor = hasCritical ? 'error' : 'primary'

  return (
    <Box sx={styles.container}>
      Alarms
      {total > 0 && <StyledBadge overlap="rectangular" color={badgeColor} badgeContent={total} max={1e9} />}
    </Box>
  )
}

export default AlarmItemNameWithActiveAlarms
