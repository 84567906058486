import { Checkbox, GridItem, Paper, Select } from '../../../../common/Form'
import {
  ApplianceType,
  MatroxDecoderFramerateFamily,
  MatroxDecoderGenlockSource,
  MatroxE4ApplianceSettings,
  MatroxEncoderGenlockResolution,
  MatroxEncoderIOConfig,
} from 'common/api/v1/types'
import { useFormContext } from 'react-hook-form'
import MuiCheckbox from '@mui/material/Checkbox'
import { getNumberOfInputSdiPortsFromIOConfig } from 'common/matrox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useEffect } from 'react'

interface Props {
  applianceType: ApplianceType.matroxMonarchEdgeE4_8Bit | ApplianceType.matroxMonarchEdgeE4_10Bit
  settings: MatroxE4ApplianceSettings
}

const resolutionOptions = Object.values(MatroxEncoderGenlockResolution)
const ioConfigOptions = Object.values(MatroxEncoderIOConfig)

// From Matrox: Currently in 2.0.9 there is a limitation for the E4 decoder genlock in 3:1 and 2:2 modes
// where it will only be an output physically on the connector, so it will not work in external mode
const e4DecoderGenlockSourceOptions = [MatroxDecoderGenlockSource.internal]
const decoderGenlockFramerateFamilyOptions = Object.values(MatroxDecoderFramerateFamily)

const MatroxE4SettingsComp = ({ settings, applianceType }: Props) => {
  const isRunningMatroxV2 = false // FIXME MG: Uncomment when we wish to support matrox v2 features: settings.apiVersion === 'v2'
  const is10BitE4 = applianceType === ApplianceType.matroxMonarchEdgeE4_10Bit
  const { setValue, watch } = useFormContext()

  const ioConfigKey = 'settings.ioConfig'
  watch(ioConfigKey) // Changing the ioConfig recalculates the numberOfInputSdiPorts

  const selectIOConfig = (value: MatroxEncoderIOConfig) => {
    setValue(ioConfigKey, value)
  }

  const handlePcmModeClick = (portIndex: number, checked: boolean) => {
    const currentValue = settings.sdiPortsWithPcmModeEnabled ?? []
    const newValue = checked ? [...currentValue, portIndex].sort() : currentValue.filter((i) => i !== portIndex)
    setValue(`settings.sdiPortsWithPcmModeEnabled`, newValue, { shouldValidate: true })
  }

  useEffect(() => {
    function setDefaultIoConfig() {
      if (!settings.ioConfig) {
        setValue(ioConfigKey, MatroxEncoderIOConfig.fourZero)
      }
    }
    setDefaultIoConfig()
  }, [])

  const numberOfInputSdiPorts = getNumberOfInputSdiPortsFromIOConfig(settings.ioConfig)
  const pcmModeCheckboxes = [0, 1, 2, 3].map((portIndex) => {
    const isPcmModeEnabled = settings.sdiPortsWithPcmModeEnabled?.includes(portIndex) ?? false
    return (
      <Checkbox
        xs={2}
        lg={2}
        xl={2}
        key={`SDI-${portIndex}`}
        label={`SDI-${portIndex + 1}`}
        control={
          <MuiCheckbox
            checked={isPcmModeEnabled}
            disabled={portIndex >= numberOfInputSdiPorts}
            onChange={(_, checked) => handlePcmModeClick(portIndex, checked)}
          />
        }
      />
    )
  })

  return (
    <Paper title="Matrox E4 Settings">
      <Paper title="Encoder Genlock" className="outlined">
        <Checkbox label={'Enabled'} name={`settings.genlock.isEnabled`} />
        <Select
          label="Resolution"
          name={`settings.genlock.resolution`}
          required
          disabled={!settings.genlock.isEnabled}
          options={resolutionOptions}
        />
      </Paper>

      {isRunningMatroxV2 && is10BitE4 && (
        <Paper title="Decoder Genlock">
          <Select
            label="Source"
            name={`settings.decoderGenlock.source`}
            required
            options={e4DecoderGenlockSourceOptions}
          />
          <Select
            label="Framerate Family"
            name={`settings.decoderGenlock.framerateFamily`}
            required
            options={decoderGenlockFramerateFamilyOptions}
          />
        </Paper>
      )}

      {isRunningMatroxV2 && is10BitE4 && (
        <Paper
          title="I/O Configuration"
          className="outlined"
          tooltip={
            <div>
              Determines how the SDI connectors are configured:
              <br /> 4:0: 4 SDI inputs, 0 SDI outputs
              <br /> 3:1: 3 SDI inputs, 1 SDI output
              <br /> 2:2: 2 SDI inputs, 2 SDI outputs
            </div>
          }
        >
          <GridItem>
            <RadioGroup
              row
              value={settings.ioConfig ?? MatroxEncoderIOConfig.fourZero}
              onChange={(e) => selectIOConfig(e.target.value as MatroxEncoderIOConfig)}
            >
              {ioConfigOptions.map((option) => (
                <FormControlLabel key={option} value={option} control={<Radio />} label={option.toString()} />
              ))}
            </RadioGroup>
          </GridItem>
        </Paper>
      )}

      {isRunningMatroxV2 && (
        <Paper
          title="Input SDI PCM Mode"
          className="outlined"
          tooltip={
            <div>
              Enables raw audio capture. When enabled:
              <br /> - all processors using the SDI port will be handling PCM audio
              <br /> - it is not possible to mix AAC and PCM at the same time
              <br /> - only MPEG-2 TS outputs and SRT outputs are allowed, outputs of other types will not work
            </div>
          }
        >
          {pcmModeCheckboxes}
        </Paper>
      )}
    </Paper>
  )
}

export default MatroxE4SettingsComp
