import { useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'

import { Input, Output, OutputRecipientList } from 'common/api/v1/types'
import { useOutputsSelector, usePageParamsFilteredSelector, useSettingsSelector, useUser } from '../../../utils'

import { MissingContent } from '../../common/MissingContent'
import Wrapper from '../../common/Wrapper'
import { Link } from '../../common/Link'
import Table from '../../common/Table'

import getConfig from './tableConfig'

import { SelectOutputListDialog } from './SelectOutputListDialog'
import { updateOutputList } from '../../../redux/actions/outputListsActions'
import { SelectInputWithConfirmationDialog } from '../SelectInputDialog'
import { EnrichedOutput } from '../../../api/nm-types'
import {
  registerOutputObserver,
  setInputOfOutput,
  unregisterOutputObserver,
} from '../../../redux/actions/outputsActions'
import DeleteDialog from './DeleteDialog'
import CommonActions from './CommonActions'
import { AppDispatch } from 'src/store'

import { useRoutes } from '../../../store'

export enum OutputListDialog {
  selectInput,
  addToOutputList,
}

function dialogReducer(
  state: { output?: Output; dialog?: OutputListDialog; isOpen: boolean },
  action: { type: OutputListDialog; output: Output } | { type: 'close' },
) {
  if (action.type === 'close')
    return {
      ...state,
      isOpen: false,
    }
  return {
    isOpen: true,
    output: action.output,
    type: action.type,
  }
}

export const List = () => {
  const routes = useRoutes()
  const navigate = useNavigate()
  const { outputs, loading, total } = usePageParamsFilteredSelector(useOutputsSelector)

  const outputIds = outputs.map((o) => o.id)
  useEffect(() => {
    dispatch(registerOutputObserver({ outputIds }))
    return () => {
      dispatch(unregisterOutputObserver({ outputIds }))
    }
  }, [JSON.stringify(outputIds)])

  const user = useUser()
  const dispatch = useDispatch<AppDispatch>()
  const [selected, setSelected] = useState<Array<Output['id']>>([])
  useEffect(() => {
    setSelected([])
  }, [outputs.map((i) => i.id).join(',')])
  const [dialog, dispatchDialogAction] = useReducer(dialogReducer, { isOpen: false })
  const { settings } = useSettingsSelector()
  const experimentalFeatures = settings?.expFeatures

  const closeDialog = () => dispatchDialogAction({ type: 'close' })
  const showDialog = (output: Output, type: OutputListDialog) => dispatchDialogAction({ type, output })

  const handleSelect = (id: Output['id']) => {
    if (selected.includes(id)) {
      setSelected([...selected.filter((item) => item !== id)])
    } else {
      setSelected([...selected, id])
    }
  }

  const handleSelectAll = () => {
    if (selected.length === outputs.length) {
      setSelected([])
    } else {
      setSelected(outputs.reduce<Array<Output['id']>>((acc, { id }) => acc.concat(id), []))
    }
  }

  const onOutputListSelect = (output: Output, outputList: OutputRecipientList) => {
    dispatch(
      updateOutputList({
        outputList: {
          ...outputList,
          addOutputs: [output.id],
          removeOutputs: [],
        },
        withRedirection: false,
      }),
    )
  }

  const onInputSelected = (output: Output, input: Input) => {
    // EDGE-2774: Use 'setInputOfOutput' instead of 'updateOutput' since it generates a more detailed audit-log message
    dispatch(setInputOfOutput({ output, inputId: input.id }))
  }

  const onClearInput = (output: EnrichedOutput) => dispatch(setInputOfOutput({ output, inputId: undefined }))

  const isDialogOpen = (type: OutputListDialog) => dialog.isOpen && dialog.type === type

  return (
    <Wrapper
      name="Outputs"
      actions={
        <>
          <Link type="button" to={routes.outputLists()}>
            <Button variant="outlined" color="primary">
              Manage output lists
            </Button>
          </Link>
          <Link type="button" to={routes.outputsNew()}>
            <Button style={{ marginLeft: 8 }} id="outputs-table-create-button" variant="contained" color="primary">
              Create output
            </Button>
          </Link>
        </>
      }
    >
      <CommonActions outputs={outputs} selected={selected} setSelected={setSelected} />
      <Table<Output>
        id="outputs-table"
        emptyMessageComponent={
          <MissingContent message="No outputs available" buttonText="Create output" url={routes.outputsNew()} />
        }
        config={getConfig({
          dispatch,
          routes,
          outputs,
          experimentalFeatures,
          selected,
          handleSelect,
          handleSelectAll,
          showDialog,
          user,
        })}
        rowProps={({ id, name }) => {
          const isItemSelected = Boolean(id && selected.includes(id))
          return {
            onDoubleClick: () => navigate(routes.outputsUpdate({ id })),
            'aria-checked': isItemSelected,
            tabIndex: -1,
            selected: isItemSelected,
            'data-output-name': name,
          }
        }}
        data={outputs}
        pagination={{ useUrlSearchParams: true, total }}
        pending={loading}
      />

      {dialog.output && (
        <SelectInputWithConfirmationDialog
          show={isDialogOpen(OutputListDialog.selectInput)}
          output={dialog.output}
          closeDialog={closeDialog}
          onInputSelect={onInputSelected}
          onClearInput={onClearInput}
        />
      )}
      {dialog.output && (
        <SelectOutputListDialog
          output={dialog.output}
          show={isDialogOpen(OutputListDialog.addToOutputList)}
          onListSelect={onOutputListSelect}
          closeDialog={closeDialog}
        />
      )}
      <DeleteDialog setSelected={setSelected} />
    </Wrapper>
  )
}
