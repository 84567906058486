import type { ListResult, Service, ServiceFilter, ServiceUpdate } from 'common/api/v1/types'
import { singleSortQueryFromPaginatedRequestParams, ServicesRequestParams } from '../nm-types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IServiceApi {
  getServices(params: ServicesRequestParams): Promise<ListResult<Service>>
  getService(id: string): Promise<Service>
  updateService(service: ServiceUpdate): Promise<Service>
}

export class ServiceApi implements IServiceApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  updateService(service: ServiceUpdate): Promise<Service> {
    return this.edgeClient.updateService(service.id, service)
  }

  /**
   * Returns service
   * @param serviceId
   */
  async getService(serviceId: string): Promise<Service> {
    return this.edgeClient.getService(serviceId)
  }

  async getServices({
    filter: searchName,
    includeHidden,
    ...params
  }: ServicesRequestParams): Promise<ListResult<Service>> {
    const filter: ServiceFilter = { searchName, includeHidden }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    const { items, total } = await this.edgeClient.listServices(query)

    return {
      items,
      total,
    }
  }
}
