import type { EdgeApiHttpError } from 'common/errors'

export interface ErrorInfo {
  errorCode: string
  fatal: boolean
  text: string
  details: EdgeApiHttpError['detail']
  origin: any
}

export class ApplicationException {
  constructor(public errorInfo: ErrorInfo) {}
}

function stringValueFromObject(key: string, obj: any): string | undefined {
  const value = obj[key as keyof typeof obj]
  return typeof value === 'string' && value ? value : undefined
}

export function makeApplicationException(error: unknown): ApplicationException {
  return error instanceof ApplicationException
    ? error
    : new ApplicationException({
        fatal: true,
        errorCode: 'http_get_general_error',
        text: stringValueFromObject('title', error) ?? stringValueFromObject('name', error) ?? 'Unable to fetch',
        details:
          stringValueFromObject('detail', error) ?? stringValueFromObject('message', error) ?? 'Http Request Failed',
        origin: (error as any).response,
      })
}
