import { useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { useRegionsSelector, withDefaultPagination } from '../../utils'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '../common/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MuiSelect from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { ClearIcon } from '@mui/x-date-pickers'
import Divider from '@mui/material/Divider'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import codeTheme from '../../themes/code'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { EnrichedGroup, EnrichedUser, GroupsRequestParams } from '../../api/nm-types'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { ListResult, Role } from 'common/api/v1/types'
import { getGroup, getGroups } from '../../redux/actions/groupActions'

export const AddEdgeConnectDialog = ({
  open,
  onClose,
  user,
}: {
  open: boolean
  onClose: () => void
  user: EnrichedUser
}) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const [showCopied, setShowCopied] = useState<boolean>(false)
  const [groups, setGroups] = useState<EnrichedGroup[]>([])

  const fetchAllGroups = async () => {
    return dispatch(getGroups({ pageNumber: '0', rowsPerPage: '1000' } as GroupsRequestParams)).then((res) => {
      return (res.payload as ListResult<EnrichedGroup>).items as EnrichedGroup[]
    })
  }

  const fetchUserGroup = async () => {
    return dispatch(getGroup(user.group)).then((res) => res.payload as EnrichedGroup)
  }

  useEffect(() => {
    fetchUserGroup().then((group) => {
      setCopyCommandGroup(group.name)
      // Only superusers can see appliance secrets in all groups
      if (user.role === Role.super) {
        fetchAllGroups().then((groups) => {
          setGroups(groups)
        })
      } else {
        setGroups([group])
      }
    })
  }, [])

  const regionSel = useRegionsSelector(
    withDefaultPagination({
      pageNumber: '0',
      rowsPerPage: '1000',
    }),
  )
  const [copyCommandGroup, setCopyCommandGroup] = useState<string>('system')
  const [copyCommandName, setCopyCommandName] = useState<string>('edge-connect')
  const [copyCommandRegion, setCopyCommandRegion] = useState<string>('')

  const copyCommand = () => {
    if (!groups) return undefined

    const matchedGroup = groups.filter((group) => group.name === copyCommandGroup)
    if (matchedGroup.length === 0) return undefined

    const secret = matchedGroup[0].applianceSecret
    if (!secret) return undefined

    return (
      `curl --output connectit "${window.location.origin}/connectit"
chmod +x connectit
sudo SECRET="${secret}" ./connectit install edge-connect \\
  --url "${window.location.origin}" \\
  --name "${copyCommandName}"` +
      (copyCommandRegion
        ? ` \\
  --region "${copyCommandRegion}"`
        : '')
    )
  }

  const onCopy = () => {
    const cmd = copyCommand()
    if (!cmd) return

    void navigator.clipboard.writeText(cmd)

    setShowCopied(true)
    setTimeout(() => {
      setShowCopied(false)
    }, 1500)
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 3,
            mb: 1,
            width: 'auto',
            maxWidth: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">Start an Edge Connect</Typography>

            <Tooltip
              title={
                <>
                  <Typography variant="inherit">
                    Run this command on a Linux machine to install Edge Connect.
                  </Typography>
                  <br />
                  <Typography variant="inherit">Docker must be installed before running the command.</Typography>
                </>
              }
              sx={{ ml: 1, mr: 1, scale: 1.1 }}
            >
              <HelpOutlineIcon />
            </Tooltip>

            {/* name, only alphanum, non-empty */}
            <TextField
              variant="outlined"
              size="small"
              label="Name"
              value={copyCommandName}
              error={copyCommandName.length === 0}
              onChange={(e) => setCopyCommandName(e.target.value.replace(/[^a-zA-Z0-9_.-]/g, ''))}
              sx={{ width: '150px', ml: 2 }}
            />
            {/* group */}
            {groups.length > 1 && (
              <FormControl variant="outlined" size="small" sx={{ minWidth: '100px', maxWidth: '300px', ml: 2 }}>
                <InputLabel>Group</InputLabel>
                <MuiSelect label="Group" value={copyCommandGroup} onChange={(e) => setCopyCommandGroup(e.target.value)}>
                  {groups.map((group) => (
                    <MenuItem key={group.id} value={group.name}>
                      {group.name}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
            )}
            {/* region */}
            <FormControl variant="outlined" size="small" sx={{ minWidth: '170px', ml: 2 }}>
              <InputLabel>Region (Optional)</InputLabel>
              <MuiSelect
                label="Region (Optional)"
                value={copyCommandRegion}
                onChange={(e) => setCopyCommandRegion(e.target.value)}
                endAdornment={
                  copyCommandRegion.length > 0 && (
                    <InputAdornment sx={{ marginRight: '10px' }} position="end">
                      <IconButton
                        sx={{ scale: 0.8 }}
                        onClick={() => {
                          setCopyCommandRegion('')
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {regionSel.regions.map((region) => (
                  <MenuItem key={region.id} value={region.name}>
                    {region.name}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </Box>
          <Divider sx={{ borderStyle: 'none' }} />
          <Box sx={{ position: 'relative', display: 'inline-block', maxWidth: '100%' }}>
            {navigator.clipboard && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'absolute',
                  mt: 2,
                  mr: 1,
                  top: 4,
                  right: 4,
                  zIndex: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    mr: 1,
                    opacity: showCopied ? 1 : 0,
                    visibility: showCopied ? 'visible' : 'hidden',
                    transition: 'opacity 0.3s ease, visibility 0.3s ease',
                  }}
                >
                  Copied!
                </Typography>
                <IconButton onClick={onCopy}>
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            )}

            <SyntaxHighlighter
              language="bash"
              style={codeTheme}
              codeTagProps={{
                style: { backgroundColor: 'transparent' }, // Background color behind the text
              }}
              customStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                border: '1px solid ' + theme.palette.divider,
                width: 'auto',
              }}
            >
              {copyCommand() || 'Loading...\n\n\n'}
            </SyntaxHighlighter>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
