import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Event from '@mui/icons-material/Event'
import Close from '@mui/icons-material/Close'
import { ListOutputSortableField } from 'common/api/v1/types'
import { useRoutes, type AppDispatch, type GlobalState } from '../../store'
import { deleteSearchHistory } from '../../redux/actions/tagsAction'
import { useSettingsSelector } from '../../utils/hooks'
import { Sidebar, type SidebarProps } from '../common/SidebarMenu/index'

const showHover = { opacity: 0, '&:hover, .MuiStack-root:hover &': { opacity: 1 } }

export const EMSidebar = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { searchHistory } = useSelector(({ tagsReducer }: GlobalState) => tagsReducer, shallowEqual)
  const routes = useRoutes()
  const { settings } = useSettingsSelector()

  const handleDeleteSearchHistory = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tags: string) => {
    e.preventDefault()
    dispatch(deleteSearchHistory(tags))
  }

  const defaultOrder = settings?.expFeatures?.ExtHealthAlarms ? ListOutputSortableField.healthStatus : undefined // 'healthStatus' for Input/Output
  const menuItems: SidebarProps['menuItems'] = () => [
    {
      name: 'Events',
      icon: <Event />,
      children: searchHistory
        .map((tags) => ({
          name: (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {tags}
              <Tooltip title="Remove from search history">
                <IconButton
                  size="small"
                  onClick={(ev) => handleDeleteSearchHistory(ev, tags)}
                  sx={showHover}
                  aria-label="Delete history"
                >
                  <Close fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          ),
          url: routes.events({ tags, desc: defaultOrder }),
        }))
        .toReversed(),
    },
  ]

  return (
    <Sidebar
      logoLink={routes.events()}
      title="Event Manager"
      menuItems={menuItems}
      profileUrl={routes.eventsProfile()}
    />
  )
}
