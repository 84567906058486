import { changeErrorSnackbarPosition, toggleSidebarMenu } from '../actions/uiActions'
import { logoutUser } from '../actions/userActions'
import { createSlice } from '@reduxjs/toolkit'
import { LOCAL_STORAGE_UI_SNACKBAR_ERROR_POSITION_KEY, type UISnackbarErrorPosition } from '../../localstorage'

type State = {
  open?: boolean
  errorSnackbarPosition: UISnackbarErrorPosition
}
export const initialStateUi: State = {
  errorSnackbarPosition:
    (localStorage.getItem(LOCAL_STORAGE_UI_SNACKBAR_ERROR_POSITION_KEY) as UISnackbarErrorPosition) ?? 'bottom-right',
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialStateUi,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        toggleSidebarMenu,
        (state, { payload: isOpen }): State => ({
          ...state,
          open: isOpen ?? !state.open,
        }),
      )
      .addCase(
        changeErrorSnackbarPosition.fulfilled,
        (state, { payload }): State => ({
          ...state,
          errorSnackbarPosition: payload,
        }),
      )
      .addCase(logoutUser.fulfilled, (): State => initialStateUi)
  },
})

export default uiSlice.reducer
