import type { BackupInit, ExternalS3BackupConfig, GlobalSettings } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface ISettingsApi {
  getSettings(): Promise<GlobalSettings>
  saveSettings(params: GlobalSettings): Promise<GlobalSettings>
  createBackupAsync(params: BackupInit): Promise<void>
}

export class SettingsApi implements ISettingsApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  getSettings(): Promise<GlobalSettings> {
    return this.edgeClient.getGlobalsettings()
  }
  saveSettings(params: GlobalSettings): Promise<GlobalSettings> {
    return this.edgeClient.setGlobalsettings(params)
  }

  async createBackupAsync(params: BackupInit) {
    await this.edgeClient.createBackup(params)
  }

  async testS3Write(params: ExternalS3BackupConfig) {
    await this.edgeClient.testBackupExternalS3s(params)
  }
}
