import { FC, forwardRef } from 'react'
import { Controller } from 'react-hook-form'
import MenuItem from '@mui/material/MenuItem'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import GridItem, { GridItemProps, omitGridProps } from './GridItem'
import { validate } from '../../../utils'
import Tooltip from '../Tooltip'
import omit from 'lodash/omit'

const Comp = forwardRef(
  ({ field, fieldState, ...props }: { field: any; fieldState: any } & TextFieldProps & SelectProps, ref) => {
    const showError = fieldState.invalid
    const fieldError = fieldState.error ? fieldState.error.message : ''
    const muiProps: TextFieldProps & { ref: any } = {
      error: showError,
      helperText: showError ? fieldError : props.helperText,
      ...props,
      ...field,
      ref,
    }

    const hasEmpty = !!(props.options || []).find((option) => typeof option === 'object' && option.value === '')
    const selectProps = {
      displayEmpty: hasEmpty,
    }
    const labelProps = {
      shrink: hasEmpty || undefined,
    }

    return (
      <TextField
        {...muiProps}
        defaultValue=""
        slotProps={{
          select: selectProps,
          inputLabel: labelProps,
        }}
      />
    )
  },
)

export interface RichOption {
  name: string
  value?: string | number
  disabled?: boolean
  tooltip?: string | React.ReactNode
  hoverTooltip?: string
}

type Option = RichOption | string | number

interface SelectProps extends GridItemProps {
  name?: string
  label?: string
  options?: Array<Option>
  required?: boolean
  disabled?: boolean
  validators?: Parameters<typeof validate>[0]
  variant?: TextFieldProps['variant']
  hiddenLabel?: boolean
  sx?: TextFieldProps['sx']
  onChange?: (value: string | number) => void
}

/**
 * Simple select component using React Hook Form
 * @param props {
 *   name?: field name
 *   label?: label to show
 *   validators?: validation rules for the field
 *   required?: adds validation
 *   options?: array of string, numbers or complex objects with name, value, disabled state and tooltip
 *   onChange?: callback function to handle selection changes
 * }
 */
const Select: FC<SelectProps> = (props) => {
  const { validators = {}, variant = 'outlined', hiddenLabel = false, sx } = props

  if (props.required) validators.presence = 'This field is required'

  return (
    <GridItem {...props}>
      <Controller
        name={props.name ?? ''}
        rules={{ validate: validate(validators) }}
        render={({ field: { ref, value, onChange, ...field }, fieldState }) => {
          // Propagate the onChange both to RHF and the parent component
          const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
            const selectedValue = event.target.value as string | number
            onChange(selectedValue)
            props.onChange?.(selectedValue)
          }

          return (
            <Comp
              inputRef={ref}
              field={{ ...field, value, onChange: handleChange }}
              fieldState={fieldState}
              select
              label={hiddenLabel ? undefined : props.label}
              margin="normal"
              fullWidth
              variant={variant}
              hiddenLabel={hiddenLabel}
              data-test-id={`select-${props.name ?? props.label}`}
              sx={sx}
              {...{
                ...omitGridProps(omit(props, 'onChange')),
                options: props.options?.map((o) => (typeof o === 'object' && 'name' in o ? o.name : o)),
              }}
            >
              {props.options !== undefined &&
                props.options.map((option: Option) => {
                  const opt = typeof option !== 'object' ? ({ name: option } as RichOption) : option
                  const tooltipStyle = opt.hoverTooltip ? 'hover' : opt.tooltip ? 'inline' : 'none'
                  const body = (
                    <span
                      style={{
                        fontStyle: 'value' in opt && opt.value === undefined ? 'italic' : undefined,
                        marginRight: 5,
                      }}
                    >
                      {opt.name}
                    </span>
                  )
                  if (tooltipStyle === 'hover') {
                    body.props.style.width = '100%'
                  }
                  return (
                    <MenuItem
                      value={'value' in opt ? opt.value : opt.name}
                      key={opt.name}
                      disabled={Boolean(opt.disabled)}
                    >
                      {tooltipStyle === 'hover' && <Tooltip title={opt.hoverTooltip}>{body}</Tooltip>}
                      {tooltipStyle !== 'hover' && body}
                      {tooltipStyle === 'inline' && typeof opt.tooltip === 'string' && (
                        <Typography variant="body2" component="span">
                          &nbsp;({opt.tooltip})
                        </Typography>
                      )}
                      {tooltipStyle === 'inline' && typeof opt.tooltip === 'object' && opt.tooltip}
                    </MenuItem>
                  )
                })}
            </Comp>
          )
        }}
      />
    </GridItem>
  )
}

export default Select
