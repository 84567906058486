import { usePageParams } from '../../utils'
import Wrapper from '../common/Wrapper'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, GlobalState } from '../../store'
import { equals } from 'common/util'
import { compareAsc, format } from 'date-fns'
import { AdAuditLogMessage } from 'common/types'
import Table, { TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import { getAdAudit } from '../../redux/actions/adAuditActions'
import { DATE_FORMAT_LONGER } from 'common/api/v1/helpers'
import Typography from '@mui/material/Typography'
import { UrlParamFilteredSearchBar } from '../common/Filters/FilterView/FilteredSearchBar'
import Toolbar from '@mui/material/Toolbar'
import {
  AdAuditLogFilterKey,
  makeAdAuditLogFilters,
  mapAdAuditLogFilterToUrlParam,
  mapUrlParamToAdAuditLogFilter,
} from './demo/adAuditLogFilter'
import { GridItem, Paper } from '../common/Form'

export const AdAuditLog = () => {
  const [pageParams] = usePageParams()
  const { fromDate, toDate } = pageParams

  const minDate = fromDate ? new Date(fromDate) : new Date('2025-01-01')
  const maxDate = toDate ? new Date(toDate) : new Date()
  const validDateRange = compareAsc(minDate, maxDate) === -1

  const dispatch = useDispatch<AppDispatch>()
  const refreshAdAuditLogs = async () => {
    dispatch(getAdAudit(pageParams))
  }

  useEffect(() => {
    refreshAdAuditLogs()
  }, [pageParams])

  return (
    <Wrapper name={['Ad Insertion', 'Ad Audit Log']} fullWidth>
      <AdAuditListFilter />

      {validDateRange ? (
        <AdAuditListTable />
      ) : (
        <Paper>
          <GridItem lg={12} xl={12}>
            <Typography variant="h2" margin={'auto'}>
              Invalid date range
            </Typography>
          </GridItem>
        </Paper>
      )}
    </Wrapper>
  )
}

const AdAuditListFilter = () => {
  const filters = makeAdAuditLogFilters()
  return (
    <>
      <Toolbar disableGutters>
        <div style={{ flexShrink: 1, flexGrow: 1 }}>
          <UrlParamFilteredSearchBar
            urlParamCacheKey={'adAuditLog'}
            mapUrlParamToFilterFn={mapUrlParamToAdAuditLogFilter}
            mapFilterToUrlParamFn={mapAdAuditLogFilterToUrlParam}
            searchTokenFilters={filters}
            rawTextFilter={filters.find((f) => f.key === AdAuditLogFilterKey.any)!}
          />
        </div>
      </Toolbar>
    </>
  )
}

const AdAuditListTable = () => {
  const { adAudit, total, loading } = useSelector(
    ({ adAuditReducer }: GlobalState) => ({
      adAudit: adAuditReducer.adAudit,
      total: adAuditReducer.total,
      loading: adAuditReducer.loading,
    }),
    equals,
  )

  const [pageParams] = usePageParams()
  const { pageNumber } = pageParams

  const formatEntity = (entity: any) => {
    try {
      return JSON.stringify(JSON.parse(entity), null, 2)
    } catch (_) {
      return entity
    }
  }

  const tableConfig: TableConfig<AdAuditLogMessage> = [
    {
      title: 'Entity Type',
      getValue: ({ entityType }) => (
        <Typography whiteSpace={'nowrap'} display={'flex'} flex={1} gap={'12px'}>
          {entityType}
        </Typography>
      ),
      props: {
        padding: 'normal',
      },
    },
    {
      title: 'method',
      getValue: ({ method }) => <Typography whiteSpace={'nowrap'}>{method}</Typography>,
    },
    {
      title: 'status',
      getValue: ({ status }) => <Typography whiteSpace={'nowrap'}>{status}</Typography>,
    },
    {
      title: 'result',
      getValue: ({ result }) => <Typography whiteSpace={'nowrap'}>{result}</Typography>,
    },
    {
      title: 'entity',
      getValue: ({ entity }) => <pre style={{ fontSize: '.85rem' }}>{formatEntity(entity)}</pre>,
    },
    {
      title: 'component',
      getValue: ({ component }) => <Typography whiteSpace={'nowrap'}>{component}</Typography>,
    },
    {
      title: 'entity name',
      getValue: ({ entityName }) => <Typography whiteSpace={'nowrap'}>{entityName}</Typography>,
    },
    {
      title: 'entity id',
      getValue: ({ entityId }) => <Typography whiteSpace={'nowrap'}>{entityId}</Typography>,
    },
    {
      title: 'message',
      getValue: ({ message }) => <Typography whiteSpace={'nowrap'}>{message}</Typography>,
    },
    {
      title: 'event id',
      getValue: ({ eventId }) => <Typography whiteSpace={'nowrap'}>{eventId}</Typography>,
    },
    {
      title: 'output id',
      getValue: ({ outputId }) => <Typography whiteSpace={'nowrap'}>{outputId}</Typography>,
    },
    {
      title: 'stream id',
      getValue: ({ streamId }) => <Typography whiteSpace={'nowrap'}>{streamId}</Typography>,
    },
    {
      title: 'channel id',
      getValue: ({ channelId }) => <Typography whiteSpace={'nowrap'}>{channelId}</Typography>,
    },
    {
      title: 'pod',
      getValue: ({ pod }) => <Typography whiteSpace={'nowrap'}>{pod}</Typography>,
    },
    {
      title: 'source',
      getValue: ({ source }) => <Typography whiteSpace={'nowrap'}>{source}</Typography>,
    },
    {
      title: 'target',
      getValue: ({ target }) => <Typography whiteSpace={'nowrap'}>{target}</Typography>,
    },
    {
      title: 'trace id',
      getValue: ({ traceId }) => <Typography whiteSpace={'nowrap'}>{traceId}</Typography>,
    },
    {
      title: 'time',
      getValue: ({ timestamp }) => (
        <Typography whiteSpace={'nowrap'}>{timestamp ? format(timestamp, DATE_FORMAT_LONGER) : null}</Typography>
      ),
      props: {
        padding: 'normal',
      },
    },
  ]

  return (
    <Table<AdAuditLogMessage>
      emptyMessageComponent={<MissingContent message="No logs available for the interval" />}
      config={tableConfig}
      data={adAudit.map((item, idx) => ({ ...item, id: `${pageNumber || 0}_${idx}` }))}
      pending={loading}
      pagination={{ total, useUrlSearchParams: true }}
      isSmall
    />
  )
}
