import { useInputsSelector } from '../../../utils'
import { Preview } from '../../common/Preview'
import Wrapper from '../../common/Wrapper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Fragment, useEffect, useRef, useState, useCallback } from 'react'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined'
import ButtonGroup from '@mui/material/ButtonGroup'
import HideImageIcon from '@mui/icons-material/HideImage'
import ImageIcon from '@mui/icons-material/Image'
import { Timeline, TimelineOptions } from 'vis-timeline'
import 'vis-timeline/dist/vis-timeline-graph2d.min.css'
import './visjs-overrides.css'
import Grid from '@mui/material/Grid2'
import { AdInsertionStage, initialStages } from './initialAdStages'
import ArcadeButton from './ArcadeButton'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, GlobalState, Api } from '../../../store'
import { triggerAdBreak } from '../../../redux/actions/adInsertionDemoActions'
import { Appliance, Output, Scte104InjectionResult } from 'common/api/v1/types'
import { equals } from 'common/util'
import { getAdAudit } from '../../../redux/actions/adAuditActions'
import { AdAuditLogMessage } from 'common/types'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'
import SettingsIcon from '@mui/icons-material/Settings'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import { getLogger } from '../../../logger'
import { GridItem } from '../../common/Form'
import Autocomplete from '@mui/material/Autocomplete'
import { COOKIE_APPLIANCE_PROXY } from 'common/constants'
import Typography from '@mui/material/Typography'
export const DemoPage = () => {
  const { inputs } = useInputsSelector({
    pageNumber: '0',
    rowsPerPage: '2',
    tags: 'ad-insertion-demo',
  })

  const log = getLogger('adInsertionDemo')

  const options: TimelineOptions = {
    width: '100%',
    height: '500px',
    showMajorLabels: true,
    showCurrentTime: true,
    zoomable: false,
    zoomMax: 30000,
    zoomMin: 30000,
    type: 'box',
    format: {
      minorLabels: {
        second: 'ss',
      },
    },
    start: Date.now() - 30000,
    end: Date.now() + 30000,
    rollingMode: { follow: true, offset: 0.5 },
  }

  interface TimelineItem {
    id: number
    content: string
    start: number
    end: number
    type?: string
    style?: string
  }

  const dispatch = useDispatch<AppDispatch>()

  // State
  const [previewEnabled, setPreviewEnabled] = useState<boolean>(false)
  const [settingsExpanded, setSettingsExpanded] = useState<boolean>(false)
  const [items, setItems] = useState<TimelineItem[]>([])
  const [stages, setStages] = useState<AdInsertionStage[]>(initialStages)
  const [eventId, setEventId] = useState<number | undefined>(undefined)
  const [intervalRef, setIntervalRef] = useState<any>()
  const [adAuditSse, setAdAuditSse] = useState<AdAuditLogMessage[]>([])
  const [eventSourceRef, setEventSourceRef] = useState<EventSource | undefined>()
  const [triggered, setTriggered] = useState<boolean>(false)
  const [disableArcadeButton, setDisableArcadeButton] = useState<boolean>(false)

  // Settings
  const [connectionMode, setConnectionMode] = useState<'sse' | 'polling' | undefined>()
  const [durationMs, setDurationMs] = useState<number | undefined>()
  const [timeShiftMs, setTimeShiftMs] = useState<number | undefined>()
  const [preRollMs, setPreRollMs] = useState<number | undefined>()
  const [postInsertionMs, setPostInsertionMs] = useState<number | undefined>()
  const [applianceId, setApplianceId] = useState<string | undefined>()
  const [outputId, setOutputId] = useState<string | undefined>()

  // Load settings from local storage on mount
  useEffect(() => {
    const savedConnectionMode = localStorage.getItem('adInsertionDemo_connectionMode')
    const savedDurationMs = localStorage.getItem('adInsertionDemo_durationMs')
    const savedTimeShiftMs = localStorage.getItem('adInsertionDemo_timeShiftMs')
    const savedPreRollMs = localStorage.getItem('adInsertionDemo_preRollMs')
    const savedPostInsertionMs = localStorage.getItem('adInsertionDemo_postInsertionMs')
    const savedApplianceId = localStorage.getItem('adInsertionDemo_applianceId')
    const savedOutputId = localStorage.getItem('adInsertionDemo_outputId')

    if (savedConnectionMode) setConnectionMode(savedConnectionMode as 'sse' | 'polling')
    else setConnectionMode('sse')
    if (savedDurationMs) setDurationMs(Number(savedDurationMs))
    else setDurationMs(30000)
    if (savedTimeShiftMs) setTimeShiftMs(Number(savedTimeShiftMs))
    else setTimeShiftMs(1000)
    if (savedPreRollMs) setPreRollMs(Number(savedPreRollMs))
    else setPreRollMs(10000)
    if (savedPostInsertionMs) setPostInsertionMs(Number(savedPostInsertionMs))
    else setPostInsertionMs(5000)
    if (savedApplianceId) setApplianceId(savedApplianceId)
    if (savedOutputId) setOutputId(savedOutputId)
  }, [])

  // Save settings to local storage when they change
  useEffect(() => {
    if (connectionMode) localStorage.setItem('adInsertionDemo_connectionMode', connectionMode)
    if (durationMs) localStorage.setItem('adInsertionDemo_durationMs', durationMs.toString())
    if (timeShiftMs) localStorage.setItem('adInsertionDemo_timeShiftMs', timeShiftMs.toString())
    if (preRollMs) localStorage.setItem('adInsertionDemo_preRollMs', preRollMs.toString())
    if (postInsertionMs) localStorage.setItem('adInsertionDemo_postInsertionMs', postInsertionMs.toString())
    if (applianceId) localStorage.setItem('adInsertionDemo_applianceId', applianceId)
    if (outputId) localStorage.setItem('adInsertionDemo_outputId', outputId)

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        handleExternalTrigger()
        handleArcadeButton()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionMode, durationMs, preRollMs, postInsertionMs, applianceId, outputId, triggered])

  // Set mode to polling if appliance type is Videon
  useEffect(() => {
    if (!applianceId) return

    Api.appliancesApi.getAppliance(applianceId).then((result) => {
      if (result?.type === 'videon') {
        setConnectionMode('polling')
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applianceId, connectionMode])

  const { adAudit } = useSelector(
    ({ adAuditReducer }: GlobalState) => ({
      adAudit: adAuditReducer.adAudit,
    }),
    equals,
  )

  const calculateStageTime = (stage: AdInsertionStage, index: number) => {
    if (stage.stage === 'request' && eventId) {
      return `Event ID: ${eventId}`
    }

    if (!stage.endTime) return
    if (stage.hideTime) return

    if (stage.previousStage) {
      const previousStage = stages.find((s) => s.stage === stage.previousStage)
      if (!previousStage?.endTime) return

      return `${stage.endTime - previousStage.endTime} ms`
    }

    if (!(index > 0 && stages[index - 1].endTime)) return

    return `${stage.endTime - stages[index - 1].endTime!} ms`
  }

  const renderStage = (stage: AdInsertionStage, index: number) => {
    const renderStageIcon = (stage: AdInsertionStage): JSX.Element => {
      let status = stage.status
      if (stage.stage === 'request' && !eventId) status = undefined

      if (status === 'done') return <CheckIcon sx={{ height: 30, width: 30 }} />
      if (status === 'progress') return <CircularProgress size={30} thickness={6} />
      return <HourglassEmptyOutlinedIcon sx={{ height: 30, width: 30 }} />
    }

    const opacity = stage.status === 'progress' ? 1 : 0.6

    return (
      <Fragment key={stage.stage}>
        <ListItem style={{ opacity }}>
          <ListItemIcon>{renderStageIcon(stage)}</ListItemIcon>
          <ListItemText primary={stage.displayName} secondary={calculateStageTime(stage, index)} />
        </ListItem>
      </Fragment>
    )
  }

  const timelineRef = useRef(null)
  useEffect(() => {
    if (!timelineRef.current) return

    const timeline = new Timeline(timelineRef.current, items, options)

    return () => {
      timeline.destroy()
    }
  }, [items, options])

  const setupSse = (eventId: number): Promise<EventSource> => {
    return new Promise((resolve) => {
      log('Setting up SSE for event ID', eventId)

      const queryParams = {
        filter: {
          eventId,
          outputId,
        },
      }
      const path = `/api/ad-audit-log/sse?q=${JSON.stringify(queryParams)}`

      const eventSource = new EventSource(path)

      eventSource.onopen = () => {
        log('SSE connection established for event ID', eventId)
        setEventSourceRef(eventSource)
        resolve(eventSource)
      }

      eventSource.onerror = (error) => {
        log('SSE error for event ID', eventId, error)
        resolve(eventSource)
      }

      eventSource.onmessage = (event) => {
        log('SSE message for event ID', eventId, event.data)
        const newAuditMessage = JSON.parse(event.data) as AdAuditLogMessage & { sentAt: Date }
        setAdAuditSse((prev) => [...prev, newAuditMessage])
      }
    })
  }

  const handleArcadeButton = async () => {
    if (!(applianceId && preRollMs && postInsertionMs && durationMs)) return
    if (triggered) return

    document.cookie = `${COOKIE_APPLIANCE_PROXY}=${applianceId};path=/api/scte104-injection`

    const proposedEventId = Math.floor(Math.random() * 100000)
    log('Triggering ad break', proposedEventId)
    if (connectionMode === 'sse') setEventId(proposedEventId)

    // Reset state
    setAdAuditSse([])
    setItems([])
    setStages(initialStages)
    setDisableArcadeButton(true)

    // SSE is set up prior to triggering ad break so that we don't miss any logs
    if (connectionMode === 'sse') {
      await setupSse(proposedEventId)
    }

    dispatch(
      triggerAdBreak({
        eventId: proposedEventId,
        applianceId,
        preRollMs,
        durationMs,
      }),
    )
      .then((result) => {
        const returnedEventId = (result.payload as Scte104InjectionResult).eventId
        log('Ad break triggered', returnedEventId)
        setEventId(returnedEventId)
        setTriggered(true)
        setTimeout(() => {
          setDisableArcadeButton(false)
          setTriggered(false)
        }, preRollMs + durationMs + postInsertionMs)
      })
      .catch((error) => {
        log('Error triggering ad break', error)
        setTriggered(false)
        setDisableArcadeButton(false)
      })
  }

  useEffect(() => {
    if (!(applianceId && preRollMs && durationMs && timeShiftMs)) return

    if (triggered && eventId) {
      setItems([
        ...items,
        {
          id: Math.random(),
          content: 'Pre roll',
          start: Date.now(),
          end: Date.now() + preRollMs + timeShiftMs,
          type: 'background',
          style: 'background-color: rgba(77,80,90,0.5)',
        },
        {
          id: Math.random(),
          content: 'Ad break',
          start: Date.now() + preRollMs + timeShiftMs,
          end: Date.now() + preRollMs + durationMs + timeShiftMs,
          type: 'background',
        },
      ])

      log('Starting ad inseriton demo cycle')
      if (connectionMode === 'polling') {
        log('Polling mode set')
        if (intervalRef) clearInterval(intervalRef)

        const now = Date.now()
        const adBreakEnd = now + preRollMs + durationMs + (postInsertionMs || 0) + timeShiftMs

        const interval = setInterval(() => {
          if (Date.now() > adBreakEnd) {
            clearInterval(interval)
            return
          }

          dispatch(getAdAudit({ eventId: eventId.toString(), outputId, fromDate: new Date(now).toISOString() }))
        }, 50)

        setIntervalRef(interval)
      }
    } else {
      log('Ad insertion demo cycle finished')

      if (intervalRef) clearInterval(intervalRef)
      if (eventSourceRef) eventSourceRef.close()
      dispatch(getAdAudit({ eventId: '0' })) // clear ad audit
      setStages(initialStages)
    }
  }, [triggered])

  // Handle new ad audit logs arrived
  useEffect(() => {
    if (!(connectionMode === 'sse' ? adAuditSse : adAudit)) return
    if (!triggered) return

    // Check if any of the stage criteria are met
    const newItems: TimelineItem[] = []
    const updatedStages: AdInsertionStage[] = stages.map((stage) => {
      if (stage.status === 'done') return stage

      const updatedStage = { ...stage }

      if (stage.doneCriteria && stage.doneCriteria.length > 0) {
        let finishTime: number | undefined

        const isStageDone = stage.doneCriteria.every((criterion) => {
          return (connectionMode === 'sse' ? adAuditSse : adAudit).some((log) => {
            finishTime = new Date(log.timestamp).getTime()

            let entityCheck = true
            if (criterion.entityName) {
              entityCheck = log.entityName === criterion.entityName
            }

            return (
              log.entityType === criterion.entity &&
              log.method === criterion.method &&
              log.status === criterion.status &&
              entityCheck
            )
          })
        })

        if (isStageDone) {
          updatedStage.status = 'done'

          if (finishTime) {
            updatedStage.endTime = finishTime

            newItems.push({
              id: Math.random(),
              content: updatedStage.displayName,
              start: finishTime,
              end: finishTime,
            })
          }
        }
      } else if (stage.progressCriteria && stage.progressCriteria.length > 0) {
        const isStageProgress = stage.progressCriteria.every((criterion) => {
          return (connectionMode === 'sse' ? adAuditSse : adAudit).some((log) => {
            return (
              log.entityType === criterion.entity && log.method === criterion.method && log.status === criterion.status
            )
          })
        })

        if (isStageProgress) {
          updatedStage.status = 'progress'
        }
      }

      return updatedStage
    })

    // ensure that all stages before a done stage are also done
    const doneIndex = updatedStages.findLastIndex((stage) => stage.status === 'done')
    if (doneIndex > -1) {
      updatedStages.forEach((stage, index) => {
        if (index < doneIndex && stage.status !== 'done') {
          updatedStages[index] = { ...stage, status: 'done' }
        }
      })
    }

    setItems([...items, ...newItems])
    setStages(updatedStages)
  }, [adAuditSse, adAudit])

  const triggerAnimationRef = useRef<() => void>()

  const handleExternalTrigger = () => {
    if (triggered) return

    if (triggerAnimationRef.current) {
      triggerAnimationRef.current()
    }
  }

  return (
    <Wrapper name="Ad Insertion">
      <Grid container spacing={2}>
        <Grid size={{ lg: 12, xl: 12 }} sx={{ my: 1 }}>
          <ButtonGroup>
            {inputs.length > 0 && (
              <Button
                variant="outlined"
                startIcon={previewEnabled ? <HideImageIcon /> : <ImageIcon />}
                onClick={() => setPreviewEnabled(!previewEnabled)}
              >
                {`${previewEnabled ? 'Hide' : 'Show'} streams`}
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => setSettingsExpanded(!settingsExpanded)}
              startIcon={settingsExpanded ? <CloseIcon /> : <SettingsIcon />}
            >
              {`${settingsExpanded ? 'Hide' : 'Show'} settings`}
            </Button>
          </ButtonGroup>
        </Grid>

        {settingsExpanded && (
          <Grid size={{ lg: 12, xl: 12 }} sx={{ my: 1 }}>
            <GridItem>
              <FormControl sx={{ mr: 2 }} fullWidth>
                <ScteInserterApplianceAutocomplete
                  value={applianceId}
                  onChange={(id: string) => setApplianceId(id)}
                  fetchAppliances={async () => {
                    const appliancesResult = await Api.appliancesApi.listAppliances({
                      filter: {
                        capabilities: {
                          scteInserter: true,
                        },
                      },
                    })
                    return appliancesResult.items
                  }}
                />
              </FormControl>
              <FormControl sx={{ mr: 2 }} fullWidth>
                <OutputAutoComplete
                  value={outputId}
                  onChange={(id: string) => setOutputId(id)}
                  fetchOutputs={async () => {
                    const outputsResult = await Api.outputApi.getOutputs({ pageNumber: '0', rowsPerPage: '50' })
                    return outputsResult.items
                  }}
                />
              </FormControl>

              <FormControl sx={{ mr: 2 }} fullWidth>
                <InputLabel id="connection-mode-label">Connection Mode</InputLabel>
                <Select
                  value={connectionMode}
                  onChange={(event) => setConnectionMode(event.target.value as 'sse' | 'polling')}
                  displayEmpty
                  label="Connection Mode"
                  labelId="connection-mode-label"
                >
                  <MenuItem value="polling">Polling</MenuItem>
                  <MenuItem value="sse">SSE</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="Pre-roll time (ms)"
                value={preRollMs || ''}
                onChange={(e) => setPreRollMs(parseInt(e.target.value))}
                sx={{ mr: 2 }}
                fullWidth
                error={!preRollMs}
              />

              <TextField
                label="Post insertion time (ms)"
                value={postInsertionMs || ''}
                onChange={(e) => setPostInsertionMs(parseInt(e.target.value))}
                sx={{ mr: 2 }}
                fullWidth
                error={!postInsertionMs}
              />

              <TextField
                label="Time shift (ms)"
                value={timeShiftMs || ''}
                onChange={(e) => setTimeShiftMs(parseInt(e.target.value))}
                sx={{ mr: 2 }}
                fullWidth
                error={!timeShiftMs}
              />

              <TextField
                label="Ad break duration (ms)"
                value={durationMs || ''}
                onChange={(e) => setDurationMs(parseInt(e.target.value))}
                sx={{ mr: 2 }}
                fullWidth
                error={!durationMs}
              />
            </GridItem>
          </Grid>
        )}
        <Grid size={{ lg: 12, xl: 12 }} sx={{ my: 1 }}>
          {previewEnabled &&
            inputs.map((input) => (
              <Preview
                key={input.id}
                inputId={input.id}
                isOnDemand={false}
                isAudioOnly={false}
                width={'50%'}
                height="auto"
              />
            ))}
        </Grid>

        <Grid size={{ lg: 9, xl: 8 }}>
          <Box display="flex" flexDirection="column" alignItems="center" width={'100%'} gap={10}>
            <Box width={'100%'}>
              <div ref={timelineRef} />
            </Box>

            <ArcadeButton
              onClick={handleArcadeButton}
              size={200}
              triggerExternalAnimation={(trigger) => (triggerAnimationRef.current = trigger)}
              disabled={!(applianceId && outputId) || disableArcadeButton}
            />
            {!(applianceId && outputId) && (
              <Typography color="red" variant="h3">
                Select an appliance and output in settings to start the demo
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid size={{ lg: 3, xl: 4 }}>
          <List>{stages.map((stage, index) => renderStage(stage, index))}</List>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

const ScteInserterApplianceAutocomplete = ({
  value,
  onChange,
  fetchAppliances,
}: {
  value: string | undefined
  onChange: (applianceId: string) => void
  fetchAppliances: () => Promise<Appliance[]>
}) => {
  const [open, setOpen] = useState(false)
  const [appliances, setAppliances] = useState<readonly Appliance[]>([])
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')

  // Load appliances initially and when dropdown opens
  const loadAppliances = useCallback(async () => {
    if (appliances.length === 0) {
      setLoading(true)
      try {
        const fetchedAppliances = await fetchAppliances()
        setAppliances(fetchedAppliances)
      } finally {
        setLoading(false)
      }
    }
  }, [fetchAppliances, appliances.length])

  useEffect(() => {
    loadAppliances()
  }, [loadAppliances])

  const handleOpen = () => {
    setOpen(true)
    loadAppliances()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const selectedAppliance = appliances.find((appliance) => appliance.id === value) || null

  return (
    <Autocomplete<Appliance, false, false, false>
      open={open}
      value={selectedAppliance}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={(_event, newValue) => {
        onChange(newValue?.id || '')
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option?.name || ''}
      options={appliances}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="SCTE Inserter Appliance"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

const OutputAutoComplete = ({
  value,
  onChange,
  fetchOutputs,
}: {
  value: string | undefined
  onChange: (outputId: string) => void
  fetchOutputs: () => Promise<Output[]>
}) => {
  const [open, setOpen] = useState(false)
  const [outputs, setOutputs] = useState<readonly Output[]>([])
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')

  // Load outputs initially and when dropdown opens
  const loadOutputs = useCallback(async () => {
    if (outputs.length === 0) {
      setLoading(true)
      try {
        const fetchedOutputs = await fetchOutputs()
        setOutputs(fetchedOutputs)
      } finally {
        setLoading(false)
      }
    }
  }, [fetchOutputs, outputs.length])

  useEffect(() => {
    loadOutputs()
  }, [loadOutputs])

  const handleOpen = () => {
    setOpen(true)
    loadOutputs()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const selectedOutput = outputs.find((output) => output.id === value) || null

  return (
    <Autocomplete<Output, false, false, false>
      open={open}
      value={selectedOutput}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={(_event, newValue) => {
        onChange(newValue?.id || '')
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option?.name || ''}
      options={outputs}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Output"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
