import { createSlice } from '@reduxjs/toolkit'
import { Group } from 'common/api/v1/types'
import {
  clearGroup,
  clearGroups,
  createGroup,
  getGroup,
  getGroups,
  updateGroup,
  removeGroup,
} from '../actions/groupActions'

import { logoutUser } from '../actions/userActions'
import { EnrichedGroup } from '../../api/nm-types'
import type { ApplicationException } from '../../api/ApplicationException'
import { isOneOf } from '../actions'
import { createLoadingReducer } from './shared'

interface State {
  formErrors?: ApplicationException['errorInfo']['details']
  loading: boolean
  group?: Group
  groups: Array<EnrichedGroup>
  saving?: boolean
  total: number
}
export const initialStateGroups: State = {
  groups: [],
  loading: false,
  total: 0,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(removeGroup, getGroups)

const groupsSlice = createSlice({
  name: 'groups',
  initialState: initialStateGroups,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGroup.fulfilled, (state, { payload: group }): State => ({ ...state, group }))
      .addCase(clearGroup, (state): State => ({ ...state, group: undefined, formErrors: undefined }))
      .addCase(clearGroups, (state): State => ({ ...state, groups: [] }))
      .addCase(logoutUser.fulfilled, (): State => initialStateGroups)
      .addCase(
        getGroups.fulfilled,
        (state, { payload: { items: groups, total } }): State => ({
          ...state,
          groups,
          total,
        }),
      )
      .addMatcher(isOneOf([updateGroup.pending, createGroup.pending]), (state): State => ({ ...state, saving: true }))
      .addMatcher(
        isOneOf([createGroup.fulfilled, updateGroup.fulfilled]),
        (state): State => ({
          ...state,
          saving: undefined,
          formErrors: undefined,
        }),
      )
      .addMatcher(
        isOneOf([updateGroup.rejected, createGroup.rejected]),
        (state, { payload }): State => ({
          ...state,
          saving: false,
          formErrors: payload?.details,
        }),
      )
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default groupsSlice.reducer
