import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import merge from 'lodash/merge'

import { clearKubernetesNode, getKubernetesNode, updateKubernetesNode } from '../../../redux/actions/k8sActions'
import { AppDispatch, GlobalState } from '../../../store'
import { Form, RHF, ButtonsPane, Checkbox, Paper, SafeRouting } from '../../common/Form'
import { FormProps } from '../../common/Form/RHF'
import Wrapper from '../../common/Wrapper'
import Pendable from '../../common/Pendable'
import { formTransform, useSettingsSelector, useUser } from '../../../utils'
import { KubernetesNode, Role, ExternalRegionMode } from 'common/api/v1/types'
import { KubernetesRoles } from 'common/k8s/labels'
import Meta from './meta'
import MuiCheckbox from '@mui/material/Checkbox'

import { useRoutes } from '../../../store'

interface RoleTypes {
  roleCore: boolean
  roleVideo: boolean
  roleVideoStandby: boolean
  roleThumb: boolean
  roleEdgeConnect: boolean
  roleLoadSimulator: boolean
}

const getInitialState = (n?: KubernetesNode): KubernetesNode & RoleTypes =>
  merge(
    {
      name: '',
      status: '',
      externalIP: '',
      internalIP: '',
      hostname: '',
      roles: [],
      roleCore: n?.roles.includes(KubernetesRoles.core) || false,
      roleVideo: n?.roles.includes(KubernetesRoles.video) || false,
      roleVideoStandby: n?.roles.includes(KubernetesRoles.video_standby) || false,
      roleThumb: n?.roles.includes(KubernetesRoles.thumb) || false,
      roleEdgeConnect: n?.roles.includes(KubernetesRoles.edge_connect) || false,
      roleLoadSimulator: n?.roles.includes(KubernetesRoles.load_simulator) || false,
      kubeletVersion: '',
      region: {
        id: '',
        name: '',
        external: ExternalRegionMode.externalK8s,
      },
    },
    n,
  )
export const Edit = () => {
  const { regionId, name } = useParams<{ regionId: string; name: KubernetesNode['name'] }>()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getKubernetesNode({ regionId: regionId!, name: name! }))
    return () => {
      dispatch(clearKubernetesNode())
    }
  }, [dispatch, name, regionId])
  const { node, loading } = useSelector(
    ({ k8sReducer }: GlobalState) => ({
      node: k8sReducer.kubernetesNode,
      loading: k8sReducer.loading,
    }),
    shallowEqual,
  )

  const user = useUser()
  if (user.role !== Role.super) {
    navigate(-1)
    return null
  }

  const onSubmit = (kubernetesNode: KubernetesNode) => {
    dispatch(
      updateKubernetesNode({
        regionId: kubernetesNode.region.id,
        name: kubernetesNode.name,
        roles: kubernetesNode.roles,
      }),
    )
  }

  return (
    <Wrapper name={['Kubernetes nodes', node?.name]}>
      <Pendable pending={loading}>
        <RHF
          onSubmit={(values) => {
            onSubmit(formTransform(values))
          }}
          defaultValues={getInitialState(node)}
          component={(props) => (
            <KubernetesNodeForm {...props} isExternalNode={node?.region.external !== ExternalRegionMode.core} />
          )}
        />
      </Pendable>
    </Wrapper>
  )
}

const KubernetesNodeForm = ({
  getValues,
  setError,
  setFocus,
  formState,
  setValue,
  watch,
  isExternalNode,
}: FormProps<KubernetesNode & RoleTypes> & { isExternalNode: boolean }) => {
  const navigate = useNavigate()
  const routes = useRoutes()
  const values = getValues()
  const currentRoles = watch('roles')

  const { saving, formErrors } = useSelector(
    ({ k8sReducer }: GlobalState) => ({
      saving: k8sReducer.saving,
      formErrors: k8sReducer.formErrors,
    }),
    shallowEqual,
  )
  const { devMode } = useSettingsSelector()

  useEffect(() => {
    if (Array.isArray(formErrors)) {
      formErrors.forEach((item) => {
        setError(item.name as keyof typeof values, { type: 'manual', message: item.reason })
      })
      if (formErrors[0]) setFocus(formErrors[0].name as keyof typeof values)
    }
  }, [formErrors, setError, setFocus])

  const onCheckboxChange = (role: KubernetesRoles) => {
    const roleIndex = currentRoles.indexOf(role)
    if (roleIndex !== -1) {
      currentRoles.splice(roleIndex, 1)
    }
    if (roleIndex === -1) {
      currentRoles.push(role)
    }
    setValue('roles', Array.from(currentRoles.values()), { shouldValidate: true, shouldDirty: true })
  }

  const isChecked = (role: KubernetesRoles) => currentRoles.includes(role)

  return (
    <>
      <SafeRouting formState={formState} />
      <Form id="node-form" noValidate>
        <Meta node={values} />
        <Paper title="Node roles">
          <Checkbox
            control={
              <MuiCheckbox
                checked={isChecked(KubernetesRoles.core)}
                onChange={() => onCheckboxChange(KubernetesRoles.core)}
              />
            }
            label="Core"
            tooltip="The core role determines which worker nodes run the API and all the supporting central services and databases. This is the most memory-intensive role and requires a minimum of 16GB for production deployments. It is also the most storage-intensive role both in volume and IOPS."
            disabled={isExternalNode}
          />
          <Checkbox
            control={
              <MuiCheckbox
                checked={isChecked(KubernetesRoles.video)}
                onChange={() => onCheckboxChange(KubernetesRoles.video)}
              />
            }
            label="Video"
            tooltip="The video role is given to the worker nodes that should distribute video. This is the most bandwidth-intensive role and it requires a public ip (public as in reachable from outside the cluster) available. For each worker node with the video node there should be a public ip mapping that specifies how external appliances can reach the video node."
          />
          <Checkbox
            control={
              <MuiCheckbox
                checked={isChecked(KubernetesRoles.video_standby)}
                onChange={() => onCheckboxChange(KubernetesRoles.video_standby)}
              />
            }
            label="Video Standby"
            tooltip="The video_standby role is a specialization of the video role where no workload is scheduled unless some video worker node is unavailable. This can be used to ensure auto-recovery in case a video worker node becomes unavailable for any reason."
          />
          <Checkbox
            control={
              <MuiCheckbox
                checked={isChecked(KubernetesRoles.thumb)}
                onChange={() => onCheckboxChange(KubernetesRoles.thumb)}
              />
            }
            label="Thumb"
            tooltip="The thumb role determines where the thumbnail service should run. This is the most CPU-intensive role and for that reason, it is generally recommended to not colocate this role with the video role on the same worker."
          />

          {devMode && (
            <>
              <Checkbox
                control={
                  <MuiCheckbox
                    checked={isChecked(KubernetesRoles.edge_connect)}
                    onChange={() => onCheckboxChange(KubernetesRoles.edge_connect)}
                  />
                }
                label="Edge Connect"
                tooltip="The edge_connect role is used to deploy Edge Connect instances within the Kubernetes cluster."
                disabled={isExternalNode}
              />
              <Checkbox
                control={
                  <MuiCheckbox
                    checked={isChecked(KubernetesRoles.load_simulator)}
                    onChange={() => onCheckboxChange(KubernetesRoles.load_simulator)}
                  />
                }
                label="Load simulator"
                tooltip="The load simulator role is used to run appliance emulators that generate control plane load."
                disabled={isExternalNode}
              />
            </>
          )}
        </Paper>
        <ButtonsPane
          main={{
            Cancel: {
              onClick: () => void navigate(routes.kubernetesNodes()),
            },
            Save: {
              id: 'button-save',
              savingState: !!saving,
              primary: true,
              type: 'submit',
            },
          }}
        />
      </Form>
    </>
  )
}
