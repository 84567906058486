import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import { readAlarmsInBackground } from '../../redux/actions/alarmsActions'
import { ROUTES } from '../../utils/routes'
import type { AppDispatch } from '../../store'
import { NotFound } from '../common/NotFound'
import { EMSidebar } from '../partials/EMSidebar'
import ErrorBoundary from '../common/ErrorBoundary'
import { PageTitle } from '../common/PageTitle'
import { EventPage } from '../events/EventPage'
import { Profile } from '../profile'

export const EventManagerContainer = () => {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(readAlarmsInBackground({}))
  }, [dispatch])

  return (
    <>
      <PageTitle title="Event Manager" />
      <Box sx={{ height: '100vh', width: '100vw', display: 'flex' }}>
        <EMSidebar />
        <ErrorBoundary>
          <Container component="main" maxWidth={false} sx={{ overflowX: 'auto' }}>
            <Routes>
              <Route index element={<EventPage />} />
              <Route path={ROUTES.profile} element={<Profile />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
        </ErrorBoundary>
      </Box>
    </>
  )
}
