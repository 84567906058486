import { AdAuditLogRequestParams } from '../../../api/nm-types'
import { FilterBy, FilterTemplate } from '../../common/Filters/FilterView/FilterTemplate'
import { AdAuditEntity, AdAuditMethod, AdAuditResult, AdAuditStatus } from 'common/types'
import { dateFilterPossibleValues } from '../../../components/common/Filters/FilterView/dateFilter'
import { format } from 'date-fns'
import { DATE_FORMAT_LONGER } from 'common/api/v1/helpers'

export enum AdAuditLogFilterKey {
  any = 'any',
  timestamp = 'timestamp',
  entityType = 'entity type',
  method = 'method',
  status = 'status',
  result = 'result',
  eventId = 'event id',
  outputId = 'output id',
  streamId = 'stream id',
  channelId = 'channel id',
  component = 'component',
  pod = 'pod',
  entity = 'entity',
  entityId = 'entity id',
  entityName = 'entity name',
  message = 'message',
  source = 'source',
  target = 'target',
  traceId = 'trace id',
}

export function mapAdAuditLogFilterToUrlParam(filter: FilterBy): Partial<AdAuditLogRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case AdAuditLogFilterKey.any:
        return { filter: filter.value }
      case AdAuditLogFilterKey.timestamp:
        return filter.operator === 'after'
          ? { fromDate: new Date(filter.value).toISOString() }
          : { toDate: new Date(filter.value).toISOString() }
      case AdAuditLogFilterKey.entityType:
        return { entityType: filter.value }
      case AdAuditLogFilterKey.method:
        return { method: filter.value }
      case AdAuditLogFilterKey.status:
        return { status: filter.value }
      case AdAuditLogFilterKey.result:
        return { result: filter.value }
      case AdAuditLogFilterKey.eventId:
        return { eventId: filter.value }
      case AdAuditLogFilterKey.outputId:
        return { outputId: filter.value }
      case AdAuditLogFilterKey.streamId:
        return { streamId: filter.value }
      case AdAuditLogFilterKey.channelId:
        return { channelId: filter.value }
      case AdAuditLogFilterKey.component:
        return { component: filter.value }
      case AdAuditLogFilterKey.pod:
        return { pod: filter.value }
      case AdAuditLogFilterKey.entity:
        return { entity: filter.value }
      case AdAuditLogFilterKey.entityId:
        return { entityId: filter.value }
      case AdAuditLogFilterKey.entityName:
        return { entityName: filter.value }
      case AdAuditLogFilterKey.message:
        return { message: filter.value }
      case AdAuditLogFilterKey.source:
        return { source: filter.value }
      case AdAuditLogFilterKey.target:
        return { target: filter.value }
      case AdAuditLogFilterKey.traceId:
        return { traceId: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToAdAuditLogFilter(key: keyof AdAuditLogRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: AdAuditLogFilterKey.any,
        operator: 'matches',
        value,
      }
    case 'fromDate':
      return {
        key: AdAuditLogFilterKey.timestamp,
        operator: 'after',
        value: format(new Date(value), DATE_FORMAT_LONGER),
      }
    case 'toDate':
      return {
        key: AdAuditLogFilterKey.timestamp,
        operator: 'before',
        value: format(new Date(value), DATE_FORMAT_LONGER),
      }
    case 'entityType':
      return {
        key: AdAuditLogFilterKey.entityType,
        operator: 'is',
        value,
      }
    case 'method':
      return {
        key: AdAuditLogFilterKey.method,
        operator: 'is',
        value,
      }
    case 'status':
      return {
        key: AdAuditLogFilterKey.status,
        operator: 'is',
        value,
      }
    case 'result':
      return {
        key: AdAuditLogFilterKey.result,
        operator: 'is',
        value,
      }
    case 'eventId':
      return {
        key: AdAuditLogFilterKey.eventId,
        operator: 'matches',
        value,
      }
    case 'outputId':
      return {
        key: AdAuditLogFilterKey.outputId,
        operator: 'matches',
        value,
      }
    case 'streamId':
      return {
        key: AdAuditLogFilterKey.streamId,
        operator: 'matches',
        value,
      }
    case 'channelId':
      return {
        key: AdAuditLogFilterKey.channelId,
        operator: 'matches',
        value,
      }
    case 'component':
      return {
        key: AdAuditLogFilterKey.component,
        operator: 'is',
        value,
      }
    case 'pod':
      return {
        key: AdAuditLogFilterKey.pod,
        operator: 'matches',
        value,
      }
    case 'entity':
      return {
        key: AdAuditLogFilterKey.entity,
        operator: 'matches',
        value,
      }
    case 'entityId':
      return {
        key: AdAuditLogFilterKey.entityId,
        operator: 'matches',
        value,
      }
    case 'entityName':
      return {
        key: AdAuditLogFilterKey.entityName,
        operator: 'matches',
        value,
      }
    case 'message':
      return {
        key: AdAuditLogFilterKey.message,
        operator: 'matches',
        value,
      }
    case 'source':
      return {
        key: AdAuditLogFilterKey.source,
        operator: 'matches',
        value,
      }
    case 'target':
      return {
        key: AdAuditLogFilterKey.target,
        operator: 'matches',
        value,
      }
    case 'traceId':
      return {
        key: AdAuditLogFilterKey.traceId,
        operator: 'matches',
        value,
      }
  }
  return undefined
}

export function makeAdAuditLogFilters(): FilterTemplate[] {
  const filterTemplates: FilterTemplate[] = [
    { key: AdAuditLogFilterKey.any, operator: 'matches' },
    {
      key: AdAuditLogFilterKey.timestamp,
      operator: 'after',
      possibleValuesSync: (s) => dateFilterPossibleValues(s),
    },
    {
      key: AdAuditLogFilterKey.timestamp,
      operator: 'before',
      possibleValuesSync: (s) => dateFilterPossibleValues(s),
    },
    {
      key: AdAuditLogFilterKey.entityType,
      operator: 'is',
      possibleValuesSync: () => Object.values(AdAuditEntity).map((v) => ({ value: v, displayName: v })),
    },
    {
      key: AdAuditLogFilterKey.method,
      operator: 'is',
      possibleValuesSync: () => Object.values(AdAuditMethod).map((v) => ({ value: v, displayName: v })),
    },
    {
      key: AdAuditLogFilterKey.status,
      operator: 'is',
      possibleValuesSync: () => Object.values(AdAuditStatus).map((v) => ({ value: v, displayName: v })),
    },
    {
      key: AdAuditLogFilterKey.result,
      operator: 'matches',
      possibleValuesSync: () => Object.values(AdAuditResult).map((v) => ({ value: v, displayName: v })),
    },
    { key: AdAuditLogFilterKey.eventId, operator: 'matches' },
    { key: AdAuditLogFilterKey.outputId, operator: 'matches' },
    { key: AdAuditLogFilterKey.streamId, operator: 'matches' },
    { key: AdAuditLogFilterKey.channelId, operator: 'matches' },
    { key: AdAuditLogFilterKey.component, operator: 'matches' },
    { key: AdAuditLogFilterKey.pod, operator: 'matches' },
    { key: AdAuditLogFilterKey.entity, operator: 'matches' },
    { key: AdAuditLogFilterKey.entityId, operator: 'matches' },
    { key: AdAuditLogFilterKey.entityName, operator: 'matches' },
    { key: AdAuditLogFilterKey.message, operator: 'matches' },
    { key: AdAuditLogFilterKey.source, operator: 'matches' },
    { key: AdAuditLogFilterKey.target, operator: 'matches' },
    { key: AdAuditLogFilterKey.traceId, operator: 'matches' },
  ]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}
