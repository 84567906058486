import { Route, Routes } from 'react-router'

import { NotFound } from '../common/NotFound'
import { Graph } from './Graph'

export const Graphs = () => {
  return (
    <Routes>
      <Route index element={<Graph />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
