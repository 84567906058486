import { createSlice } from '@reduxjs/toolkit'

import { logoutUser } from '../actions/userActions'
import { createLoadingReducer } from './shared'
import { AdAuditLogMessage } from 'common/types'
import { getAdAudit } from '../actions/adAuditActions'

interface State {
  adAudit: Array<AdAuditLogMessage>
  total: number
  loading: boolean
}

export const initialStateAdAudit: State = {
  adAudit: [],
  total: 0,
  loading: false,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(getAdAudit)

const adAuditSlice = createSlice({
  name: 'adAudit',
  initialState: initialStateAdAudit,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getAdAudit.fulfilled,
        (state, { payload: { items: adAudit, total } }): State => ({
          ...state,
          adAudit,
          total,
        }),
      )
      .addCase(logoutUser.fulfilled, (): State => initialStateAdAudit)
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default adAuditSlice.reducer
