import { Route, Routes } from 'react-router'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import { NotFound } from '../common/NotFound'
import { Header } from '../streamManager/TopBar'
import { List as StreamManagerMain } from '../streamManager/List/index'
import { Profile } from '../profile/index'
import ErrorBoundary from '../common/ErrorBoundary'

const padding = { marginBottom: 2, paddingX: 3 }

export const StreamManagerContainer = () => {
  return (
    <Box sx={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <ErrorBoundary>
        <Container component="main" maxWidth={false} disableGutters sx={{ flexGrow: 1, overflowX: 'auto' }}>
          <Routes>
            <Route index element={<StreamManagerMain />} />
            <Route
              path="profile"
              element={
                <Box sx={padding}>
                  <Profile isStreamManager />
                </Box>
              }
            />
            <Route
              path="*"
              element={
                <Box sx={padding}>
                  <NotFound />
                </Box>
              }
            />
          </Routes>
        </Container>
      </ErrorBoundary>
    </Box>
  )
}
