import Button from '@mui/material/Button'

import type { OutputRecipientList, OutputRecipientListSortableField } from 'common/api/v1/types'
import { useOutputListsSelector, usePageParamsFilteredSelector } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import { useRoutes } from '../../store'
import type { EnrichedOutputRecipientList } from '../../api/nm-types'
import { UrlParamFilteredSearchBar } from '../common/Filters/FilterView/FilteredSearchBar'
import Toolbar from '@mui/material/Toolbar'
import {
  ListOutputListFilterKey,
  makeListOutputListsFilter,
  mapOutputListFilterToUrlParam,
  mapUrlParamToOutputListFilter,
} from './listOutputListsFilter'

export const ListView = () => {
  const routes = useRoutes()
  const { outputLists, total, loading } = usePageParamsFilteredSelector(useOutputListsSelector)

  const tableConfig: TableConfig<EnrichedOutputRecipientList, OutputRecipientListSortableField> = [
    {
      title: 'name',
      getValue: (outputList) => (
        <Link to={routes.outputListsUpdate({ id: outputList.id })} underline="hover">
          {outputList.name}
        </Link>
      ),
      sorting: { byParameter: 'name' },
    },
    {
      title: 'description',
      getValue: ({ description }) => description,
      sorting: { byParameter: 'description' },
    },
    {
      title: 'group',
      getValue: (groupList) => (
        <Link to={routes.groupsUpdate({ id: groupList.group })} underline="hover">
          {groupList._group?.name}
        </Link>
      ),
    },
    {
      getValue: ({ id }) => <MoveToEntity url={routes.outputListsUpdate({ id })} entityName="output list" />,
      props: { align: 'right' },
    },
  ]
  const filters = makeListOutputListsFilter()
  return (
    <Wrapper
      name={['Outputs', 'Lists']}
      actions={
        <Link key="outputListCreate" type="button" to={routes.outputListsNew()}>
          <Button variant="contained" color="primary">
            Add output list
          </Button>
        </Link>
      }
    >
      <Toolbar disableGutters>
        <div style={{ flexShrink: 1, flexGrow: 1 }}>
          <UrlParamFilteredSearchBar
            urlParamCacheKey={'outputLists'}
            mapUrlParamToFilterFn={mapUrlParamToOutputListFilter}
            mapFilterToUrlParamFn={mapOutputListFilterToUrlParam}
            searchTokenFilters={filters}
            rawTextFilter={filters.find((f) => f.key === ListOutputListFilterKey.name)!}
          />
        </div>
      </Toolbar>
      <Table<OutputRecipientList>
        emptyMessageComponent={
          <MissingContent message="No output lists" buttonText="Add output list" url={routes.outputListsNew()} />
        }
        config={tableConfig}
        data={outputLists}
        pending={loading}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
