import { useFormContext } from 'react-hook-form'
import { Autocomplete } from '../../common/Form'

export const AdNumberFilterInputField = ({ formName, label }: { formName: string; label: string }) => {
  const { getValues } = useFormContext()
  const fieldValue = getValues(formName) || []

  return (
    <Autocomplete<string>
      name={formName}
      label={label}
      required={false}
      api={(params) =>
        new Promise((resolve) =>
          resolve(
            params.filter && !isNaN(parseInt(params.filter))
              ? { items: [parseInt(params.filter).toString()], total: 1 }
              : { items: [], total: 0 },
          ),
        )
      }
      multiple
      allowCustomOptions={false}
      validators={{
        numericality: {
          noStrings: true,
        },
      }}
      defaultOption={fieldValue}
      getOptionValue={(option) => option}
      getOptionLabel={(option) => option}
      optionComparator={(o1, o2) => o1 === o2}
    />
  )
}
