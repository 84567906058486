import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'

import { Scte104Injection, Scte104InjectionResult } from 'common/api/v1/types'
import { enqueueErrorSnackbar } from './notificationActions'

export const triggerAdBreak = createAsyncThunk<Scte104InjectionResult, Scte104Injection, ThunkApi>(
  'audit/getAdAudit',
  async (params, { dispatch, extra: { api } }) => {
    try {
      return await api.adInsertionDemoApi.triggerAdBreak(params)
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'trigger ad break' }))
      throw err
    }
  },
)
