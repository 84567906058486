import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { deleteAdServer, listAdServersHook } from '../../../redux/actions/adServerActions'
import { Api, AppDispatch, useRoutes } from '../../../store'
import { GridItem, Paper } from '../../common/Form'
import Pendable from '../../common/Pendable'
import Table from '../../common/Table'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Link } from '../../common/Link'
import { DeleteButton } from '../../common/DeleteButton'
import { PaginatedRequestParams } from '../../../api/nm-types'
import { usePageParamsFiltered } from '../../../utils'
import { useAsync } from 'react-async-hook'
const { adServersApi } = Api

const marginBetweenIconAndName = '12px'

export const AdServerList = () => {
  const navigate = useNavigate()
  const routes = useRoutes()
  const dispatch = useDispatch<AppDispatch>()

  const searchParams = usePageParamsFiltered<PaginatedRequestParams>()

  const { result, execute } = useAsync(
    () => listAdServersHook(searchParams, { dispatch, adServersApi }),
    [JSON.stringify(searchParams)],
  )

  return (
    <Paper title="Ad Servers">
      <GridItem lg={12} xl={12}>
        <Pendable pending={!result}>
          {result && (
            <Table
              emptyMessageComponent="No Ad Servers found"
              data={result.items}
              pagination={{
                total: result.total,
                useUrlSearchParams: true,
              }}
              config={[
                {
                  title: 'name',
                  getValue: ({ name, id: adServersId }) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flex: 1,
                      }}
                    >
                      <Link
                        underline="hover"
                        to={routes.adServersUpdate({ id: adServersId })}
                        style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
                        muiStyle={{
                          display: 'flex',
                          flex: 1,
                          gap: marginBetweenIconAndName,
                        }}
                      >
                        <span style={{ display: 'flex', flex: 1 }}>{name}</span>
                      </Link>
                    </Box>
                  ),
                },
                {
                  getValue: ({ id: adServerId }) =>
                    adServerId && (
                      <DeleteButton
                        callback={() => {
                          dispatch(deleteAdServer(adServerId)).then(() => {
                            execute()
                          })
                        }}
                        confirmMessage="Are you sure you want to delete this ad server?"
                      />
                    ),
                  props: { align: 'right', noTypography: true },
                },
              ]}
            />
          )}
        </Pendable>
      </GridItem>
      <GridItem lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={() => navigate(routes.adServersNew())}>
          Add Ad Server
        </Button>
      </GridItem>
    </Paper>
  )
}
