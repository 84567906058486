import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import Chip from '@mui/material/Chip'
import { SxProps, Theme } from '@mui/material/styles'
import GridItem, { GridItemProps } from './GridItem'

export interface FormTagsInputProps extends Omit<React.ComponentProps<typeof TextField>, 'name'>, GridItemProps {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  comment?: string
  commentProps?: Partial<SxProps<Theme>>
  // any other props you want to pass to the Autocomplete component
}

export const MultiSelect: React.FC<FormTagsInputProps> = ({
  name,
  label,
  required = false,
  disabled = false,
  comment,
  commentProps,
  xs,
  newLine,
  tooltip,
  ...textFieldProps
}) => {
  const { getFieldState } = useFormContext()

  const hasError = Boolean(getFieldState(name)?.error)

  return (
    <GridItem xs={xs} newLine={newLine} tooltip={tooltip}>
      <FormControl fullWidth margin="normal">
        <Controller
          name={name}
          render={({ field }) => (
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              disabled={disabled}
              value={field.value}
              onChange={(_, newValue) => {
                // Update react-hook-form on tag change
                field.onChange(newValue)
              }}
              // Render the selected tags as Chips
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                ))
              }
              // Render the input field
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...textFieldProps}
                  label={label || name}
                  variant="outlined"
                  required={required}
                  error={hasError}
                  helperText={hasError ? getFieldState(name)?.error?.message : comment}
                />
              )}
            />
          )}
        />
      </FormControl>
    </GridItem>
  )
}
