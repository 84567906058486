import { PRODUCTS } from 'common/constants'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSettingsSelector } from '../../utils'

const defaultProductName: string = PRODUCTS.nimbraEdge.name

export const PageTitle = ({ title }: { title: string }) => {
  const { settings } = useSettingsSelector()
  const [productName, setProductName] = useState('')

  useEffect(() => {
    if (!settings) return

    if (settings?.customTabTitle) {
      setProductName(settings.customTabTitle)
      return
    }

    setProductName(defaultProductName)
  }, [settings])

  return (
    <Helmet>
      <title>
        {title} - {productName}
      </title>
    </Helmet>
  )
}
