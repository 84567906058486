import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import { updateService } from '../../../redux/actions/serviceActions'
import type { Service, ServiceUpdate } from 'common/api/v1/types'
import { Api, AppDispatch } from '../../../store'
import { ButtonsPane, Checkbox, Paper, SafeRouting, TextInput, Form } from '../../common/Form'
import Pendable from '../../common/Pendable'
import Wrapper from '../../common/Wrapper'
import { formTransform } from '../../../utils'
import { Meta } from '../meta'
import { useAsync } from 'react-async-hook'
import { FailedFetchingContent } from '../../common/FailedFetchingContent'

import { useRoutes } from '../../../store'
import { RHF } from '../../common/Form'
import { AllowUncontrolled, FormProps } from '../../common/Form/RHF'
import { StoragePools } from './StoragePools'
const { serviceApi } = Api

type ServiceEditState = ServiceUpdate & {
  _desiredRetentionPeriodDays?: number
}

const getInitialState = (service: Service): ServiceEditState => {
  const desiredRetentionPeriod = service?.retentionPeriods?.[0].desired ?? service?.retentionPeriods?.[0].current
  const _desiredRetentionPeriodDays = desiredRetentionPeriod ? desiredRetentionPeriod / 1000 / 60 / 60 / 24 : undefined
  return {
    ...service,
    _desiredRetentionPeriodDays,
  }
}

const ServiceForm = ({ formState, getValues }: FormProps<Service & AllowUncontrolled>) => {
  const navigate = useNavigate()
  const values = getValues()
  const routes = useRoutes()
  const product = getValues().product
  const desiredVolumeCapacityTooltip =
    `Increase the volume capacity of all service instances in Kubernetes (if it's enabled by the Kubernetes provider). The volume capacity cannot be decreased.` +
    (product === 'Redpanda'
      ? `\n\nRemember to also increase the retention.bytes setting for the topics using kubectl and the rpk command or using the Redpanda Console at ${window.origin}/kafka. The retention.bytes setting applies per partition, so the total disk usage per topic and node will be retention.bytes multiplied by 8.`
      : '')

  return (
    <>
      <SafeRouting enabled={formState.isDirty && !formState.isSubmitting} />
      <Form id="service-form" noValidate>
        <Meta service={values} />
        <Paper title="Settings">
          <Checkbox
            name="collectLogs"
            label="Collect logs"
            tooltip="Enabling log collection will ensure the logs are collected, indexed and stored."
          />
          {values.allowEnableDisable && <Checkbox name="enabled" label="Enable" tooltip="Enable the service" />}
          {values.allowsVolumeAdministration && (
            <TextInput
              name="desiredVolumeCapacity"
              label="Desired volume capacity"
              tooltip={desiredVolumeCapacityTooltip}
            />
          )}
          {values.allowsRetentionPeriodAdministration && (
            <TextInput
              name="_desiredRetentionPeriodDays"
              type="number"
              label="Desired retention period (days)"
              tooltip={'Set the retention period in days. 0 means no retention.'}
              validators={{
                number: {
                  onlyInteger: true,
                  greaterThanOrEqualTo: 0,
                  message: 'Must be a positive integer',
                },
              }}
            />
          )}
        </Paper>

        {values.storagePools !== undefined && <StoragePools />}

        <ButtonsPane
          main={{
            Cancel: {
              onClick: () => {
                navigate(routes.services())
              },
            },
            Save: { savingState: formState.isSubmitting, primary: true, type: 'submit' },
          }}
        />
      </Form>
    </>
  )
}

export const Edit = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const routes = useRoutes()
  const navigate = useNavigate()
  const onSubmit = async (service: ServiceUpdate) => {
    await updateService(service, { dispatch, serviceApi: Api.serviceApi, navigate, routes })
  }

  const { result: serviceToEdit, loading, error } = useAsync(async () => serviceApi.getService(id!), [id])

  return (
    <Wrapper name={['Services', serviceToEdit ? serviceToEdit.purpose : ' ']}>
      {error ? (
        <FailedFetchingContent message={'Failed to fetch service'} />
      ) : (
        <Pendable pending={loading}>
          <RHF
            onSubmit={(values) => {
              const { _desiredRetentionPeriodDays, ...body } = formTransform(values)
              return onSubmit({
                ...body,
                desiredRetentionPeriod: _desiredRetentionPeriodDays
                  ? _desiredRetentionPeriodDays * 24 * 60 * 60 * 1000 // day to ms
                  : undefined,
              })
            }}
            defaultValues={getInitialState(serviceToEdit!)}
            component={ServiceForm}
          />
        </Pendable>
      )}
    </Wrapper>
  )
}
