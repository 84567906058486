import { Route, Routes } from 'react-router'

import { NotFound } from '../common/NotFound'
import { StatusList } from './List'

export const Status = () => {
  return (
    <Routes>
      <Route index element={<StatusList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
