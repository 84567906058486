import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { format } from 'date-fns'
import get from 'lodash/get'
import Button from '@mui/material/Button'
import Info from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'

import {
  ApplianceType,
  BroadcastStandard,
  ExpFeatures,
  IpPortMode,
  ListInputSortableField,
  PhysicalPort,
  ThumbnailMode,
  VideoPreviewMode,
} from 'common/api/v1/types'
import { isEditableGroup, pluralizeWord, useConfirmationDialog, useSettingsSelector, useUser } from '../../../utils'
import { Api, AppDispatch, GlobalState, useRoutes } from '../../../store'
import {
  Autocomplete,
  ButtonsPane,
  Checkbox,
  Form,
  GridItem,
  Paper,
  SafeRouting,
  Select,
  TextInput,
} from '../../common/Form'
import DataSet from '../../common/DataSet'
import { AutoUpdatingInputHealthIndicator } from '../../common/Indicator'
import { Link } from '../../common/Link'

import {
  CommonFields,
  generatorDefaults,
  ristDefaults,
  rtpDefaults,
  srtDefaults,
  udpDefaults,
  zixiDefaults,
} from './PortForm/IpPortForm'
import { useEditType, type EnrichedInputWithEnrichedPorts } from './index'
import { rtmpDefaults } from './PortForm/IpPortForm/RtmpForm'
import {
  APPLIANCE_SECTION_FORM_PREFIX,
  ApplianceSection,
  collectApplianceSectionEntries,
  getPortAppliance,
  isAppliance,
  isApplianceOrRegionSelectable,
  isCoreNode,
  makeApplianceSection,
} from '../../common/Interface/Base'
import { applianceList, getCoreNodesInput } from '../../common/Metadata'
import {
  DATE_FORMAT_LONG,
  getFormattedTransportStreamContent,
  getProductName,
  notUndefinedOrNull,
  tsInfoServiceName,
} from 'common/api/v1/helpers'
import { isFeatureOn } from '../../../utils/features'
import { EnrichedInput, EnrichedInputPort, PaginatedRequestParams } from '../../../api/nm-types'
import { removeInput, rerouteInput } from '../../../redux/actions/inputsActions'
import { ndiDefaults } from './PortForm/NdiForm'
import { InputTransformationSection } from '../DerivedInput/InputTransformationSection'
import { enqueueErrorSnackbar } from '../../../redux/actions/notificationActions'
import * as uuid from 'uuid'
import Tooltip from '../../common/Tooltip'
import { TagInputField } from '../../common/TagInputField'
import type { AllowUncontrolled, FormProps } from '../../common/Form/RHF'
import { isRtpOverSrt } from 'common/srt'

const { inputApi } = Api

export const MAX_MAX_BITRATE_MBPS = 2147

export const initialInputLogicalPort = ({
  physicalPortId,
  port,
  enforcedMode,
  applianceAllocationId,
  numDistinctFailoverPriorities,
}: {
  physicalPortId?: string
  port?: PhysicalPort
  enforcedMode?: IpPortMode
  applianceAllocationId?: string
  numDistinctFailoverPriorities?: number
}) => ({
  ...udpDefaults(),
  ...rtpDefaults(),
  ...srtDefaults(numDistinctFailoverPriorities || 0),
  ...ristDefaults(),
  ...zixiDefaults(),
  ...rtmpDefaults(),
  ...generatorDefaults(),
  ...ndiDefaults(),
  _port: port,
  [CommonFields.mode]: enforcedMode ?? '',
  [CommonFields.physicalPort]: physicalPortId,
  [CommonFields.applianceAllocationId]: applianceAllocationId,
  // Provide an initial id to use as 'React.key'
  id: uuid.v4(),
})

const TsParsingMode = ({ disabled, thumbnailMode }: { disabled: boolean; thumbnailMode: ThumbnailMode }) => {
  return (
    <Select
      label="Broadcast standard for transport stream analysis"
      name="broadcastStandard"
      disabled={disabled}
      options={[
        { name: 'DVB', value: 'dvb' },
        { name: 'ATSC', value: 'atsc' },
        {
          name: 'No analysis',
          value: 'none',
          disabled: thumbnailMode === ThumbnailMode.edge,
        },
      ]}
      tooltip="Choose broadcast standard for mpeg-ts parsing or disable analysis by choosing 'No analysis'."
    />
  )
}

const UnhealthyAlarmLevel = (_props: object) => {
  return (
    <Select
      label="Alarm level when input is unhealthy"
      name="unhealthyAlarm"
      options={[
        { name: '(No alarm)', value: '' },
        { name: 'Critical', value: 'critical' },
        { name: 'Major', value: 'major' },
        { name: 'Minor', value: 'minor' },
        { name: 'Warning', value: 'warning' },
      ]}
      tooltip="Choose what type of alarm will be triggered when the input becomes unhealthy."
    />
  )
}

const VideoPreviewModeSelect = ({ disabled }: { disabled: boolean }) => {
  return (
    <Select
      label="Preview"
      disabled={disabled}
      name="videoPreviewMode"
      options={Object.values(VideoPreviewMode)}
      tooltip="'on demand' begins generating input preview when requested and is a more efficient alternative to 'always on', which constantly generates input preview and has a faster startup-time. No preview will be available if the input contains multiple video streams. Preview can only be enabled with 'core' thumbnail mode."
    />
  )
}

const thumbnailOptions = [
  { name: 'none', value: ThumbnailMode.none, hoverTooltip: 'thumbnail generation is disabled' },
  {
    name: 'core',
    value: ThumbnailMode.core,
    hoverTooltip: `thumbnails are generated on ${getProductName(ApplianceType.thumb)} appliances`,
  },
  {
    name: 'edge',
    value: ThumbnailMode.edge,
    hoverTooltip: 'thumbnails are generated on the ingress appliance',
  },
]

const ThumbnailModeSelect = () => {
  return <Select label="Thumbnail mode" name="thumbnailMode" options={thumbnailOptions} />
}

interface DeriveFromInputSelectProps {
  currentParentInput?: EnrichedInput
}

const DeriveFromInputSelect = ({ currentParentInput }: DeriveFromInputSelectProps) => {
  const { getValues } = useFormContext<InputFormProps>()

  const api = useCallback(async (params: PaginatedRequestParams<any>) => {
    // Fetch all inputs except derived ones
    const response = await inputApi.getInputs.bind(inputApi)({ ...params, derived: false })

    // Remove current form input from response
    const itemsWithoutSelf = response.items.filter((item) => item.id !== getValues().id)
    return {
      items: itemsWithoutSelf,
      total: itemsWithoutSelf.length,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Autocomplete<EnrichedInput>
      key={currentParentInput?.id}
      required={true}
      name="deriveFrom.parentInput"
      label="Input to derive from"
      defaultOption={currentParentInput}
      tooltip="Search for the input to derive from. Other derived inputs are not shown here"
      api={api}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => {
        return option.name
      }}
      optionComparator={(o1, o2) => o1.id === o2.id}
      data-test-id="deriveFrom.parent"
    />
  )
}

interface DerivedInputProps {
  currentParentInput?: EnrichedInput
}

const DerivedInput = ({ currentParentInput }: DerivedInputProps) => {
  const { getValues, watch } = useFormContext<InputFormProps>()

  const derivedKey = '_derived'
  watch(derivedKey)

  return (
    <>
      <Checkbox name={derivedKey} label="Derive from input" />
      {!!getValues()._derived && <DeriveFromInputSelect currentParentInput={currentParentInput} />}
    </>
  )
}

export type InputFormProps = EnrichedInputWithEnrichedPorts

const InputForm = (form: FormProps<InputFormProps & AllowUncontrolled>) => {
  const routes = useRoutes()
  const {
    getValues,
    setError,
    setFocus,
    setValue,
    formState,
    defaultValues,
    watch,
    trigger: validateForm,
    reset,
  } = form
  const values = getValues()
  const user = useUser()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const setConfirm = useConfirmationDialog()
  const { devMode, settings } = useSettingsSelector()
  const editType = useEditType()

  watch('thumbnailMode')
  const [currentParentInput, setCurrentParentInput] = useState<EnrichedInput>()
  const { isSaving, isRerouting, formErrors } = useSelector(
    ({ inputsReducer }: GlobalState) => ({
      isSaving: inputsReducer.saving,
      isRerouting: inputsReducer.rerouting,
      formErrors: inputsReducer.formErrors,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (Array.isArray(formErrors)) {
      formErrors.forEach(({ name, reason }) => {
        setError(name, { type: 'manual', message: reason })
      })
      if (formErrors[0]) setFocus(formErrors[0].name)
    }
  }, [formErrors, setError, setFocus])

  // reset on changed editType
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(defaultValues), [editType, reset])

  const selectedThumbnailMode = values.thumbnailMode
  const thumbnailModeSupportsPreview = selectedThumbnailMode === ThumbnailMode.core
  const videoPreviewMode = values.videoPreviewMode
  useEffect(() => {
    function disablePreviewIfThumbnailingDisabled() {
      if (!thumbnailModeSupportsPreview && videoPreviewMode && videoPreviewMode !== VideoPreviewMode.off) {
        void setValue('videoPreviewMode', VideoPreviewMode.off)
      }
    }
    disablePreviewIfThumbnailingDisabled()
  }, [thumbnailModeSupportsPreview, videoPreviewMode, setValue])

  const selectedBroadcastStandard = values.broadcastStandard as 'none' | BroadcastStandard
  useEffect(() => {
    const shouldEnableBroadcastStandard =
      selectedThumbnailMode === ThumbnailMode.edge && selectedBroadcastStandard === 'none'
    if (shouldEnableBroadcastStandard) {
      void setValue('broadcastStandard', settings?.defaultBroadcastStandard ?? BroadcastStandard.dvb)
    }
  }, [selectedBroadcastStandard, selectedThumbnailMode, settings])

  // Fetch current select parent input and save in local state to avoid fetching it multiple times in child components
  useEffect(() => {
    const parentInputId = values.deriveFrom?.parentInput
    if (!parentInputId) {
      setCurrentParentInput(undefined)
      return
    }

    let cancelled = false
    inputApi
      .getInput(parentInputId)
      .then((input) => {
        if (cancelled) {
          // The useEffect cleanup has run and the component may have been unmounted
          return
        }

        setCurrentParentInput(input)
      })
      .catch((err) => {
        dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch input to derive from' }))
      })

    return () => {
      cancelled = true
    }
  }, [values.deriveFrom?.parentInput, setValue])

  // Switch broadcast standard to mirror the current selected parent input
  useEffect(() => {
    if (currentParentInput === undefined) return
    setValue('broadcastStandard', currentParentInput.broadcastStandard)
    setValue('handoverMethod', currentParentInput.handoverMethod)
  }, [currentParentInput, setValue])

  const coreNodes = getCoreNodesInput(values)

  const onRemoveInputAppliance = (index: number) => removeApplianceSection(index)
  const initialApplianceSections = collectApplianceSectionEntries(defaultValues)
  const applianceSectionEntries = collectApplianceSectionEntries(values)

  const {
    fields: applianceSectionFields,
    append: appendApplianceSection,
    remove: removeApplianceSection,
    update: updateApplianceSection,
  } = useFieldArray({
    name: APPLIANCE_SECTION_FORM_PREFIX,
  })

  const adminStatus = get(values, 'adminStatus') ? 1 : 0
  const applianceSections = applianceSectionFields.map((field, applianceIndex) => {
    const data = applianceSectionEntries[applianceIndex]
    const key = field.id
    const isModeSelectionDisabled = data.ports.length > 1
    return (
      <ApplianceSection<EnrichedInputWithEnrichedPorts>
        applianceIndex={applianceIndex}
        title={`Input appliance #${applianceIndex + 1}`}
        key={key}
        namePrefix={`${APPLIANCE_SECTION_FORM_PREFIX}.${applianceIndex}`}
        adminStatus={adminStatus}
        initialApplianceOrRegionId={
          initialApplianceSections[applianceIndex]?.region?.id ??
          initialApplianceSections[applianceIndex]?.appliance?.id
        }
        isModeSelectionDisabled={isModeSelectionDisabled}
        onRemove={applianceSectionFields.length > 1 ? onRemoveInputAppliance : undefined}
        inputId={editType.isUpdate ? values.id : undefined}
        outputId={undefined}
        groupId={values.owner ?? user.group}
        isInputForm={true}
        enforcedPortMode={undefined}
        isEditingExistingEntity={editType.isUpdate}
        isCopyingExistingEntity={editType.isCopy}
        isApplianceOrRegionSelectable={(option) => isApplianceOrRegionSelectable(option, values, false)}
        onApplianceOrRegionSelected={(selected) => {
          if (!selected) {
            updateApplianceSection(applianceIndex, makeApplianceSection({ region: undefined, appliance: undefined }))
          } else if (isAppliance(selected)) {
            const applianceSection = makeApplianceSection({ region: undefined, appliance: selected })
            updateApplianceSection(applianceIndex, applianceSection)
          } else {
            const regionalSection = makeApplianceSection({ region: selected, appliance: undefined })
            updateApplianceSection(applianceIndex, regionalSection)
          }
        }}
        {...form}
      />
    )
  })

  const transportStreamContent = getFormattedTransportStreamContent((values.tsInfo || [])[0])
  const contentFormat =
    transportStreamContent === 'MPTS'
      ? 'MPTS'
      : `${transportStreamContent} (${tsInfoServiceName((values.tsInfo || [])[0])})`

  const redundancyCheckboxDisabled = isCoreNode(values) || values._derived
  const getRedundancyTooltip = () => {
    if (!values._redundant && redundancyCheckboxDisabled) {
      // Checkbox can be disabled but redundancy enabled "by force", e.g. RTP-over-SRT for MA-core-inputs
      if (isCoreNode(values)) {
        return 'Not possible to enable redundancy when using a core appliance for the input.'
      }
      if (values._derived) {
        return 'Not possible to enable redundancy for a derived input.'
      }
      return undefined
    }
    return "Redundancy enabled will route the input stream through an additional path in the input appliance's region(s)."
  }

  const showTransformationSection = values._derived && currentParentInput !== undefined
  const showApplianceSections = !values._derived
  const canAddAdditionalInputAppliance = applianceSectionEntries.length === 1

  const allLogicalPorts = applianceSectionEntries.flatMap((s) => s.ports as EnrichedInputPort[])
  const isCoreVideoInput = allLogicalPorts.some((lp) => getPortAppliance(lp)?.type === ApplianceType.core)
  const areStreamsMergable = allLogicalPorts.every((lp) => isRtpOverSrt(lp))
  const isMultiAppliance =
    new Set(allLogicalPorts.map((lp) => getPortAppliance(lp)?.id).filter(notUndefinedOrNull)).size > 1
  const isRedundant = values._redundant
  const mustBeRedundant = isMultiAppliance && isCoreVideoInput && areStreamsMergable
  const canBeRedundant = mustBeRedundant || !isCoreVideoInput

  useEffect(() => {
    function ensureCorrectRedundancy() {
      if (!canBeRedundant && isRedundant) {
        setValue('_redundant', false)
      } else if (mustBeRedundant && !isRedundant) {
        setValue('_redundant', true)
      }
    }
    ensureCorrectRedundancy()
  }, [canBeRedundant, isRedundant, mustBeRedundant, setValue])

  useWatch({ name: '_redundant' })

  return (
    <>
      <SafeRouting formState={formState} />
      <Form id="input-form" noValidate disableSubmitOnEnterKey>
        <Paper className="outlined" title="Metadata" collapsible>
          <Paper>
            <TextInput name="name" label="Input name" required autoFocus />
            <Checkbox name="adminStatus" label="Enabled" onClick={() => void validateForm()} />
            <Checkbox
              name="_redundant"
              label="Redundant"
              disabled={redundancyCheckboxDisabled}
              tooltip={getRedundancyTooltip()}
            />
            <TagInputField formName={'tags'} type={'input'} />

            <ThumbnailModeSelect />
            <VideoPreviewModeSelect disabled={!thumbnailModeSupportsPreview} />
            <TextInput
              noNegative
              name="maxBitrateMbps"
              label="Max bitrate (Mbps)"
              validators={{
                number: {
                  lessThanOrEqualTo: MAX_MAX_BITRATE_MBPS,
                  greaterThan: 0,
                  message: `Must be greater than zero and no more than ${MAX_MAX_BITRATE_MBPS}`,
                },
              }}
              type="number"
              tooltip="Maximum bitrate allowed including retransmission. Packets exceeding the maximum bitrate will be dropped."
            />
            {devMode && <TextInput name="bufferSize" label="Buffer duration (ms)" required type="number" />}
            <TsParsingMode disabled={values._derived} thumbnailMode={selectedThumbnailMode} />
            {settings && isFeatureOn(ExpFeatures.ExtHealthAlarms, settings) && <UnhealthyAlarmLevel />}
            {settings && isFeatureOn(ExpFeatures.ExtDerivedInput, settings) && (
              <DerivedInput currentParentInput={currentParentInput} />
            )}
          </Paper>
          {editType.isUpdate && (
            <Paper>
              <GridItem lg={12} xl={12}>
                <DataSet
                  values={{
                    Id: values.id,
                    Tags: ((defaultValues.tags ?? []) as string[]).flatMap((tag, i) => {
                      const defaultOrder = settings?.expFeatures?.ExtHealthAlarms
                        ? ListInputSortableField.healthStatus
                        : undefined
                      const link = (
                        <Link to={routes.events({ tags: tag, desc: defaultOrder })} underline="hover">
                          {tag}
                        </Link>
                      )
                      return i === 0 ? link : [', ', link]
                    }),
                    Created: format(new Date(values.createdAt), DATE_FORMAT_LONG),
                    Updated: format(new Date(values.updatedAt), DATE_FORMAT_LONG),
                    Access: values.canSubscribe ? 'Full Access' : 'Preview',
                    Owner: !!values._owner?.id && (
                      <Link
                        to={routes.groupsUpdate({ id: values._owner.id })}
                        underline="hover"
                        available={isEditableGroup(values._owner.id, user)}
                      >
                        {values._owner?.name}
                      </Link>
                    ),
                    Status: <AutoUpdatingInputHealthIndicator initialInput={values} inline />,
                    Format: contentFormat,
                    [`Input ${pluralizeWord(values.appliances?.length ?? 0, 'appliance')}`]: applianceList(
                      values.appliances ?? [],
                      user,
                    ),
                    [`Core video ${pluralizeWord(coreNodes.length, 'node')}`]: applianceList(coreNodes, user),
                    'Service Overview': (
                      <IconButton
                        aria-label="service overview button"
                        onClick={(e) => {
                          e.stopPropagation()
                          navigate(routes.service({ id: values.id }))
                        }}
                        sx={{ marginLeft: '-10px', marginTop: '-8px' }}
                      >
                        <Tooltip title={'Service Overview'} placement="top">
                          <Info />
                        </Tooltip>
                      </IconButton>
                    ),
                  }}
                />
              </GridItem>
            </Paper>
          )}
        </Paper>
        {showTransformationSection && <InputTransformationSection currentParentInput={currentParentInput} />}
        {showApplianceSections && (
          <>
            {applianceSections}

            {canAddAdditionalInputAppliance && (
              <Button
                sx={{ marginBottom: 2 }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  const emptySection = makeApplianceSection({ region: undefined, appliance: undefined })
                  appendApplianceSection(emptySection)
                }}
              >
                Add input appliance
              </Button>
            )}
          </>
        )}

        <ButtonsPane
          main={{
            Cancel: {
              onClick: () => void navigate(routes.inputs()),
            },
            Save: { savingState: isSaving, primary: true, type: 'submit' },
          }}
          secondary={
            editType.isUpdate
              ? {
                  'Delete input': {
                    onClick: () => {
                      setConfirm(
                        () => void dispatch(removeInput({ inputId: values.id, redirect: true })),
                        `Deleting input "${values.name}". Are you sure?`,
                      )
                    },
                  },
                  'USE AS TEMPLATE': {
                    onClick: () => void navigate(routes.inputsCopy({ id: values.id })),
                  },
                  ...(devMode
                    ? {
                        Reroute: {
                          onClick: () => void dispatch(rerouteInput({ inputId: values.id })),
                          savingState: isRerouting,
                        },
                      }
                    : {}),
                }
              : undefined
          }
        />
      </Form>
    </>
  )
}

export default InputForm
