import { DispatchProp } from 'react-redux'
import { format } from 'date-fns'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Tooltip from '../../common/Tooltip'
import Typography from '@mui/material/Typography'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

import {
  ExperimentalFeatures,
  Input,
  InputAdminStatus,
  ListInputSortableField,
  Role,
  ThumbnailMode,
  User,
} from 'common/api/v1/types'
import {
  DATE_FORMAT_LONG,
  DATE_FORMAT_SHORT,
  getFormattedTransportStreamContent,
  tsInfoServiceName,
} from 'common/api/v1/helpers'

import { InputHealthIndicator, NonSubscribingAutoUpdatingInputHealthIndicator } from '../../common/Indicator'
import { TableConfig } from '../../common/Table'
import { NonSubscribingThumbnail } from '../../common/Thumbnail'
import { Link } from '../../common/Link'
import { hasAccessToAppliance, inputOutputColor, inputType, isEditableGroup } from '../../../utils'
import { EnrichedInput } from '../../../api/nm-types'
import { ActionMenu } from './ActionMenu'
import { TransportStream } from 'common/tr101Types'
import type { Routes } from '../../../utils/routes'
import CableIcon from '@mui/icons-material/Cable'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { canEditInput } from './index'

interface GetConfigProps {
  selected: Array<Input['id']>
  inputs: Array<Input>
  handleSelect: (id: string) => void
  handleSelectAll: () => void
  user: User
  dispatch: DispatchProp['dispatch']
  routes: Routes
  experimentalFeatures?: ExperimentalFeatures
}
type InputColumn = TableConfig<EnrichedInput, ListInputSortableField>[number]

const canUseNM = (role: Role) => role !== Role.basic

const status = ({
  autoUpdate,
  user,
  routes,
  experimentalFeatures,
}: { autoUpdate: boolean } & Pick<GetConfigProps, 'user' | 'routes' | 'experimentalFeatures'>): InputColumn => ({
  title: 'status',
  getValue: (input) => {
    const indicator = autoUpdate ? (
      <NonSubscribingAutoUpdatingInputHealthIndicator initialInput={input} />
    ) : (
      <InputHealthIndicator status={input.health} disabled={input.adminStatus === InputAdminStatus.off} />
    )
    // Note that this column actually conveys both the status and the access level of the input
    // When you have pull access for the input, you can see the status, otherwise you see the access level
    return input.canSubscribe || user.role === Role.super ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {input.alarms && input.alarms.length ? (
          <Link available={canUseNM(user.role)} type="button" to={routes.alarms()}>
            {indicator}
          </Link>
        ) : (
          indicator
        )}
        {input.unhealthyAlarm ? (
          <Tooltip title={'Status alarms are enabled for this input'}>
            <RemoveRedEyeIcon style={{ marginLeft: '5px' }} />
          </Tooltip>
        ) : null}
      </div>
    ) : (
      'preview'
    )
  },
  sorting: experimentalFeatures?.ExtHealthAlarms
    ? {
        byParameter: ListInputSortableField.healthStatus,
      }
    : undefined,
  props: { noTypography: true },
})

const preview = (): InputColumn => ({
  title: 'preview',
  getValue: (input) =>
    input.thumbnailMode === ThumbnailMode.edge ? (
      input.channelIds.map((channelId, index) => (
        <NonSubscribingThumbnail key={channelId} input={input} channelId={channelId} shouldHaveTopMargin={index > 0} />
      ))
    ) : (
      <NonSubscribingThumbnail input={input} />
    ),
  props: {
    sx: { padding: 1, width: '100px' },
    noTypography: true,
  },
  headerProps: {},
})

const name = (
  { availableFn, toFn }: { availableFn: (input: EnrichedInput) => boolean; toFn: (input: EnrichedInput) => string },
  override?: Partial<Pick<NonNullable<InputColumn>, 'props'>>,
): InputColumn => ({
  title: 'name',
  getValue: (input) => (
    <Link available={availableFn(input)} to={toFn(input)} underline="hover">
      <Typography {...inputOutputColor({ adminStatus: input.adminStatus })} data-test-id="input-name">
        {input.name}
      </Typography>
    </Link>
  ),
  sorting: {
    byParameter: ListInputSortableField.inputName,
  },
  props: {
    ...override?.props,
  },
})

export const inputColumns = {
  status,
  preview,
  name,
} as const satisfies Record<string, (props: any) => InputColumn>

const getConfig: (props: GetConfigProps) => InputColumn[] = ({
  selected,
  inputs,
  experimentalFeatures,
  handleSelect,
  handleSelectAll,
  user,
  dispatch,
  routes,
}) => [
  {
    title: (
      <Checkbox
        indeterminate={selected.length > 0 && selected.length < inputs.length}
        checked={selected.length === inputs.length}
        onChange={handleSelectAll}
        inputProps={{ 'aria-label': 'select all inputs' }}
        data-test-id={'select-all'}
      />
    ),
    getValue: ({ id }) => (
      <Checkbox
        checked={selected.includes(id)}
        onChange={() => {
          handleSelect(id)
        }}
        onDoubleClick={(e) => e.stopPropagation()}
      />
    ),
    props: {
      padding: 'checkbox',
      noTypography: true,
    },
  },
  status({ autoUpdate: true, user, routes, experimentalFeatures }),
  preview(),
  name({
    availableFn: ({ owner }: Input) => canEditInput(user, owner),
    toFn: ({ id }: Input) => routes.inputsUpdate({ id }),
  }),
  {
    title: 'appliance',
    getValue: ({ appliances, adminStatus }) =>
      appliances?.length
        ? appliances?.map((a) => (
            <Link
              key={a.id}
              to={routes.appliancesUpdate({ id: a.id })}
              available={hasAccessToAppliance(a, user)}
              underline="hover"
            >
              <Typography {...inputOutputColor({ adminStatus })} variant="body2">
                {a.name}
              </Typography>
            </Link>
          ))
        : '',
    sorting: {
      byParameter: ListInputSortableField.applianceName,
    },
  },
  {
    title: 'protocol',
    getValue: (input) => {
      const inputTypes = inputType(input)
      const chips = inputTypes.map((label) => (
        <Chip
          key={label}
          style={input.ports && input.ports.length > 0 ? { margin: '1pt' } : {}} // TODO: Why margin only when there are ports?
          size="small"
          label={label}
          icon={
            input.deriveFrom ? (
              <AutoAwesomeIcon />
            ) : !input.ports || !input.ports.length ? (
              <QuestionMarkIcon />
            ) : (
              <CableIcon />
            )
          }
        />
      ))
      return <> {chips} </>
    },
    sorting: {
      byParameter: ListInputSortableField.protocol,
    },
  },
  {
    title: 'format',
    getValue: (input) => {
      const tsInfos: (TransportStream | undefined)[] = (input.appliances ?? []).map((a) =>
        input.tsInfo?.find((info) => info.applianceId === a.id),
      )

      const formattedContent = tsInfos.map((info) => getFormattedTransportStreamContent(info)).join('\n')
      const toolTip =
        formattedContent === 'MPTS'
          ? 'Input contains multiple video streams'
          : tsInfos.map(tsInfoServiceName).join(', ')
      return (
        <Tooltip title={toolTip} placement="top">
          <Typography
            {...inputOutputColor({ adminStatus: input.adminStatus })}
            variant="body2"
            style={{ whiteSpace: 'pre-line' }}
          >
            {formattedContent}
          </Typography>
        </Tooltip>
      )
    },
  },
  {
    title: 'owner',
    getValue: ({ _owner }) => (
      <Link
        to={routes.groupsUpdate({ id: _owner?.id })}
        available={!!_owner?.id && isEditableGroup(_owner?.id, user)}
        underline="hover"
      >
        <Typography variant="body2" color="textSecondary">
          {!!_owner && _owner.name}
        </Typography>
      </Link>
    ),
    sorting: {
      byParameter: ListInputSortableField.ownerGroupName,
    },
  },
  {
    title: 'created',
    getValue: ({ createdAt, adminStatus }) => (
      <Tooltip title={format(new Date(createdAt), DATE_FORMAT_LONG)} placement="top">
        <Typography {...inputOutputColor({ adminStatus })} variant="body2">
          {format(new Date(createdAt), DATE_FORMAT_SHORT)}
        </Typography>
      </Tooltip>
    ),
    sorting: {
      byParameter: ListInputSortableField.creationDate,
    },
  },
  {
    title: '',
    getValue: (row) => (
      <ActionMenu input={row} user={user} dispatch={dispatch} experimentalFeatures={experimentalFeatures} />
    ),
    props: { noTypography: true },
  },
]

export default getConfig
