import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'

import { ListGroupSortableField, Role } from 'common/api/v1/types'
import {
  isEditableGroup,
  pluralize,
  useGroupsSelector,
  usePageParamsFilteredSelector,
  useSettingsSelector,
  useUser,
} from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import { EnrichedGroup } from '../../api/nm-types'
import {
  ListGroupFilterKey,
  makeListGroupsFilter,
  mapGroupFilterToUrlParam,
  mapUrlParamToGroupFilter,
} from './listGroupFilter'

import { useRoutes } from '../../store'
import { UrlParamFilteredSearchBar } from '../common/Filters/FilterView/FilteredSearchBar'

export const GroupsList = () => {
  const { groups, total, loading } = usePageParamsFilteredSelector(useGroupsSelector)
  const routes = useRoutes()
  const user = useUser()
  const { devMode } = useSettingsSelector()

  const tableConfig: TableConfig<EnrichedGroup, ListGroupSortableField> = [
    {
      title: 'name',
      getValue: (group) => (
        <Link available={isEditableGroup(group.id, user)} to={routes.groupsUpdate({ id: group.id })} underline="hover">
          {group.name}
        </Link>
      ),
      sorting: { byParameter: ListGroupSortableField.groupName },
    },
    {
      title: 'users',
      getValue: ({ id, userCount }) =>
        userCount !== undefined && isEditableGroup(id, user) ? pluralize(userCount, 'user') : null,
      sorting: { byParameter: ListGroupSortableField.numberOfUsers, hidden: user.role !== Role.super },
    },
    {
      title: 'appliances',
      getValue: ({ id, applianceCount }) =>
        applianceCount !== undefined && isEditableGroup(id, user) ? pluralize(applianceCount, 'appliance') : null,
      sorting: { byParameter: ListGroupSortableField.numberOfAppliances, hidden: user.role !== Role.super },
    },
    devMode
      ? {
          title: 'interfaces',
          getValue: ({ id, interfaceCount }) =>
            interfaceCount !== undefined && isEditableGroup(id, user) ? pluralize(interfaceCount, 'interface') : null,
          sorting: { byParameter: ListGroupSortableField.numberOfPorts, hidden: user.role !== Role.super },
        }
      : null,
    {
      getValue: ({ id }) => (
        <MoveToEntity url={routes.groupsUpdate({ id })} disabled={!isEditableGroup(id, user)} entityName="group" />
      ),
      props: { align: 'right', noTypography: true },
    },
  ]
  const filters = makeListGroupsFilter()
  return (
    <Wrapper
      name="Groups"
      actions={
        <>
          <Link key="manageGroupLists" type="button" to={routes.groupLists()}>
            <Button variant="outlined" color="primary">
              Manage group lists
            </Button>
          </Link>
          {user.role === Role.super && (
            <Link key="addGroup" id="groups-table-create-button" to={routes.groupsNew()}>
              <Button style={{ marginLeft: 8 }} variant="contained" color="primary">
                Add group
              </Button>
            </Link>
          )}
        </>
      }
    >
      <Toolbar disableGutters>
        <div style={{ flexShrink: 1, flexGrow: 1 }}>
          <UrlParamFilteredSearchBar
            urlParamCacheKey={'groups'}
            mapUrlParamToFilterFn={mapUrlParamToGroupFilter}
            mapFilterToUrlParamFn={mapGroupFilterToUrlParam}
            searchTokenFilters={filters}
            rawTextFilter={filters.find((f) => f.key === ListGroupFilterKey.name)!}
          />
        </div>
      </Toolbar>
      <Table<EnrichedGroup>
        emptyMessageComponent={
          <MissingContent message="No groups available" buttonText="Add group" url={routes.groupsNew()} />
        }
        config={tableConfig}
        data={groups}
        id="groups-table"
        pending={loading}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
