import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Typography from '@mui/material/Typography'
import { Theme, useTheme } from '@mui/material/styles'

import { AppDispatch, GlobalState } from '../../store'
import { clearCachedApplianceConfig, getApplianceConfig } from '../../redux/actions/applianceActions'
import { useSettingsSelector } from '../../utils'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import DataSet from '../common/DataSet'
import { ButtonsPane, GridItem, Paper } from '../common/Form'
import { ApplianceConfiguration } from 'common/api/v1/types'
import { ButtonsPaneProps } from '../common/Form/ButtonsPane'

const toStringified = (object: { [key: string]: any }) =>
  Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: String(value),
    }),
    {},
  )

interface RecursiveDataSetProps {
  object: { [key: string]: any } | string | number
  style?: React.CSSProperties
}
const RecursiveDataSet = ({ object, style }: RecursiveDataSetProps) => {
  const theme: Theme = useTheme()
  const entries = typeof object === 'object' ? Object.entries(object) : []
  const { flat, objects, arrays } = entries.reduce<{
    flat: { [key: string]: any }
    objects: Array<[string, any]>
    arrays: Array<[string, Array<any>]>
  }>(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc.arrays.push([key, value])
      } else if (value && typeof value === 'object') {
        acc.objects.push([key, value])
      } else {
        acc.flat = { ...acc.flat, [key]: value }
      }
      return acc
    },
    { flat: {}, objects: [], arrays: [] },
  )
  return (
    <div style={style}>
      <DataSet values={toStringified(entries.length > 0 ? flat : { '': object })} />
      {objects.map(([key, value], ind) => (
        <div key={`${key}-${ind}`}>
          <Typography variant="h3">{key}</Typography>
          <RecursiveDataSet object={value} style={{ marginLeft: theme.spacing(2) }} />
        </div>
      ))}
      {arrays.map(([key, value], ind) => (
        <div key={`${key}-${ind}`}>
          <Typography variant="h3">{key}</Typography>
          {value.map((item, i) => (
            <div key={`${item}-${i}`}>
              <Typography variant="h4">Item {i}</Typography>
              <RecursiveDataSet key={`${ind}-${i}`} object={item} style={{ marginLeft: theme.spacing(2) }} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

const ownClasses = {
  paperItem: {
    marginTop: 0,
    marginBottom: 0,
  },
}

type ApplianceConfigurationObjectsToShow = keyof Pick<
  ApplianceConfiguration,
  'inputs' | 'outputs' | 'tunnels' | 'channels' | 'settings' | 'vaInput' | 'vaOutput' | 'commands'
>
const initialState = (collapsed: boolean) => ({
  inputs: collapsed,
  outputs: collapsed,
  tunnels: collapsed,
  channels: collapsed,
  settings: collapsed,
  vaInput: collapsed,
  vaOutput: collapsed,
  commands: collapsed,
  firewallWhitelists: collapsed,
})
export const Config = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    id && dispatch(getApplianceConfig(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { devMode } = useSettingsSelector()
  const { config, loading }: { config?: ApplianceConfiguration; loading?: boolean } = useSelector(
    (state: GlobalState) => ({ config: state.appliancesReducer.config, loading: state.appliancesReducer.loading }),
    shallowEqual,
  )

  const objects: {
    [key in ApplianceConfigurationObjectsToShow]: string
  } = {
    inputs: 'Inputs',
    outputs: 'Outputs',
    tunnels: 'Tunnels',
    channels: 'Channels',
    settings: 'General Settings',
    vaInput: 'VA inputs',
    vaOutput: 'VA outputs',
    commands: 'Commands',
  }

  const [collapsed, setCollapsed] = useState(initialState(true))

  const bottomButtons: ButtonsPaneProps['main'] = {
    'Regenerate config': {
      disabled: !!loading,
      onClick: () => {
        void dispatch(clearCachedApplianceConfig(id!))
        return
      },
      primary: true,
    },
  }
  if (navigator.clipboard) {
    bottomButtons['Copy JSON'] = {
      onClick: () => {
        void navigator.clipboard.writeText(JSON.stringify(config))
        return
      },
      primary: true,
    }
  }

  return (
    <Wrapper name="Appliance configuration">
      <Pendable cover pending={!!id && loading && !!config}>
        <Paper title="Metadata">
          <GridItem xs={12} lg={6}>
            <DataSet
              values={{
                logLevel: config?.logLevel,
                ...(devMode && { ristserverLogLevel: config?.ristserverLogLevel }),
              }}
            />
          </GridItem>
        </Paper>
        <ButtonsPane
          main={
            Object.values(collapsed).some((c) => c)
              ? {
                  'Expand all': {
                    onClick: () => setCollapsed(initialState(false)),
                    primary: false,
                  },
                }
              : {
                  'Collapse all': {
                    onClick: () => setCollapsed(initialState(true)),
                    primary: false,
                  },
                }
          }
        />

        {config &&
          (Object.entries(objects) as Array<[ApplianceConfigurationObjectsToShow, string]>).map(([key, title]) => {
            const entry = config[key]
            if (!entry) {
              return null
            }
            const arrayEntry = Array.isArray(entry) ? entry : [entry]
            if (arrayEntry.length === 0) {
              return null
            }
            return (
              <Paper
                key={key}
                className="outlined"
                title={title}
                collapsible
                collapsed={collapsed[key]}
                onCollapseChange={(c) => setCollapsed({ ...collapsed, [key]: c })}
              >
                {arrayEntry.map((object: (typeof arrayEntry)[number], ind: number) => (
                  <GridItem key={`${key}-${ind}`} xs={12} lg={6}>
                    <Paper sx={ownClasses.paperItem}>
                      <RecursiveDataSet object={object} />
                    </Paper>
                  </GridItem>
                ))}
              </Paper>
            )
          })}
        {
          <Paper
            key={'firewallWhitelist'}
            className="outlined"
            title={'Firewall whitelists'}
            collapsible
            collapsed={collapsed['firewallWhitelists']}
            onCollapseChange={(c) => setCollapsed({ ...collapsed, firewallWhitelists: c })}
          >
            {config?.firewall.whitelists?.map(
              (object: ApplianceConfiguration['firewall']['whitelists'][number], ind: number) => (
                <GridItem key={`${'firewallWhitelist'}-${ind}`} xs={12} lg={6}>
                  <Paper sx={ownClasses.paperItem}>
                    <RecursiveDataSet object={object} />
                  </Paper>
                </GridItem>
              ),
            )}
          </Paper>
        }
        <ButtonsPane main={bottomButtons} />
      </Pendable>
    </Wrapper>
  )
}
