import { useDispatch } from 'react-redux'
import { useAsync } from 'react-async-hook'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MuiLink from '@mui/material/Link'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '../common/Tooltip'
import OpenInNew from '@mui/icons-material/OpenInNew'
import Circle from '@mui/icons-material/FiberManualRecord'
import HelpOutline from '@mui/icons-material/HelpOutline'
import { ListServiceSortableField, Service } from 'common/api/v1/types'
import { usePageParamsFiltered, useSettingsSelector } from '../../utils'
import { Link } from '../common/Link'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { getServices } from '../../redux/actions/serviceActions'
import { ServicesRequestParams } from '../../api/nm-types'
import { Api, AppDispatch, useRoutes } from '../../store'
import {
  ListK8sServiceFilterKey,
  makeListK8sServicesFilter,
  mapK8sFilterToUrlParam,
  mapUrlParamToK8sServiceFilter,
} from './listServiceFilter'
import { ProductLink } from './Product'
import { UrlParamFilteredSearchBar } from '../common/Filters/FilterView/FilteredSearchBar'
import Pendable from '../common/Pendable'

const { serviceApi } = Api

const Toggle = ({ value }: { value: boolean }) => {
  return value ? <Circle /> : null
}

export const ListView = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { devMode } = useSettingsSelector()
  const searchParams = { includeHidden: devMode, ...usePageParamsFiltered<ServicesRequestParams>() }
  const { result } = useAsync(() => getServices(searchParams, { dispatch, serviceApi }), [JSON.stringify(searchParams)])
  const routes = useRoutes()
  const tableConfig: TableConfig<Service, ListServiceSortableField> = [
    {
      title: 'product',
      getValue: (service) => (
        <>
          <Link to={routes.servicesUpdate({ id: service.id })} underline="hover">
            {service.product}
          </Link>
          <ProductLink product={service.product} enabled={service.enabled} />
        </>
      ),
      sorting: { byParameter: ListServiceSortableField.product },
    },
    {
      title: 'purpose',
      getValue: ({ purpose, description }) => (
        <Box sx={{ display: 'flex' }}>
          {purpose}
          {description && (
            <Tooltip title={description}>
              <HelpOutline sx={{ marginLeft: 1 }} />
            </Tooltip>
          )}
        </Box>
      ),
      sorting: { byParameter: ListServiceSortableField.purpose },
    },
    {
      title: 'collect logs',
      getValue: ({ collectLogs }) => <Toggle value={collectLogs} />,
      props: { noTypography: true },
    },
    {
      getValue: (svc) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <MoveToEntity url={routes.servicesUpdate({ id: svc.id })} entityName="service" />

            {svc.filterUrl && (
              <Tooltip title="View logs">
                <span>
                  <MuiLink href={svc.filterUrl} target="_blank">
                    <IconButton edge="end" aria-label="View logs">
                      <OpenInNew />
                    </IconButton>
                  </MuiLink>
                </span>
              </Tooltip>
            )}
          </Box>
        )
      },
      props: { align: 'right', noTypography: true },
    },
  ]

  const filters = makeListK8sServicesFilter()
  return (
    <Wrapper name="Services">
      <Toolbar disableGutters>
        <div style={{ flexShrink: 1, flexGrow: 1 }}>
          <UrlParamFilteredSearchBar
            urlParamCacheKey={'k8sServices'}
            mapUrlParamToFilterFn={mapUrlParamToK8sServiceFilter}
            mapFilterToUrlParamFn={mapK8sFilterToUrlParam}
            searchTokenFilters={filters}
            rawTextFilter={filters.find((f) => f.key === ListK8sServiceFilterKey.name)!}
          />
        </div>
      </Toolbar>
      <Pendable pending={!result}>
        {result && (
          <Table<Service>
            emptyMessageComponent={<MissingContent message="No services available" />}
            config={tableConfig}
            data={result.items}
            id="services-table"
            pagination={{ total: result.total, useUrlSearchParams: true }}
          />
        )}
      </Pendable>
    </Wrapper>
  )
}
