import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'

import { ListResult } from 'common/api/v1/types'
import { withDefaultPagination } from '../../utils'
import { AdAuditLogRequestParams } from '../../api/nm-types'
import { AdAuditLogMessage } from 'common/types'

export const getAdAudit = createAsyncThunk<ListResult<AdAuditLogMessage>, Partial<AdAuditLogRequestParams>, ThunkApi>(
  'audit/getAdAudit',
  async (params, { extra: { api } }) => {
    return await api.adAuditApi.getAdAudit(withDefaultPagination(params))
  },
)
