import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Adjust from '@mui/icons-material/Adjust'
import MoreVert from '@mui/icons-material/MoreVert'
import Settings from '@mui/icons-material/Settings'

import { useRoutes, type AppDispatch } from '../../store'
import { setDefaultRegion } from '../../redux/actions/regionsActions'
import type { EnrichedRegion } from '../../api/nm-types'

interface ActionMenuProps {
  region: EnrichedRegion
  disabled?: boolean
}

export const ActionMenu = ({ region, disabled }: ActionMenuProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const routes = useRoutes()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleItemClick = (event: React.MouseEvent<HTMLElement>, route?: string) => {
    event.stopPropagation()
    setAnchorEl(null)
    if (route) navigate(route)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick} disabled={disabled} aria-label="open region action menu">
        <MoreVert />
      </IconButton>
      <Menu open={open} keepMounted anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={(e) => handleItemClick(e, routes.regionsUpdate({ id: region.id }))} aria-label="edit region">
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleItemClick(e)
            dispatch(setDefaultRegion({ regionId: region.id, regionName: region.name }))
          }}
        >
          <ListItemIcon>
            <Adjust />
          </ListItemIcon>
          <ListItemText primary="Set as default" />
        </MenuItem>
      </Menu>
    </>
  )
}
