import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, ThunkApi } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { AdServer, AdServerInit, DeletedAdServer } from 'common/types'
import { PaginatedRequestParams } from '../../api/nm-types'
import { withDefaultPagination } from '../../utils'
import { IAdServersApi } from '../../api/adServers/api'
import { ListResult } from 'common/api/v1/types'

// for react-async-hook
export const listAdServersHook = async (
  params: Partial<PaginatedRequestParams>,
  { dispatch, adServersApi }: { dispatch: AppDispatch; adServersApi: IAdServersApi },
) => {
  try {
    return await adServersApi.listAdServers(withDefaultPagination(params))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch overlays' }))
    throw err
  }
}

export const listAdServers = createAsyncThunk<ListResult<AdServer>, PaginatedRequestParams, ThunkApi>(
  'adServers/listAdServers',
  async (params, { dispatch, extra: { api } }) => {
    try {
      return await api.adServersApi.listAdServers(params)
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'fetch Ad Servers' }))
      throw error
    }
  },
)

export const getAdServer = createAsyncThunk<AdServer, string, ThunkApi>(
  'adServers/getAdServer',
  async (id, { dispatch, extra: { api } }) => {
    try {
      return await api.adServersApi.getAdServer(id)
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'fetch Ad Server' }))
      throw error
    }
  },
)

export const createAdServer = createAsyncThunk<AdServerInit, AdServer, ThunkApi>(
  'adServers/createAdServer',
  async (adServer, { dispatch, extra: { routes, api, navigate } }) => {
    try {
      const res = await api.adServersApi.createAdServer(adServer)
      navigate()(routes.adInsertion())
      dispatch(enqueueSuccessSnackbar('Ad Server created'))
      return res
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'create Ad Server' }))
      throw error
    }
  },
)

export const updateAdServer = createAsyncThunk<AdServer, AdServer, ThunkApi>(
  'adServers/updateAdServer',
  async (adServer, { dispatch, extra: { routes, api, navigate } }) => {
    try {
      const res = await api.adServersApi.updateAdServer(adServer.id, adServer)
      navigate()(routes.adInsertion())
      dispatch(enqueueSuccessSnackbar('Ad Server saved'))
      return res
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'update Ad Server' }))
      throw error
    }
  },
)

export const deleteAdServer = createAsyncThunk<DeletedAdServer, string, ThunkApi>(
  'adServers/deleteAdServer',
  async (id, { dispatch, extra: { api } }) => {
    try {
      const res = await api.adServersApi.deleteAdServer(id)
      dispatch(enqueueSuccessSnackbar('Ad Server deleted'))
      return res
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'delete Ad Server' }))
      throw error
    }
  },
)

export const clearAdServer = createAction('adServers/clearAdServer')
export const clearAdServers = createAction('adServers/clearAdServers')
