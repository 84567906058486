import { ButtonsPane, GridItem, Paper } from '../../common/Form'
import Table from '../../common/Table'
import { useNavigate } from 'react-router'
import { Api, AppDispatch, useRoutes } from '../../../store'
import { Link } from '../../common/Link'
import { useDispatch } from 'react-redux'
import { deleteOverlay, listOverlaysHook } from '../../../redux/actions/overlaysActions'
import { DeleteButton } from '../../common/DeleteButton'
import { Overlay } from 'common/api/v1/types'
import Pendable from '../../common/Pendable'
import { usePageParamsFiltered } from '../../../utils'
import { PaginatedRequestParams } from '../../../api/nm-types'
import { useAsync } from 'react-async-hook'
const { overlaysApi } = Api

export const OverlaysList = () => {
  const navigate = useNavigate()
  const routes = useRoutes()
  const dispatch = useDispatch<AppDispatch>()

  const searchParams = usePageParamsFiltered<PaginatedRequestParams>()

  const { result, execute: refresh } = useAsync(
    () => listOverlaysHook(searchParams, { dispatch, overlaysApi }),
    [JSON.stringify(searchParams)],
  )

  return (
    <Paper title="Overlays">
      <GridItem lg={12} xl={12}>
        <Pendable pending={!result}>
          {result && (
            <Table<Overlay>
              emptyMessageComponent="No overlays found"
              data={result.items}
              pagination={{
                total: result.total,
                useUrlSearchParams: true,
              }}
              config={[
                {
                  title: 'name',
                  getValue: ({ name, id: overlayId }) => (
                    <Link
                      underline="hover"
                      to={routes.overlaysUpdate({ id: overlayId })}
                      style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
                      muiStyle={{
                        gap: 12,
                      }}
                    >
                      {name}
                    </Link>
                  ),
                },
                {
                  getValue: ({ id: overlayId }) =>
                    overlayId && (
                      <DeleteButton
                        callback={() => dispatch(deleteOverlay(overlayId)).then(() => refresh())}
                        confirmMessage="Are you sure you want to delete this overlay? Removing an overlay used by an active stream will cause it to glitch for a moment."
                      />
                    ),
                  props: { align: 'right', noTypography: true },
                },
              ]}
            />
          )}
        </Pendable>
      </GridItem>
      <GridItem lg={12} xl={12}>
        <ButtonsPane
          main={{
            'Create overlay': { primary: true, onClick: () => navigate(routes.overlaysNew()) },
          }}
        />
      </GridItem>
    </Paper>
  )
}
