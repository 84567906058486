import { createSlice } from '@reduxjs/toolkit'

import {
  clearOutputFormErrors,
  clearOutputs,
  createOutput,
  disableOutputs,
  Draft,
  draftOutputs,
  enableOutputs,
  getBareOutputs,
  getOutputs,
  getOutputsWithLists,
  getTotalOutputCount,
  registerOutputObserver,
  removeOutputs,
  setInputOfOutput,
  unregisterOutputObserver,
  updateOutput,
} from '../actions/outputsActions'
import { logoutUser } from '../actions/userActions'
import { Output, OutputRecipientList } from 'common/api/v1/types'
import { EnrichedOutput } from '../../api/nm-types'
import type { ApplicationException } from '../../api/ApplicationException'
import { isOneOf } from '../actions'
import { getService } from '../actions/serviceOverviewActions'
import { isOutput } from '../../utils'
import { createLoadingReducer } from './shared'

export interface State {
  formErrors?: ApplicationException['errorInfo']['details']
  loading: boolean
  outputs: Array<EnrichedOutput>
  outputsWithLists: Array<EnrichedOutput | OutputRecipientList>
  outputsToObserve: { [outputId: string]: number }
  saving?: boolean
  total: number
  draft: Draft
  observedOutputs: Array<Output>
}
const initialStateOutputs: State = {
  loading: false,
  outputs: [],
  outputsWithLists: [],
  outputsToObserve: {},
  total: 0,
  draft: { outputs: [] },
  observedOutputs: [],
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(
  getOutputs,
  removeOutputs,
  getOutputsWithLists,
  getTotalOutputCount,
)

const outputsSlice = createSlice({
  name: 'outputs',
  initialState: initialStateOutputs,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOutput.fulfilled, (state): State => ({ ...state, saving: undefined, formErrors: undefined }))
      .addCase(
        updateOutput.fulfilled,
        (state, { payload: updatedOutput }): State => ({
          ...state,
          saving: undefined,
          outputs: state.outputs.map((outp) => (outp.id === updatedOutput.id ? { ...updatedOutput } : outp)),
        }),
      )
      .addCase(
        getOutputs.fulfilled,
        (state, { payload: { items: outputs, total } }): State => ({
          ...state,
          outputs,
          total,
        }),
      )
      .addCase(
        getOutputsWithLists.fulfilled,
        (state, { payload: { items: outputsWithLists, total } }): State => ({
          ...state,
          outputsWithLists,
          total,
        }),
      )
      .addCase(getTotalOutputCount.fulfilled, (state, { payload: total }): State => ({ ...state, total }))
      .addCase(clearOutputFormErrors, (state): State => ({ ...state, formErrors: undefined }))
      .addCase(clearOutputs, (state): State => ({ ...state, outputs: [], outputsWithLists: [] }))
      .addCase(
        draftOutputs,
        (state, { payload: draft }): State => ({
          ...state,
          draft,
        }),
      )
      .addCase(logoutUser.fulfilled, (): State => initialStateOutputs)
      .addCase(
        registerOutputObserver.pending,
        (
          state,
          {
            meta: {
              arg: { outputIds },
            },
          },
        ): State => {
          const outputsToObserve = { ...state.outputsToObserve }
          for (const outputId of outputIds) {
            outputsToObserve[outputId] = (outputsToObserve[outputId] || 0) + 1
          }
          return { ...state, outputsToObserve }
        },
      )
      .addCase(unregisterOutputObserver, (state, { payload: { outputIds } }): State => {
        const newObservers = { ...state.outputsToObserve }
        for (const outputId of outputIds) {
          const numberOfObservers = (newObservers[outputId] || 0) - 1
          if (numberOfObservers < 1) {
            delete newObservers[outputId]
          } else {
            newObservers[outputId] = numberOfObservers
          }
        }

        const observedOutputIds = Object.keys(newObservers)
        return {
          ...state,
          outputsToObserve: newObservers,
          observedOutputs: state.observedOutputs.filter((o) => observedOutputIds.includes(o.id)),
        }
      })
      .addCase(getBareOutputs.fulfilled, (state, { payload: bareOutputs }): State => {
        const updateOutputMetrics = <T extends Output>(enrichedOutput: T): T => {
          const bareOutput = bareOutputs.items.find((o) => o.id === enrichedOutput.id)
          return bareOutput
            ? { ...enrichedOutput, metrics: bareOutput.metrics, health: bareOutput.health }
            : enrichedOutput
        }

        const observedOutputIds = Object.keys(state.outputsToObserve)
        const observedOutputs: Output[] = observedOutputIds
          .map((id) => bareOutputs.items.find((i) => i.id === id) as Output)
          .filter(Boolean)

        return {
          ...state,
          outputs: state.outputs.map(updateOutputMetrics),
          outputsWithLists: state.outputsWithLists.reduce((acc, outputOrRecipientList) => {
            acc.push(
              isOutput(outputOrRecipientList) ? updateOutputMetrics(outputOrRecipientList) : outputOrRecipientList,
            )
            return acc
          }, [] as Array<EnrichedOutput | OutputRecipientList>),
          observedOutputs,
        }
      })
      .addCase(
        getService.fulfilled,
        (
          state,
          {
            payload: {
              objects: { outputs },
            },
          },
        ) => ({
          ...state,
          outputs: outputs.items,
        }),
      )
      .addCase(enableOutputs.fulfilled, (state, { payload: enableOutputs }): State => {
        const enableOutput = (output: Output): Output => {
          const enabledOutput = enableOutputs.find((o) => o.id === output.id)
          if (enabledOutput) {
            return { ...output, adminStatus: enabledOutput.adminStatus }
          }
          return output
        }

        return {
          ...state,
          outputs: state.outputs.map(enableOutput),
          observedOutputs: state.observedOutputs.map(enableOutput),
        }
      })
      .addCase(disableOutputs.fulfilled, (state, { payload: disableOutputs }): State => {
        const disableOutput = (output: Output): Output => {
          const disabledOutput = disableOutputs.find((o) => o.id === output.id)
          if (disabledOutput) {
            return { ...output, adminStatus: disabledOutput.adminStatus }
          }
          return output
        }
        return {
          ...state,
          outputs: state.outputs.map(disableOutput),
          observedOutputs: state.observedOutputs.map(disableOutput),
        }
      })
      .addCase(
        setInputOfOutput.fulfilled,
        (state, { payload: updatedOutput }): State => ({
          ...state,
          saving: undefined,
          outputs: state.outputs.map((outp) => (outp.id === updatedOutput.id ? { ...updatedOutput } : outp)),
        }),
      )
      .addMatcher(
        isOneOf([createOutput.pending, updateOutput.pending, setInputOfOutput.pending]),
        (state): State => ({ ...state, saving: true, formErrors: undefined }),
      )
      .addMatcher(
        isOneOf([createOutput.rejected, updateOutput.rejected, setInputOfOutput.rejected]),
        (state, { payload }): State => ({
          ...state,
          saving: false,
          formErrors: payload?.details,
        }),
      )
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default outputsSlice.reducer

export const selectOutputs = ({ outputsReducer }: { outputsReducer: State }) => ({
  outputs: outputsReducer.outputs,
  total: outputsReducer.total,
})
