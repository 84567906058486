import { useState } from 'react'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Switch from '@mui/material/Switch'
import { Theme } from '@mui/material/styles'

import { GroupInputPermission, Input, InputAccessType, Group } from 'common/api/v1/types'
import { Api } from '../../../../store'
import { Paper } from '../../../common/Form'
import PaginatedList from '../../../common/SelfStatePaginatedList'
import { PermissionAction } from '../../../../api/inputs/api'
import { EnrichedGroup, GroupsRequestParams } from '../../../../api/nm-types'

const styles = {
  paper: {
    padding: (theme: Theme) => theme.spacing(1),
    margin: (theme: Theme) => theme.spacing(0, 0, 2),
    boxShadow: 'none',
    border: (theme: Theme) => `1px solid ${theme.palette.grey['800']}`,
  },
  button: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    '&.cancel': {
      color: (theme: Theme) => theme.palette.error.main,
      borderColor: (theme: Theme) => theme.palette.error.main,
    },
  },
}

const groupApi = Api.groupsApi

export type GroupWithPermission = EnrichedGroup & { _permission?: GroupInputPermission }
interface RecipientsProps {
  input: Input
  onRemove: (group: GroupWithPermission) => void
  onSwitch: (group: GroupWithPermission) => void
  onCancel: (group: GroupWithPermission) => void
  changes: { [key: string]: PermissionAction }
}
const Recipients = ({ input, onCancel, onRemove, onSwitch, changes }: RecipientsProps) => {
  const [hideFilter, setHideFilter] = useState(true)
  const [total, setTotal] = useState(0)

  return (
    <Paper
      collapsible
      title={`Current recipients${total ? ` (${total})` : ''}`}
      sx={styles.paper}
      collapsed
      titleTypography="h3"
    >
      <PaginatedList<GroupsRequestParams, Group>
        api={groupApi.getGroupPermissions.bind(groupApi)}
        emptyMessage="no recipients"
        hideSearch={hideFilter}
        notFoundMessage="no matching groups"
        onListChange={({ filter, total = 0 }) => {
          setHideFilter(!filter && total < 10)
          setTotal(total)
        }}
        otherParams={{ inputShared: input.id, userGroup: input.owner }}
        searchPlaceholder="Find specific group…"
        List={({ list }) => (
          <List disablePadding data-test-id="shared-to">
            {list.map((item) => {
              const group = item as GroupWithPermission
              let checked = group._permission?.accessType === InputAccessType.pull
              const change = changes[group.id]
              if (change === PermissionAction.change) checked = !checked
              return (
                <ListItem disableGutters key={item.id} divider>
                  <ListItemText
                    primaryTypographyProps={{ color: change ? 'textSecondary' : 'textPrimary' }}
                    data-test-id="group-name"
                  >
                    {group.name}
                  </ListItemText>
                  {!change || change === PermissionAction.change ? (
                    <>
                      <Typography variant="body2" color={checked ? 'textSecondary' : 'textPrimary'}>
                        Preview
                      </Typography>
                      <Switch
                        checked={checked}
                        onChange={() => (change ? onCancel(group) : onSwitch(group))}
                        color="primary"
                      />
                      <Typography
                        variant="body2"
                        color={checked ? 'textPrimary' : 'textSecondary'}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        Full Access
                      </Typography>
                    </>
                  ) : null}
                  {change ? (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => onCancel(group)}
                      sx={styles.button}
                      className="cancel"
                    >
                      {`Cancel ${change === PermissionAction.change ? 'switch' : 'remove'}`}
                    </Button>
                  ) : (
                    <Button
                      data-test-id="remove-btn"
                      size="small"
                      variant="outlined"
                      onClick={() => onRemove(group)}
                      sx={styles.button}
                    >
                      Remove
                    </Button>
                  )}
                </ListItem>
              )
            })}
          </List>
        )}
      />
    </Paper>
  )
}

export default Recipients
