import Button from '@mui/material/Button'

import type { GroupRecipientList, GroupRecipientListSortableField } from 'common/api/v1/types'
import { useGroupListsSelector, usePageParamsFilteredSelector } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'

import { useRoutes } from '../../store'
import Toolbar from '@mui/material/Toolbar'
import { UrlParamFilteredSearchBar } from '../common/Filters/FilterView/FilteredSearchBar'
import {
  ListGroupListFilterKey,
  makeListGroupListsFilter,
  mapGroupListFilterToUrlParam,
  mapUrlParamToGroupListFilter,
} from './listGroupListsFilter'

export const ListView = () => {
  const routes = useRoutes()
  const { groupLists, total, loading } = usePageParamsFilteredSelector(useGroupListsSelector)

  const tableConfig: TableConfig<GroupRecipientList, GroupRecipientListSortableField> = [
    {
      title: 'name',
      getValue: (groupList) => <Link to={routes.groupListsUpdate({ id: groupList.id })}>{groupList.name}</Link>,
      sorting: { byParameter: 'name' },
    },
    {
      title: 'description',
      getValue: ({ description }) => description,
      sorting: { byParameter: 'description' },
    },
    {
      getValue: ({ id }) => <MoveToEntity url={routes.groupListsUpdate({ id })} entityName="group list" />,
      props: { align: 'right' },
    },
  ]

  const filters = makeListGroupListsFilter()
  return (
    <Wrapper
      name={['Groups', 'Lists']}
      actions={
        <Link key="groupListCreate" type="button" to={routes.groupListsNew()}>
          <Button variant="contained" color="primary">
            Add group list
          </Button>
        </Link>
      }
    >
      <Toolbar disableGutters>
        <div style={{ flexShrink: 1, flexGrow: 1 }}>
          <UrlParamFilteredSearchBar
            urlParamCacheKey={'groupLists'}
            mapUrlParamToFilterFn={mapUrlParamToGroupListFilter}
            mapFilterToUrlParamFn={mapGroupListFilterToUrlParam}
            searchTokenFilters={filters}
            rawTextFilter={filters.find((f) => f.key === ListGroupListFilterKey.name)!}
          />
        </div>
      </Toolbar>
      <Table<GroupRecipientList>
        id="grouplists-table"
        emptyMessageComponent={
          <MissingContent message="No group lists" buttonText="Add group list" url={routes.groupListsNew()} />
        }
        config={tableConfig}
        data={groupLists}
        pending={loading}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
