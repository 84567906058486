import Delete from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { useConfirmationDialog } from '../../utils/hooks'

export const DeleteButton = ({ callback, confirmMessage }: { callback: () => void; confirmMessage: string }) => {
  const setConfirm = useConfirmationDialog()

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    setConfirm(() => {
      callback()
    }, confirmMessage)
  }

  return (
    <Stack direction="row" justifyContent="flex-end">
      <IconButton
        onClick={(e) => {
          handleDelete(e)
        }}
      >
        <Delete />
      </IconButton>
    </Stack>
  )
}
