import type { BrowserEdgeClient } from './network'
import { InputApi } from './inputs/api'
import { OutputApi } from './outputs/api'
import { UserApi } from './user/api'
import { ServiceApi } from './service/api'
import { AppliancesApi } from './appliances/api'
import { PortsApi } from './ports/api'
import { GroupsApi } from './groups/api'
import { AlarmsApi } from './alarms/api'
import { AlarmLogsApi } from './alarmLogs/api'
import { BitratesApi } from './bitrates/api'
import { BillingApi } from './billing/api'
import { UsageApi } from './usage/api'
import { GraphApi } from './graph/api'
import { LocationApi } from './location/api'
import { RegionApi } from './region/api'
import { OutputListApi } from './outputLists/api'
import { GroupListApi } from './groupLists/api'
import { AdAuditApi } from './adAudit/api'
import { SettingsApi } from './settings/api'
import { StatusApi } from './status/api'
import { ImageApi } from './image/api'
import { TlsApi } from './tls/api'
import { BuildInfoApi } from './buildInfo/api'
import { AddressMappingApi } from './addressMappings/api'
import { KubernetesApi } from './kubernetes/api'
import { NetworksApi } from './networks/api'
import { ApiTokenApi } from './apiTokens/api'
import { TunnelApi } from './tunnel/api'
import { OverlaysApi } from './overlays/api'
import { TagApi } from './tags/api'
import { AdServersApi } from './adServers/api'
import { AuditApi } from './audit/api'
import { AdInsertionDemoApi } from './adInsertionDemo/api'

export class Api {
  readonly adAuditApi
  readonly addressMappingsApi
  readonly adInsertionDemoApi
  readonly apiTokensApi
  readonly alarmsApi
  readonly alarmLogsApi
  readonly appliancesApi
  readonly auditApi
  readonly billingApi
  readonly usageApi
  readonly bitratesApi
  readonly graphApi
  readonly groupListsApi
  readonly imageApi
  readonly inputApi
  readonly locationApi
  readonly groupsApi
  readonly outputApi
  readonly outputListsApi
  readonly portsApi
  readonly regionApi
  readonly overlaysApi
  readonly adServersApi
  readonly settingsApi
  readonly statusApi
  readonly tagApi
  readonly tlsApi
  readonly tunnelApi
  readonly userApi
  readonly serviceApi
  readonly buildInfoApi
  readonly kubernetesApi
  readonly networksApi
  constructor(readonly edgeClient: BrowserEdgeClient) {
    this.adAuditApi = new AdAuditApi(this.edgeClient)
    this.addressMappingsApi = new AddressMappingApi(this.edgeClient)
    this.adInsertionDemoApi = new AdInsertionDemoApi(this.edgeClient)
    this.apiTokensApi = new ApiTokenApi(this.edgeClient)
    this.alarmsApi = new AlarmsApi(this.edgeClient)
    this.alarmLogsApi = new AlarmLogsApi(this.edgeClient)
    this.appliancesApi = new AppliancesApi(this.edgeClient)
    this.auditApi = new AuditApi(this.edgeClient)
    this.billingApi = new BillingApi(this.edgeClient)
    this.usageApi = new UsageApi(this.edgeClient)
    this.bitratesApi = new BitratesApi(this.edgeClient)
    this.graphApi = new GraphApi(this.edgeClient)
    this.groupListsApi = new GroupListApi(this.edgeClient)
    this.imageApi = new ImageApi(this.edgeClient)
    this.inputApi = new InputApi(this.edgeClient)
    this.locationApi = new LocationApi()
    this.groupsApi = new GroupsApi(this.edgeClient)
    this.outputApi = new OutputApi(this.edgeClient)
    this.outputListsApi = new OutputListApi(this.edgeClient)
    this.portsApi = new PortsApi(this.edgeClient)
    this.regionApi = new RegionApi(this.edgeClient)
    this.overlaysApi = new OverlaysApi(this.edgeClient)
    this.adServersApi = new AdServersApi(this.edgeClient)
    this.settingsApi = new SettingsApi(this.edgeClient)
    this.statusApi = new StatusApi(this.edgeClient)
    this.tagApi = new TagApi(this.edgeClient)
    this.tlsApi = new TlsApi(this.edgeClient)
    this.userApi = new UserApi(this.edgeClient)
    this.serviceApi = new ServiceApi(this.edgeClient)
    this.buildInfoApi = new BuildInfoApi(this.edgeClient)
    this.kubernetesApi = new KubernetesApi(this.edgeClient)
    this.networksApi = new NetworksApi(this.edgeClient)
    this.tunnelApi = new TunnelApi(this.edgeClient)
  }

  isAborted(...args: Parameters<typeof this.edgeClient.isAborted>) {
    return this.edgeClient.isAborted(...args)
  }
}
