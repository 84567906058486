import { FC, ReactNode, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/icons-material/Menu'

import { toggleSidebarMenu } from '../../redux/actions/uiActions'
import { useSettingsSelector } from '../../utils'
import { AppDispatch, useRoutes } from '../../store'
import { PRODUCTS } from 'common/constants'
import { defaultCommon } from '../../themes/common'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link } from './Link'
import { notUndefinedOrNull } from 'common/api/v1/helpers'
import { matchPath, useLocation } from 'react-router'

const styles = {
  spacer: {
    flexGrow: 1,
  },
}

interface BreadcrumbWrapperProps {
  entities: string[]
}

// Find the parent path for the breadcrumbs
function getParentPath(routesList: string[], pathname: string, entityIndex: number): string | null {
  function getRawParentPath(): string | null {
    // do not link the breadcrumb for the service overview since there is no parent
    if (pathname.startsWith('/service')) return null

    const parts = pathname.split('/').filter(Boolean)
    let countOfParentParts = entityIndex + 1
    // settings menu is separate so we need to keep it in the path
    if (parts[0] === 'settings') {
      countOfParentParts++
    }

    return `/${parts.slice(0, countOfParentParts).join('/')}`
  }

  const rawParentPath = getRawParentPath()
  if (!rawParentPath) return null

  // We need to match the parent path with the routes from redux store to get the correct filter params
  const parentPathWithFilterParams = routesList.find((route) => {
    const routeWithoutQueryParams = route.split('?')[0]
    // search for an exact match in the route list (without query params) and return the one that matches
    return matchPath({ path: routeWithoutQueryParams, end: true }, rawParentPath)
  })

  return parentPathWithFilterParams ?? rawParentPath
}

function BreadcrumbWrapper({ entities }: BreadcrumbWrapperProps) {
  const { pathname } = useLocation()
  const routes = useRoutes()
  const routesList = useMemo(() => {
    return Object.values(routes).map((routeFunc) => routeFunc())
  }, [routes])

  const separator = (
    <Typography key="separator" component="h1" variant="h2" color="inherit" noWrap>
      /
    </Typography>
  )

  const genBreadcrumbTextElement = (text: string, index: number) => (
    <Typography
      key={`breadcrumb-${text}`}
      data-testid={`breadcrumb-${index}-text`}
      component="h1"
      variant="h2"
      color="inherit"
      noWrap
    >
      {text}
    </Typography>
  )
  const genBreadcrumbLinkElement = (text: string, link: string, index: number) => (
    <Link key={`breadcrumb-${text}-link`} data-testid={`breadcrumb-${index}-link`} underline="hover" to={link}>
      {genBreadcrumbTextElement(text, index)}
    </Link>
  )
  const genBreadcrumbElement = (listLength: number, text: string, index: number) => {
    if (index < listLength - 1) {
      const parentPath = getParentPath(routesList, pathname, index)
      if (parentPath) {
        return genBreadcrumbLinkElement(text, parentPath, index)
      }
    }
    return genBreadcrumbTextElement(text, index)
  }

  // make all the breadcrumbs link except the last one
  return (
    <Breadcrumbs sx={{ color: 'inherit' }} separator={separator} aria-label="breadcrumb">
      {entities.map((text, index) => genBreadcrumbElement(entities.length, text, index))}
    </Breadcrumbs>
  )
}

const LicenseWarning = ({ daysUntilLicenseExpires }: { daysUntilLicenseExpires: number }) => (
  <div
    style={{
      textAlign: 'center',
      margin: '0 -24px',
      padding: '12px',
      fontSize: '20px',
      backgroundColor: (defaultCommon.palette?.error as any).main ?? 'red',
    }}
  >
    {daysUntilLicenseExpires >= 0 ? (
      <>
        The license will expire in <b>{daysUntilLicenseExpires}</b> day(s)
      </>
    ) : (
      'The license has expired'
    )}
  </div>
)

export interface WrapperProps {
  name: string | (string | undefined)[]
  isStreamManager?: boolean
  actions?: ReactNode
  children: ReactNode
  fullWidth?: boolean
}
/**
 * Common wrapper for edge manager page content
 * @param name - the entities of the page (could be a string or an array)
 * @param isStreamManager - true for Stream Manager.
 * @param children - content of the page
 */
const Wrapper: FC<WrapperProps> = ({ name, isStreamManager, children, actions, fullWidth }) => {
  // Styles to be applied on the <Pagination> component's closest scrollable ancestor
  // in order to make it able to float/be sticky.
  const floatingPaginationStyles = {
    position: 'relative',
    height: '100%',
    width: '100%',
    ...(fullWidth ? {} : { maxWidth: '1920px' }),
  } as const

  // Ensure that name is an array of strings, and remove undefined child entities
  const entities = (Array.isArray(name) ? name : [name]).filter(notUndefinedOrNull)
  const dispatch = useDispatch<AppDispatch>()
  const ToggleSidebarMenuAction = () => dispatch(toggleSidebarMenu())

  const { settings } = useSettingsSelector()
  const entitlements = settings?.entitlements
  let daysUntilLicenseExpires = Infinity
  if (entitlements && entitlements.product === PRODUCTS.connectIt.id) {
    if (entitlements.expiresAt) {
      const now = new Date()
      daysUntilLicenseExpires = Math.floor((entitlements.expiresAt.getTime() - now.getTime()) / (1000 * 60 * 60 * 24))
    }
  }
  const showLicenseWarning =
    entitlements?.product === PRODUCTS.connectIt.id && !isStreamManager && daysUntilLicenseExpires <= 14

  return (
    <section style={floatingPaginationStyles}>
      {showLicenseWarning && entitlements.expiresAt && (
        <LicenseWarning daysUntilLicenseExpires={daysUntilLicenseExpires} />
      )}
      <AppBar position="relative" data-test-id="app-bar" color="transparent">
        <Toolbar disableGutters>
          {!isStreamManager ? (
            <IconButton
              data-test-id="toggle-sidebar-btn"
              edge="start"
              color="inherit"
              aria-label="Toggle sidebar"
              onClick={ToggleSidebarMenuAction}
            >
              <Menu />
            </IconButton>
          ) : null}

          <BreadcrumbWrapper entities={entities} />

          <Box sx={styles.spacer} />
          {actions}
        </Toolbar>
      </AppBar>
      {children}
    </section>
  )
}

export default Wrapper
