import { createLoadingReducer } from './shared'
import { createSlice } from '@reduxjs/toolkit'
import { logoutUser } from '../actions/userActions'
import { AdServer } from 'common/types'
import type { ApplicationException } from '../../api/ApplicationException'
import { clearAdServer, deleteAdServer, getAdServer, listAdServers, updateAdServer } from '../actions/adServerActions'

interface State {
  formErrors?: ApplicationException['errorInfo']['details'] // unused
  loading: boolean
  saving: boolean
  adServers: AdServer[]
  selectedAdServer?: AdServer
}

export const initialStateAdInsertionReducer: State = {
  loading: false,
  saving: false,
  adServers: [],
  selectedAdServer: undefined,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(listAdServers)

const adInsertionSlice = createSlice({
  name: 'adServers',
  initialState: initialStateAdInsertionReducer,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdServer.fulfilled, (state, { payload: selectedAdServer }): State => ({ ...state, selectedAdServer }))
      .addCase(clearAdServer, (state): State => ({ ...state, selectedAdServer: undefined, formErrors: undefined }))

      .addCase(updateAdServer.pending, (state): State => ({ ...state, saving: true }))
      .addCase(updateAdServer.rejected, (state): State => ({ ...state, saving: false }))
      .addCase(
        updateAdServer.fulfilled,
        (state, { payload: adServer }): State => ({
          ...state,
          saving: false,
          adServers: [adServer, ...state.adServers.filter((o) => o.id !== adServer.id)],
          formErrors: undefined,
        }),
      )
      .addCase(deleteAdServer.pending, (state): State => ({ ...state, saving: true }))
      .addCase(deleteAdServer.rejected, (state): State => ({ ...state, saving: false }))
      .addCase(
        deleteAdServer.fulfilled,
        (state, { payload: e }): State => ({
          ...state,
          saving: false,
          adServers: state.adServers.filter((o) => o.id !== e.id),
          formErrors: undefined,
        }),
      )
      .addCase(
        listAdServers.fulfilled,
        (state, { payload: adServers }): State => ({ ...state, adServers: adServers.items }),
      )
      .addCase(logoutUser.fulfilled, (): State => initialStateAdInsertionReducer)
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default adInsertionSlice.reducer
