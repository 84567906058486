import { Checkbox, Paper, Select } from '../../../../common/Form'
import {
  MatroxDecoderFramerateFamily,
  MatroxDecoderGenlockSource,
  MatroxS1ApplianceSettings,
} from 'common/api/v1/types'
import MuiCheckbox from '@mui/material/Checkbox'
import { useFormContext } from 'react-hook-form'

interface Props {
  settings: MatroxS1ApplianceSettings
}

const sourceOptions = Object.values(MatroxDecoderGenlockSource)
const framerateFamilyOptions = Object.values(MatroxDecoderFramerateFamily)

const MatroxS1SettingsComp = ({ settings }: Props) => {
  const { setValue } = useFormContext()
  const isRunningMatroxV2 = false // FIXME MG: Uncomment when we wish to support matrox v2 features: settings.apiVersion === 'v2'

  const portIndex = 0

  const handlePcmModeClick = (portIndex: number, checked: boolean) => {
    const currentValue = settings.sdiPortsWithPcmModeEnabled ?? []
    const newValue = checked ? [...currentValue, portIndex].sort() : currentValue.filter((i) => i !== portIndex)
    setValue(`settings.sdiPortsWithPcmModeEnabled`, newValue, { shouldValidate: true })
  }
  return (
    <Paper title="Matrox S1 Settings">
      <Paper title="Decoder Genlock" className="outlined">
        <Select label="Source" name={`settings.genlock.source`} required options={sourceOptions} />
        <Select
          label="Framerate Family"
          name={`settings.genlock.framerateFamily`}
          required
          options={framerateFamilyOptions}
        />
      </Paper>

      {isRunningMatroxV2 && (
        <Paper title="Input SDI" className="outlined">
          <Paper
            title="PCM Mode"
            className="outlined"
            tooltip={
              <div>
                Enables raw audio capture. When enabled:
                <br /> - all processors using the SDI port will be handling PCM audio
                <br /> - it is not possible to mix AAC and PCM at the same time
                <br /> - only MPEG-2 TS outputs and SRT outputs are allowed, outputs of other types will not work
              </div>
            }
          >
            <Checkbox
              xs={2}
              lg={2}
              xl={2}
              key={`SDI-1`}
              label={`SDI-1`}
              control={
                <MuiCheckbox
                  checked={settings.sdiPortsWithPcmModeEnabled?.includes(portIndex) ?? false}
                  disabled={false}
                  onChange={(_, checked) => handlePcmModeClick(portIndex, checked)}
                />
              }
            />
          </Paper>
        </Paper>
      )}
    </Paper>
  )
}

export default MatroxS1SettingsComp
