import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFieldArray } from 'react-hook-form'
import { format } from 'date-fns'
import { isFeatureOn } from '../../../utils/features'
import Button from '@mui/material/Button'
import Info from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import get from 'lodash/get'

import {
  ApplianceSettings,
  ApplianceType,
  DelayMode,
  ExpFeatures,
  IpPortMode,
  ListOutputSortableField,
  OutputPort,
  PhysicalPort,
  PortMode,
} from 'common/api/v1/types'

import { Api, AppDispatch, GlobalState, useRoutes } from '../../../store'
import { isEditableGroup, pluralizeWord, useConfirmationDialog, useUser } from '../../../utils'

import { Autocomplete, ButtonsPane, Checkbox, GridItem, Paper, SafeRouting, Select, TextInput } from '../../common/Form'
import InputPicker from './InputPicker'
import {
  CommonFields,
  ristDefaults,
  rtmpDefaults,
  rtpDefaults,
  srtDefaults,
  udpDefaults,
  zixiDefaults,
} from './PortForm/IpPortForm'
import { vaSdiPortDefaults } from './PortForm/CoaxPortForm/VaSdiPortForm'
import DataSet from '../../common/DataSet'
import { AutoUpdatingOutputHealthIndicator } from '../../common/Indicator'
import { Link } from '../../common/Link'
import { useEditType, type EnrichedOutputWithEnrichedPorts } from './index'
import {
  APPLIANCE_SECTION_FORM_PREFIX,
  ApplianceOrRegion,
  ApplianceSection,
  collectApplianceSectionEntries,
  isAppliance,
  isApplianceOrRegionSelectable,
  makeApplianceSection,
} from '../../common/Interface/Base'
import { applianceList, getCoreNodesOutput } from '../../common/Metadata'
import { removeOutput } from '../../../redux/actions/outputsActions'
import { DEFAULT_BUFFER_DURATION } from 'common/constants'
import { DATE_FORMAT_LONG } from 'common/api/v1/helpers'
import { matroxSdiPortDefaults } from './PortForm/CoaxPortForm/MatroxSdiPortForm'
import * as uuid from 'uuid'
import { getSettingsAsync } from '../../../redux/actions/settingsActions'
import { useAsync } from 'react-async-hook'
import Tooltip from '../../common/Tooltip'
import { TagInputField } from '../../common/TagInputField'
import type { AllowUncontrolled, FormProps } from '../../common/Form/RHF'
import { Form } from '../../common/Form'
import { AdServer } from 'common/types'
import { clearAdServer, getAdServer } from '../../../redux/actions/adServerActions'
import { AdNumberFilterInputField } from './AdNumberFilterInputField'

const { settingsApi } = Api

export const initialOutputLogicalPort = ({
  physicalPortId,
  port,
  enforcedMode,
  applianceAllocationId,
  numDistinctFailoverPriorities,
  settings,
  applianceType,
}: {
  physicalPortId?: string
  port?: PhysicalPort
  enforcedMode?: IpPortMode
  applianceAllocationId?: string
  numDistinctFailoverPriorities?: number
  settings?: ApplianceSettings
  applianceType?: ApplianceType
}) => ({
  ...srtDefaults(numDistinctFailoverPriorities || 0),
  ...udpDefaults(),
  ...zixiDefaults(),
  ...vaSdiPortDefaults(),
  ...matroxSdiPortDefaults(applianceType, settings),
  ...rtpDefaults(),
  ...ristDefaults(),
  ...rtmpDefaults(),
  _port: port,
  [CommonFields.mode]: enforcedMode ?? '',
  [CommonFields.physicalPort]: physicalPortId,
  [CommonFields.copies]: 1,
  [CommonFields.applianceAllocationId]: applianceAllocationId,
  // Provide an initial id to use as 'React.key'
  id: uuid.v4(),
})

type DelayModeNames = Extract<keyof typeof DelayMode, string>

const delayModeDescriptors: {
  [key in DelayModeNames]: { label: string; description: string }
} = {
  basedOnOriginTime: {
    label: 'origin time',
    description: 'delay is based on the arrival time of the packet to the input appliance (default)',
  },
  basedOnArrivalTime: {
    label: 'arrival time',
    description: 'delay is based on the arrival time of the packet to the output appliance',
  },
}

const availableDelayModes = Object.keys(delayModeDescriptors) as DelayModeNames[]

function makeDelayModeTooltip() {
  return availableDelayModes
    .map((d) => {
      const delayModeDescriptor = delayModeDescriptors[d]
      return `${delayModeDescriptor.label}: ${delayModeDescriptor.description}`
    })
    .join('\n')
}
function makeDelayModeOptions(firstPortMode: PortMode | undefined) {
  if (firstPortMode === IpPortMode.rtp) {
    return [{ name: 'No delay', value: '' }]
  }

  return availableDelayModes.map((d) => ({
    name: delayModeDescriptors[d].label,
    value: DelayMode[d],
  }))
}

const OutputForm = (propsForOutputsForm: FormProps<EnrichedOutputWithEnrichedPorts & AllowUncontrolled>) => {
  const {
    getValues,
    defaultValues: initialValues,
    setError,
    setFocus,
    formState,
    clearErrors,
    trigger: validateForm,
    reset,
  } = propsForOutputsForm

  const { formErrors } = useSelector(
    ({ outputsReducer }: GlobalState) => ({ formErrors: outputsReducer.formErrors }),
    shallowEqual,
  )
  const adServersApi = useCallback(Api.adServersApi.listAdServers.bind(Api.adServersApi), [])
  const selectedAdServer = useSelector(
    ({ adInsertionReducer }: GlobalState) => adInsertionReducer.selectedAdServer,
    shallowEqual,
  )

  const isSaving = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.saving, shallowEqual)
  const user = useUser()
  const routes = useRoutes()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const setConfirm = useConfirmationDialog()

  const settings = useAsync(() => getSettingsAsync({ dispatch, settingsApi }), [])
  const values = getValues()
  const editType = useEditType()

  // reset on changed editType
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(initialValues), [editType, reset])

  useEffect(() => {
    if (Array.isArray(formErrors)) {
      formErrors.forEach((item) => {
        setError(item.name, { type: 'manual', message: item.reason })
      })
      if (formErrors[0]) setFocus(formErrors[0].name)
    }
  }, [formErrors, setError, setFocus])

  useEffect(() => {
    if (values.adServer) dispatch(getAdServer(values.adServer))
    return () => {
      dispatch(clearAdServer())
    }
  }, [values.adServer])

  const {
    fields: applianceSectionFields,
    append: appendApplianceSection,
    remove: removeApplianceSection,
    update: updateApplianceSection,
  } = useFieldArray({
    name: APPLIANCE_SECTION_FORM_PREFIX,
  })

  const coreNodes = getCoreNodesOutput(values)
  const onRemoveInputAppliance = (index: number) => removeApplianceSection(index)
  const initialApplianceSections = collectApplianceSectionEntries(initialValues)
  const applianceSectionEntries = collectApplianceSectionEntries(values)
  const selectedPortModes = applianceSectionEntries
    .flatMap((data) => data.ports.map((p) => p.mode))
    .filter(Boolean) as OutputPort['mode'][]
  const firstSelectedPortMode = selectedPortModes[0]
  const hasMultipleAppliances = applianceSectionEntries.length > 1
  const canAddAdditionalAppliance = false // applianceSectionEntries.length === 1 // EDGE-3728: Re-enable if/when we once again support multi-appliance outputs

  const applianceSections = applianceSectionFields.map((field, applianceIndex) => {
    const sectionData = applianceSectionEntries[applianceIndex]

    // Lock the mode-select if:
    // - Multiple interfaces have been added on a single appliance or
    // - if this is the second appliance of a multi-appliance output and the first appliance has a mode selected
    const hasMultipleLogicalPorts = sectionData.ports.length > 1
    const isFirstAppliance = applianceIndex === 0
    const isModeSelectionDisabled = hasMultipleLogicalPorts || (!isFirstAppliance && selectedPortModes.length > 1)

    // Don't allow mixing of different port modes
    const enforcedPortMode = hasMultipleAppliances ? firstSelectedPortMode : undefined

    const adminStatus = get(values, 'adminStatus') ? 1 : 0

    return (
      <ApplianceSection<EnrichedOutputWithEnrichedPorts>
        applianceIndex={applianceIndex}
        title={`Output appliance #${applianceIndex + 1}`}
        key={field.id}
        namePrefix={`${APPLIANCE_SECTION_FORM_PREFIX}.${applianceIndex}`}
        adminStatus={adminStatus}
        isModeSelectionDisabled={isModeSelectionDisabled}
        enforcedPortMode={enforcedPortMode}
        initialApplianceOrRegionId={
          initialApplianceSections[applianceIndex]?.region?.id ??
          initialApplianceSections[applianceIndex]?.appliance?.id
        }
        onRemove={hasMultipleAppliances ? onRemoveInputAppliance : undefined}
        inputId={values.input}
        outputId={editType.isUpdate ? values.id : undefined}
        groupId={values.group ?? user.group}
        isInputForm={false}
        isEditingExistingEntity={editType.isUpdate}
        isCopyingExistingEntity={editType.isCopy}
        isApplianceOrRegionSelectable={(option: ApplianceOrRegion) =>
          isApplianceOrRegionSelectable(option, values, true)
        }
        onApplianceOrRegionSelected={(selected: ApplianceOrRegion | null) => {
          clearErrors()
          if (!selected) {
            const emptySection = makeApplianceSection({ region: undefined, appliance: undefined })
            updateApplianceSection(applianceIndex, emptySection)
          } else if (isAppliance(selected)) {
            const applianceSection = makeApplianceSection({ region: undefined, appliance: selected })
            updateApplianceSection(applianceIndex, applianceSection)
          } else {
            const regionalSection = makeApplianceSection({
              region: selected,
              appliance: undefined,
            })
            updateApplianceSection(applianceIndex, regionalSection)
          }
        }}
        {...propsForOutputsForm}
      />
    )
  })

  const isHealthAlarmsEnabled = settings && settings.result && isFeatureOn(ExpFeatures.ExtHealthAlarms, settings.result)
  return (
    <>
      <SafeRouting formState={formState} />
      <Form id="outputs-form" noValidate disableSubmitOnEnterKey>
        <Paper className="outlined" title="Metadata" collapsible>
          <Paper>
            <TextInput name="name" label="Output name" required autoFocus />
            <Checkbox name="adminStatus" label="Enabled" onClick={() => void validateForm()} />
            <TagInputField formName={'tags'} type={'output'} newLine />
            <TextInput
              name="delay"
              label="Delay (ms)"
              type="number"
              tooltip={`Total end-to-end delay from the time the stream is ingested in the Edge input until it is delivered to the Edge output.`}
              noNegative
              required
              validators={{
                number: {
                  greaterThanOrEqualTo: 0,
                  lessThanOrEqualTo: DEFAULT_BUFFER_DURATION,
                  message: `Must be 0 - ${DEFAULT_BUFFER_DURATION}`,
                },
              }}
            />

            {isHealthAlarmsEnabled && <UnhealthyAlarmLevel />}
            {settings && settings.result && isFeatureOn(ExpFeatures.ExtSelectableDelayMode, settings.result) && (
              <Select
                name="delayMode"
                label="Playout delay mode"
                required
                disabled={firstSelectedPortMode === IpPortMode.rtp}
                options={makeDelayModeOptions(firstSelectedPortMode)}
                tooltip={makeDelayModeTooltip()}
              />
            )}
          </Paper>

          {!!values.id && (
            <Paper>
              <GridItem lg={12} xl={12}>
                <DataSet
                  values={{
                    Id: values.id,
                    Tags: ((initialValues.tags ?? []) as string[]).flatMap((tag, i) => {
                      const defaultOrder = isHealthAlarmsEnabled ? ListOutputSortableField.healthStatus : undefined
                      const link = (
                        <Link to={routes.events({ tags: tag, desc: defaultOrder })} underline="hover">
                          {tag}
                        </Link>
                      )
                      return i === 0 ? link : [', ', link]
                    }),
                    Created: format(new Date(values.createdAt), DATE_FORMAT_LONG),
                    Updated: format(new Date(values.updatedAt), DATE_FORMAT_LONG),
                    Status: <AutoUpdatingOutputHealthIndicator initialOutput={values} inline />,
                    Owner: !!values._group?.id && (
                      <Link
                        to={routes.groupsUpdate({ id: values._group.id })}
                        underline="hover"
                        available={isEditableGroup(values._group.id, user)}
                      >
                        {values._group?.name}
                      </Link>
                    ),
                    [`Output ${pluralizeWord(values.appliances?.length ?? 0, 'appliance')}`]: applianceList(
                      values.appliances ?? [],
                      user,
                    ),
                    [`Core video ${pluralizeWord(coreNodes.length, 'node')}`]: applianceList(coreNodes, user),
                    'Service Overview': (
                      <IconButton
                        aria-label="service overview button"
                        onClick={(e) => {
                          e.stopPropagation()
                          navigate(routes.service({ id: values.input || '', outputId: values.id }))
                        }}
                        sx={{ marginLeft: '-10px', marginTop: '-8px' }}
                        disabled={!values.input}
                      >
                        <Tooltip title={'Service Overview'} placement="top">
                          <Info />
                        </Tooltip>
                      </IconButton>
                    ),
                  }}
                />
              </GridItem>
            </Paper>
          )}
        </Paper>

        {applianceSections}

        {canAddAdditionalAppliance && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              const emptySection = makeApplianceSection({
                region: undefined,
                appliance: undefined,
              })
              appendApplianceSection(emptySection)
            }}
          >
            Add output appliance
          </Button>
        )}

        {settings && settings.result && isFeatureOn(ExpFeatures.AdInsertion, settings.result) && (
          <Paper className="outlined" title="Ad Insertion" collapsible collapsed={!values.adInsertionEnabled}>
            <Paper>
              <Checkbox name="adInsertionEnabled" label="Enabled" onClick={() => void validateForm()} />
              <Autocomplete<AdServer>
                name="adServer"
                label="Ad Server"
                api={adServersApi}
                defaultOption={values.adServer && selectedAdServer ? selectedAdServer : undefined}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                optionComparator={(o1, o2) => o1.id === o2.id}
                disabled={!values.adInsertionEnabled}
                xs={4}
              />
              <TextInput
                name="adUrl"
                label="Ad Server Template URL"
                disabled={!values.adInsertionEnabled}
                tooltip={'Part of the ad server URL that is specific to this output'}
              />
              <AdNumberFilterInputField formName={'scteEventIdFilter'} label={'SCTE 35 Event ID Filter'} />
              <AdNumberFilterInputField formName={'sctePidFilter'} label={'SCTE 35 PID Filter'} />
            </Paper>
          </Paper>
        )}

        <div id="LinksArrayContainer" />

        <InputPicker />

        <ButtonsPane
          main={{
            Cancel: {
              onClick: () => void navigate(routes.outputs()),
            },
            Save: { primary: true, savingState: isSaving, type: 'submit' },
          }}
          secondary={
            editType.isUpdate
              ? {
                  'Delete output': {
                    onClick: () => {
                      setConfirm(
                        () => void dispatch(removeOutput({ output: values, redirect: true })),
                        `Deleting output "${values.name}". Are you sure?`,
                      )
                    },
                  },
                  'USE AS TEMPLATE': {
                    onClick: () => void navigate(routes.outputsCopy({ id: values.id })),
                  },
                }
              : undefined
          }
        />
      </Form>
    </>
  )
}

const UnhealthyAlarmLevel = (_props: object) => {
  return (
    <Select
      label="Alarm level when output is unhealthy"
      name="unhealthyAlarm"
      options={[
        { name: '(No alarm)', value: '' },
        { name: 'Critical', value: 'critical' },
        { name: 'Major', value: 'major' },
        { name: 'Minor', value: 'minor' },
        { name: 'Warning', value: 'warning' },
      ]}
      tooltip="Choose what type of alarm will be triggered when the output becomes unhealthy."
    />
  )
}

export default OutputForm
