import { Route, Routes } from 'react-router'

import { SUB_PATH } from '../../utils/routes'
import { NotFound } from '../common/NotFound'
import { List } from './List'
import { Edit } from './Edit'

export const Inputs = () => {
  return (
    <Routes>
      <Route path={SUB_PATH.UPDATE} element={<Edit />} />
      <Route path={SUB_PATH.NEW} element={<Edit />} />
      <Route path={SUB_PATH.COPY} element={<Edit />} />
      <Route index element={<List />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
