import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import merge from 'lodash/merge'
import Grid from '@mui/material/Grid'
import { Group, Role } from 'common/api/v1/types'
import RHF, { FormProps } from '../../common/Form/RHF'
import { AppDispatch, GlobalState, useRoutes } from '../../../store'
import Wrapper from '../../common/Wrapper'
import Pendable from '../../common/Pendable'
import { formTransform, useConfirmationDialog, useUser } from '../../../utils'
import {
  clearAdServer,
  createAdServer,
  deleteAdServer,
  getAdServer,
  updateAdServer,
} from '../../../redux/actions/adServerActions'
import { AdServer } from 'common/types'
import { ButtonsPane, Form, Paper, SafeRouting, TextInput } from '../../common/Form'
import { equals } from 'common/util'

const getInitialState = (adServer?: AdServer): AdServer =>
  merge(
    {
      id: '',
      name: '',
      baseUrl: '',
    },
    adServer,
  )

const isEditing = (values: AdServer): values is AdServer => 'id' in values && !!values.id

const AdServerForm = ({ getValues, setError, setFocus, watch, formState }: FormProps<AdServer>) => {
  const values = getValues()
  const routes = useRoutes()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const setConfirmation = useConfirmationDialog()

  const { formErrors, saving } = useSelector(
    ({ groupsReducer }: GlobalState) => ({
      formErrors: groupsReducer.formErrors,
      saving: groupsReducer.saving,
    }),
    shallowEqual,
  )

  const user = useUser()

  useEffect(() => {
    if (Array.isArray(formErrors)) {
      formErrors.forEach((item) => {
        setError(item.name as keyof AdServer, { type: 'manual', message: item.reason })
      })
      if (formErrors[0]) setFocus(formErrors[0].name as keyof AdServer)
    }
  }, [formErrors, setError, setFocus])

  // This will cause the form to re-render on each key press in the name and baseUrl fields
  // If this form grows in complexity, these fields should be moved to their own component (so that only
  // that component re-renders on key press), but right now the form is simple enough that it's not necessary
  watch(['name', 'baseUrl'])

  if (user.role !== Role.super && (user.role !== Role.admin || user.group !== (values as Group).id)) {
    navigate(-1)
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting formState={formState} />
        <Form id="ad-server-form" noValidate>
          <Paper>
            <TextInput name="name" label="Name" required autoFocus />
            <TextInput
              name="baseUrl"
              label="Base URL"
              required
              tooltip={'Part of the ad server URL that is common to all outputs'}
              validators={{
                format: {
                  prefixes: ['http://', 'https://'],
                },
              }}
            />
          </Paper>

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => navigate(routes.adInsertion()),
              },
              Save: { savingState: !!saving, primary: true, type: 'submit' },
            }}
            secondary={
              isEditing(values) && user.role === Role.super
                ? {
                    Delete: {
                      onClick: () =>
                        isEditing(values) &&
                        setConfirmation(() => {
                          dispatch(deleteAdServer(values.id))
                          navigate(routes.adInsertion())
                        }, 'Are you sure you want to delete this Ad Server?'),
                    },
                  }
                : undefined
            }
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const EditAdServer = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    id && dispatch(getAdServer(id))
    return () => {
      dispatch(clearAdServer())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectedAdServer = useSelector(
    ({ adInsertionReducer }: GlobalState) => adInsertionReducer.selectedAdServer,
    equals,
  )

  return (
    <Wrapper name={['Ad Insertion', 'Ad Servers', id ? selectedAdServer?.name : 'New']}>
      <Pendable pending={!!id && !selectedAdServer}>
        <RHF
          onSubmit={(values) => {
            dispatch(id ? updateAdServer(formTransform(values)) : createAdServer(formTransform(values)))
          }}
          defaultValues={getInitialState(selectedAdServer)}
          component={AdServerForm}
        />
      </Pendable>
    </Wrapper>
  )
}
