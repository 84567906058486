import { Navigate, Route, Routes } from 'react-router'
import { NotFound } from '../common/NotFound'
import { ROUTES, SUB_PATH } from '../../utils/routes'
import { AdInsertionPage } from './AdInsertionPage'
import { EditAdServer } from './adServer/Edit'
import { DemoPage } from './demo/DemoPage'
import { AdAuditLog } from './AdAuditLog'

export const AdInsertion = () => {
  return (
    <Routes>
      <Route path={`adServers/${SUB_PATH.UPDATE}`} element={<EditAdServer />} />
      <Route path={`adServers/${SUB_PATH.NEW}`} element={<EditAdServer />} />
      <Route path={`adServers/${SUB_PATH.COPY}`} element={<EditAdServer />} />
      <Route path={`demo`} element={<DemoPage />} />
      <Route path={`adAudit`} element={<AdAuditLog />} />
      <Route path={`adServers`} element={<Navigate to={ROUTES.adInsertion} />} />
      <Route path={`adInsertion`} element={<Navigate to={ROUTES.adInsertion} />} />
      <Route index element={<AdInsertionPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
