import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router'
import Box from '@mui/material/Box'
import { ListInputSortableField, ListOutputSortableField, Role, type ExperimentalFeatures } from 'common/api/v1/types'
import { useRoutes, Api, type AppDispatch } from '../../store'
import { getInputs } from '../../redux/actions/inputsActions'
import { getOutputs } from '../../redux/actions/outputsActions'
import { selectInputs } from '../../redux/reducers/inputsReducer'
import { selectOutputs } from '../../redux/reducers/outputsReducer'
import { updateSearchHistory } from '../../redux/actions/tagsAction'
import { useSettingsSelector, useUser } from '../../utils'
import type { EnrichedInput, EnrichedOutput, EventsRequestParams } from '../../api/nm-types'
import { UrlParamFilteredSearchBar } from '../common/Filters/FilterView/FilteredSearchBar'
import { Paper } from '../common/Form'
import Wrapper from '../common/Wrapper'
import Table from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import { inputColumns } from '../inputs/List/tableConfig'
import { outputColumns } from '../outputs/List/tableConfig'
import { makeListInputFilter } from '../inputs/List/listInputFilter'

const INTERVAL_MS = 10_000

type TableProps = {
  tags?: string
  experimentalFeatures?: ExperimentalFeatures
}

const InputsTable = ({ tags, experimentalFeatures }: TableProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [pending, setPending] = useState(true)
  const { inputs, total } = useSelector(selectInputs, shallowEqual)

  const user = useUser()
  const routes = useRoutes()
  const config = [
    inputColumns.status({ autoUpdate: false, user, routes, experimentalFeatures }),
    inputColumns.preview(),
    inputColumns.name(
      {
        availableFn: () => user.role !== Role.basic,
        toFn: ({ id }: EnrichedInput) => routes.service({ id }),
      },
      { props: { sx: { width: '70%' } } },
    ),
  ]

  useEffect(() => {
    const params = {
      tags,
      asc: (searchParams.get('asc') as ListInputSortableField) ?? undefined, // TODO: Don't share with output
      desc: (searchParams.get('desc') as ListInputSortableField) ?? undefined,
      pageNumber: searchParams.get('iPageNumber') ?? undefined,
      rowsPerPage: searchParams.get('iRowsPerPage') ?? undefined,
    }
    setPending(true)
    dispatch(getInputs(params)).then(() => setPending(false))
    const interval = setInterval(() => void dispatch(getInputs(params)), INTERVAL_MS)
    return () => clearInterval(interval)
  }, [dispatch, searchParams, tags])

  return (
    <Paper title="Inputs">
      <Table<EnrichedInput>
        pending={pending}
        emptyMessageComponent={<MissingContent message="No inputs available" />}
        config={config}
        data={inputs}
        rowProps={(input) => {
          return user.role !== Role.basic
            ? {
                onDoubleClick: () => void navigate(routes.service({ id: input.id })),
              }
            : {}
        }}
        pagination={{
          total,
          useUrlSearchParams: { rowsPerPageKey: 'iRowsPerPage', pageNumberKey: 'iPageNumber' },
        }}
      />
    </Paper>
  )
}

const OutputsTable = ({ tags, experimentalFeatures }: TableProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [pending, setPending] = useState(true)
  const { outputs, total } = useSelector(selectOutputs, shallowEqual)
  const user = useUser()

  const routes = useRoutes()
  const config = [
    outputColumns.status({ autoUpdate: false, experimentalFeatures }),
    outputColumns.preview(),
    outputColumns.name(
      {
        user,
        toCallback: ({ id, input }: EnrichedOutput) => routes.service({ id: input, outputId: id }),
      },
      { props: { sx: { width: '70%' } } },
    ),
  ]

  useEffect(() => {
    const params = {
      tags,
      asc: (searchParams.get('asc') as ListOutputSortableField) ?? undefined,
      desc: (searchParams.get('desc') as ListOutputSortableField) ?? undefined,
      pageNumber: searchParams.get('oPageNumber') ?? undefined,
      rowsPerPage: searchParams.get('oRowsPerPage') ?? undefined,
    }
    setPending(true)
    dispatch(getOutputs(params)).then(() => setPending(false))
    const interval = setInterval(() => void dispatch(getOutputs(params)), INTERVAL_MS)
    return () => clearInterval(interval)
  }, [dispatch, searchParams, tags])

  return (
    <Paper title="Outputs">
      <Table<EnrichedOutput>
        pending={pending}
        emptyMessageComponent={<MissingContent message="No outputs available" />}
        config={config}
        data={outputs}
        rowProps={(output) => {
          return user.role !== Role.basic
            ? {
                onDoubleClick: () => void navigate(routes.service({ id: output.input, outputId: output.id })),
              }
            : {}
        }}
        pagination={{
          total,
          useUrlSearchParams: { rowsPerPageKey: 'oRowsPerPage', pageNumberKey: 'oPageNumber' },
        }}
      />
    </Paper>
  )
}

// output's 'tag' should be the same
const filters = makeListInputFilter({
  api: Api,
  expFeatures: {},
}).filter((f) => f.key === 'tag')!

export const EventPage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [searchParams] = useSearchParams()
  const { settings } = useSettingsSelector()
  const tags = searchParams.get('tags') ?? undefined

  useEffect(() => {
    if (!tags) return
    dispatch(updateSearchHistory(tags))
  }, [dispatch, tags])

  return (
    <Wrapper name={tags ? ['Events', tags] : 'Events'}>
      <UrlParamFilteredSearchBar<EventsRequestParams>
        urlParamCacheKey={undefined}
        mapUrlParamToFilterFn={(key, value) =>
          key === 'tags'
            ? {
                key: 'tag',
                operator: 'isOneOf',
                values: value.split(',').map((tagName) => ({ value: tagName, displayName: tagName })),
              }
            : undefined
        }
        mapFilterToUrlParamFn={(filter) =>
          'values' in filter ? { tags: filter.values.map(({ value }) => value).join(',') } : {}
        }
        searchTokenFilters={filters}
        rawTextFilter={filters[0]}
      />
      {tags ? (
        <Box sx={{ display: 'flex' }} gap={2}>
          <InputsTable tags={tags} experimentalFeatures={settings?.expFeatures} />
          <OutputsTable tags={tags} experimentalFeatures={settings?.expFeatures} />
        </Box>
      ) : (
        <Paper sx={{ display: 'flex', justifyContent: 'center', padding: 3 }}>
          <MissingContent message="Select event tags" />
        </Paper>
      )}
    </Wrapper>
  )
}
