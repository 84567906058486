import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { Table, type TableProps } from './Table'

export * from './MoveToEntity'
export * from './Table'
export * from './Pagination'

export interface TableContainerProps<TEntity> extends TableProps<TEntity> {
  actions?: Array<React.ReactNode>
}
/**
 * Common container for List entities pages table
 * @param actions - buttons on top of the table (for example create entity)
 * @param rest - common table properties
 */
const TableContainer: <T>(props: TableContainerProps<T>) => React.ReactElement<TableContainerProps<T>> = ({
  actions,
  ...rest
}) => {
  return (
    <Stack direction="column" sx={{ width: '100%' }}>
      {actions ? <Box sx={{ textAlign: 'right', marginRight: 1 }}>{actions}</Box> : null}
      <Paper sx={{ padding: (theme) => theme.spacing(3, 2, 1) }}>
        <Table {...rest} />
      </Paper>
    </Stack>
  )
}

export default TableContainer
