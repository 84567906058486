import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { NavigateFunction } from 'react-router'
import { ThunkApi } from '../../store'
import { LOCAL_STORAGE_UI_SNACKBAR_ERROR_POSITION_KEY, type UISnackbarErrorPosition } from '../../localstorage'
import { initAlarms } from './alarmsActions'
import { initAppliance } from './applianceActions'
import { initBuildInfo } from './buildInfoActions'

export const init = createAsyncThunk<void, NavigateFunction, ThunkApi>(
  'ui/init',
  async (navigate, { dispatch, extra: { setNavigate } }) => {
    setNavigate(navigate)
    dispatch(initAppliance())
    dispatch(initAlarms())
    dispatch(initBuildInfo())
  },
)

export const toggleSidebarMenu = createAction<boolean | undefined>('ui/toggleSidebarMenu')

export const changeErrorSnackbarPosition = createAsyncThunk<UISnackbarErrorPosition, UISnackbarErrorPosition, ThunkApi>(
  'ui/changeErrorSnackbarPosition',
  async (position) => {
    localStorage.setItem(LOCAL_STORAGE_UI_SNACKBAR_ERROR_POSITION_KEY, position)
    return position
  },
)
