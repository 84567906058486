import { ApplianceSettings, ApplianceType } from 'common/api/v1/types'
import { Checkbox, Paper } from '../../../../common/Form'
import { MultiSelect } from '../../../../common/Form/MultiSelect'

interface IAdvancedSettingsProps {
  type: ApplianceType
  settings: ApplianceSettings | undefined
}

export const AdvancedSettings = ({ type, settings }: IAdvancedSettingsProps) => {
  void type
  void settings
  return (
    <Paper title="Advanced settings" collapsible collapsed>
      <Checkbox
        label={'Enable UI Proxy'}
        name={`settings.proxy.enabled`}
        tooltip="The UI Proxy enables access to the appliance local UI remotely through a link in the appliance list with the title 'Manage appliance'. This feature requires the EDGE_EXTERNAL_UI_ENDPOINT environment variable to be set to the url of the local web UI when starting the edge-connect."
      />
      <MultiSelect
        disabled={!settings?.proxy?.enabled}
        newLine
        name={`settings.proxy.forwardedCookies`}
        label={`Forwarded cookies`}
        required={false}
        tooltip="Whitelist of cookies that will be forwarded through the UI proxy. For prefix matching, add a '*' to the end of the cookie name."
      />
      <MultiSelect
        disabled={!settings?.proxy?.enabled}
        name={`settings.proxy.forwardedHeaders`}
        label={`Forwarded headers`}
        required={false}
        tooltip="Whitelist of headers that will be forwarded through the UI proxy."
      />
    </Paper>
  )
}
